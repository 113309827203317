import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import SimplexDatepicker from '../../../../../../components/SimplexDatepicker';

type Form = {
  pspRunDate: string;
  pspAuthSignedDate: string;
};

type Props = {
  onChange: (date: Form) => void;
  defaultValue?: Form;
  isRequired?: boolean;
};

export const PSPDateFormSHSS: React.FC<Props> = ({
  onChange,
  defaultValue = {
    pspAuthSignedDate: '',
    pspRunDate: '',
  },
  isRequired,
}) => {
  const [value, setValue] = useState<Form>(defaultValue);
  return (
    <>
      <Typography paragraph>
        PSP Run Date {isRequired && <sup>*</sup>}
      </Typography>
      <SimplexDatepicker
        value={value.pspRunDate}
        maxDate={new Date()}
        onChange={(e: any) => {
          if (e) {
            const date = new Date(e);
            const dateText = date.toLocaleDateString('en-US');
            const tempValue = {
              ...value,
              pspRunDate: dateText,
            };
            onChange(tempValue);
            setValue(tempValue);
          }
        }}
      />

      <Typography paragraph>
        PSP Authorization Signed Date {isRequired && <sup>*</sup>}
      </Typography>
      <SimplexDatepicker
        value={value.pspAuthSignedDate}
        maxDate={new Date()}
        onChange={(e: any) => {
          if (e) {
            const date = new Date(e);
            const dateText = date.toLocaleDateString('en-US');
            const tempValue = {
              ...value,
              pspAuthSignedDate: dateText,
            };
            onChange(tempValue);
            setValue(tempValue);
          }
        }}
      />
    </>
  );
};
