import jwt_decode from 'jwt-decode';
import { makeAutoObservable } from 'mobx';

import { PublicRoutes } from '../config';
import {
  AccessType,
  AccessTypes,
  AuthTokenType,
  GetContactData,
} from '../types';
import { postLogoutSteps } from '../utils/helper';
import Storage from '../utils/Storage';
import { RootStore } from '.';

export class AuthStore {
  private _authCount = 0;

  private _tokens: AuthTokenType | null = null;

  private _stateList: Array<{
    index: number;
    fullName: string;
    code: string;
    id: string;
  }> = [];
  _logoutPromise: Promise<any> | null = null;

  _exchangePromise: Promise<any> | null = null;

  private _contactList: GetContactData = [];

  private _email: string | undefined;

  private _sidNav: AccessType | null = null;

  private _role = '';

  private _tokenTimer: number | undefined;

  private _idleForLongtimeWarning = false;

  private _idleTime = 0;
  private _callback: any;
  private _idleInterval: NodeJS.Timer | null = null;
  private _hasSHSSAccess = false;
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  __init() {
    this.clearTimer();
    if (this._tokens?.accessToken) {
      this.manageRefreshToken();
      if (!this._idleInterval) {
        this._idleInterval = this._callback();
      }
    }
  }

  __unset() {
    this.clearTimer();
    if (this._idleInterval) clearInterval(this._idleInterval);
    this._idleInterval = null;
  }

  setLogoutPromise(promise: any | null) {
    this._logoutPromise = promise;
  }

  setExchangePromise(promise: Promise<any> | null) {
    this._exchangePromise = promise;
  }

  setStateList(
    data: Array<{ index: number; fullName: string; code: string; id: string }>,
  ) {
    this._stateList = data;
  }

  setTokens(tokens: AuthTokenType | null) {
    this._authCount++;
    this._tokens = tokens;
    if (tokens) {
      this._onTokenSet();
    } else {
      this._onTokenUnSet();
    }
  }

  manageRefreshToken() {
    const detailsFromToken: { exp: number } = this.getTokenDetail;
    if (!detailsFromToken) return;
    this.clearTimer();
    this._tokenTimer = window.setTimeout(() => {
      this.exchangeAccessToken();
    }, detailsFromToken.exp - Date.now());
  }

  clearTimer() {
    clearTimeout(this._tokenTimer);
  }

  setIdleForLongtimeWarning(shouldShow: boolean) {
    this._idleForLongtimeWarning = shouldShow;
  }

  setCallback(cb: any) {
    this._callback = cb;
  }

  setIdleTime(duration = 0) {
    this._idleTime = duration;
  }

  get idleTime() {
    return this._idleTime;
  }

  get idleForLongtimeWarning() {
    return this._idleForLongtimeWarning;
  }

  get statesListOption() {
    return this._stateList;
  }
  get authCount() {
    return this._authCount;
  }

  get tokens() {
    return this._tokens ? { ...this._tokens } : null;
  }

  get getTokenDetail(): any {
    const tokenDetail: any = this.tokens;
    if (tokenDetail) {
      const decoded = jwt_decode(tokenDetail.accessToken);
      return decoded;
    }
    return null;
  }

  get UserAccessType() {
    return this.getTokenDetail?.accessType || '';
  }

  get IsImpersonatingMode() {
    return this.getTokenDetail?.adminId ? true : false;
  }

  get IsCompanyAdminLogin() {
    return this.UserAccessType &&
      this.UserAccessType === AccessTypes.CompanyAdmin
      ? true
      : false;
  }

  get ContactData() {
    return this._contactList;
  }

  get GetEmail() {
    return this._email;
  }

  get NavbarAccess() {
    return this._sidNav;
  }

  get UserRoleAcess() {
    return this._role;
  }

  async fetchTokens() {
    const appAuth = await Storage.getTokens();
    this.setTokens(appAuth);
  }

  initTokens(tokens: AuthTokenType) {
    if (tokens) {
      Storage.setItem(Storage.KEYS.AUTH_TOKEN, JSON.stringify(tokens));
    } else {
      Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
    }
    this.setTokens(tokens);
  }

  setContacts(data: GetContactData | null) {
    this._contactList = data!;
  }

  get Contacts() {
    return this._contactList;
  }

  setLoginEmail(email: string) {
    this._email = email!;
  }

  // NOTE: Add in memory data cache to be cleared here
  private _clearStoresCache() {
    for (const store of Object.values(this.rootStore)) {
      store?.__reset?.();
    }
  }

  // NOTE: can be called multiple times
  // Use for login state
  private _onTokenSet() {
    for (const store of Object.values(this.rootStore)) {
      store?.__init?.();
    }
  }

  private _onTokenUnSet() {
    for (const store of Object.values(this.rootStore)) {
      store?.__unset?.();
    }
  }

  async logout(notRefresh?: boolean) {
    if (this._logoutPromise) {
      return this._logoutPromise;
    }

    try {
      this.setLogoutPromise(this._authLogout());
      await this._logoutPromise!;
      // window.location.href = PublicRoutes.LOGIN;
      if (!notRefresh) {
        this.rootStore.userStore.setUserData({});
        window.history.pushState('', '', PublicRoutes.LOGIN);
      }
    } catch (e) {
      // console.error(e);
    } finally {
      this.rootStore.todoStore.setFilters({
        categories: [],
        dueIn: '',
        firstName: '',
        lastName: '',
        limit: 0,
        make: '',
        nextLink: '',
        simplexId: '',
        trainingAssignedTo: '',
        trainingReason: '',
        type: '',
        unitNumber: '',
        vinNumber: '',
        year: '',
      });
      this.rootStore.todoStore.setSearchType('');
      this.setLogoutPromise(null);
      this.setTokens(null);
      postLogoutSteps();
    }
  }

  private _authLogout() {
    if (this.isLoggedIn) {
      // TODO: API CALL TO LOGOUT
      this._clearStoresCache();
      this.removeTokens();
    }
  }

  get isLoggedIn() {
    return !!this._tokens;
  }

  removeTokens() {
    Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
    this.setTokens(null);
  }

  async exchangeOnlyOnce() {
    if (this._exchangePromise) {
      return this._exchangePromise;
    }

    try {
      this.setExchangePromise(this._exchangeToken());
      await this._exchangePromise!;
    } finally {
      this.setExchangePromise(null);
    }
  }

  private async _exchangeToken() {
    const refreshToken = this.tokens?.refreshToken;

    const { data } = await this.rootStore.apiStore.exchangeTokenApiCall(
      refreshToken || null,
    );
    this.removeTokens();
    this.initTokens({
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
      userId: data.id,
    });
    return data;
  }
  async exchangeAccessToken() {
    const accessToken = this.tokens?.accessToken || '';
    const id = this.tokens?.userId || '';

    const data = await this.rootStore.apiStore.authApi.exchangeToken({
      accessToken,
      id,
    });
    const refreshToken = this.tokens?.refreshToken || '';
    if (data.isOk()) {
      const {
        value: { accessToken, id },
      } = data;
      this.initTokens({
        accessToken,
        refreshToken,
        userId: id,
      });
    }
    if (data.isErr()) this.logout();
    // return data;
  }

  async checkCompanyEmail(options: { email: string }) {
    const companyResp = await this.rootStore.apiStore.authApi.emailCheck(
      options,
    );
    if (companyResp.isOk() && Array.isArray(companyResp.value)) {
      this.setLoginEmail(options.email);
      this.setContacts(companyResp.value);
    }
    return companyResp;
  }

  async companyLogin(options: { contactId: string; password: string }) {
    const loginResp = await this.rootStore.apiStore.authApi.companyLogin(
      options,
    );
    if (loginResp.isOk()) {
      const { accessToken, refreshToken, id } = loginResp.value;
      this.fetchProfileAccess(accessToken);
      this.initTokens({
        accessToken,
        refreshToken,
        userId: id,
      });
    }
    return loginResp;
  }

  async statesMasterList() {
    if (this.statesListOption && this.statesListOption.length) {
      return this.statesListOption;
    }
    const stateResp = await this.rootStore.apiStore.authApi.stateList();
    if (stateResp.isOk()) {
      this.setStateList(stateResp.value);
    }
    return stateResp;
  }

  async directLoginAs(options: { accessToken: string | null }) {
    const loginResp = await this.rootStore.apiStore.authApi.directCompanyLogin(
      options,
    );
    if (loginResp.isOk()) {
      this._authLogout();
      const { accessToken, refreshToken, id } = loginResp.value;
      this.fetchProfileAccess(accessToken);
      this.initTokens({
        accessToken,
        refreshToken,
        userId: id,
      });
    }
    return loginResp;
  }

  async fetchProfileAccess(accessToken: string) {
    const profileAccessResp =
      await this.rootStore.apiStore.authApi.getProfileAccess(
        accessToken,
        'portal',
      );
    if (profileAccessResp.isOk()) {
      this._sidNav = profileAccessResp.value.portalAccess;
      this._role = profileAccessResp.value.role
        ? profileAccessResp.value.role
        : '';
      this._hasSHSSAccess = profileAccessResp.value.hasSHSSAccess;
    } else {
      this._authLogout();
      return profileAccessResp;
    }
  }

  portalNavBarAccess(navBarInfo: AccessType) {
    this._sidNav = navBarInfo;
  }

  getCDAURL(cdaBaseURL: string) {
    const accessToken = this.rootStore.authStore.tokens?.accessToken || '';
    return `${cdaBaseURL}${accessToken}`;
  }

  getSHSSAccess() {
    return this._hasSHSSAccess;
  }
}
