import { Box, Checkbox, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
// import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { SelectDatePicker } from '../../../../../components/Forms/datePicker';
import { InputText } from '../../../../../components/Forms/InputText';
import { SelectInput } from '../../../../../components/Forms/selectInput';
import { SelectStateInput } from '../../../../../components/Forms/selectStateDropdown';
import SelectPrimaryDriver from '../../../../../components/SelectPrimaryDriver';
import { AllowedUploadMimeTypes } from '../../../../../config/Constants';
import { useCustomStore } from '../../../../../hooks';
import {
  EquipmentType,
  OwnershipType,
  RegistrationType,
  Trailerype,
  UnitType,
} from '../../../../../types';
import FilesList from '../../components/FilesList/FilesList';

type FormProps = {
  handleSubmit: any;
  onSubmit: any;
  control: any;
  classes: any;
  errors: any;
  getValues: any;
  setValue: any;
  watch: any;
  addToFleetInfo: any;
  updateMethod?: any;
  btnDisable: boolean | any;
  vqFile: any;
  setVqFile: any;
  registration: any;
  setRegistration: any;
  fileValidation?: boolean | any;
  initialDriverName?: string;
  isOwnerOperator?: boolean;
};
const AddToFleetForm: React.FC<FormProps> = (props: FormProps) => {
  const { fleetid } = useParams();

  const {
    handleSubmit,
    onSubmit,
    control,
    classes,
    errors,
    fileValidation,
    getValues,
    setValue,
    watch,
    addToFleetInfo,
    updateMethod,
    btnDisable,
    vqFile,
    setVqFile,
    registration,
    setRegistration,
    initialDriverName,
    isOwnerOperator,
  } = props;

  const [dateFieldHide, setDateFieldHide] = useState<boolean>(false);
  const [equipmentHide, setEquipmentHide] = useState<boolean>(false);
  const isPermanentlyRegistered = watch('isPermanantRegistration');
  const [primaryDriverHastric, setPrimaryDriverHastric] =
    useState<boolean>(false);
  const isOwnership = watch('ownershipType');

  const getYears = () => {
    const yearsList = [];
    for (let index = 1970; index <= new Date().getFullYear() + 1; index++) {
      yearsList.unshift(index);
    }
    return yearsList;
  };

  const { authStore } = useCustomStore();

  useEffect(() => {
    setValue(
      'dotFtBundleStatus',
      addToFleetInfo.AddToFleetInfo
        ? addToFleetInfo.AddToFleetInfo.dotFtBundleStatus
        : '',
    );
    // setValue(
    //   'primaryDriverContactId',
    //   addToFleetInfo.AddToFleetInfo &&
    //     addToFleetInfo.AddToFleetInfo.drivers &&
    //     addToFleetInfo.AddToFleetInfo.drivers.length === 1
    //     ? addToFleetInfo.AddToFleetInfo.drivers[0].contactId
    //     : '',
    // );
    setDateFieldHide(false);
  }, [addToFleetInfo, setValue, updateMethod]);
  useEffect(() => {
    if (isPermanentlyRegistered) setDateFieldHide(true);
    else setDateFieldHide(false);
  }, [isPermanentlyRegistered]);

  useEffect(() => {
    if (isOwnership !== 'Client/Company Owned') setPrimaryDriverHastric(true);
    else setPrimaryDriverHastric(false);
  }, [isOwnership]);

  const fileOnChange = (file: FileList, keyName: string) => {
    const fileList = [];
    for (let index = 0; index < file.length; index++) {
      fileList.push(file.item(index));
    }
    setValue(keyName, fileList);
    keyName === 'vqFile' ? setVqFile(fileList) : setRegistration(fileList);
  };
  const fileClear = (file: any, index: any, keyName = 'vqFile') => {
    const arr: Array<any> = getValues(keyName);
    arr.splice(index, 1);
    setValue(
      keyName,
      arr.map((val) => val),
    );
    setVqFile(arr.map((val) => val));
  };
  const fileClearReg = (file: any, index: any, keyName = 'registration') => {
    const arr: Array<any> = getValues(keyName);
    arr.splice(index, 1);
    setValue(
      keyName,
      arr.map((val) => val),
    );
    setRegistration(arr.map((val) => val));
  };
  const euipmentOnChnage = (e: any) => {
    setValue('unitType', '');
  };
  return (
    <form
      style={{
        width: '100%',
      }}
      id="add-to-fleet"
      onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Box className={classes.labels}>Equipment Type *</Box>
          <Controller
            control={control}
            defaultValue=""
            name="equipmentType"
            render={({
              field: { onChange, value, name, ref, onBlur },
              formState,
            }) => {
              setEquipmentHide(value === 'trailer' ? true : false);
              return (
                <SelectInput
                  isFormSubmittedOnce={!!formState.submitCount}
                  inputRefObj={ref}
                  errors={errors.equipmentType?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  onChangeText={(e) => {
                    onChange(e), euipmentOnChnage(e);
                  }}
                  size={'small'}
                  isEditable={btnDisable}
                  selectClassName={classes.selectClassName}
                  placeHolder={'Select'}
                  optionsList={[
                    EquipmentType.PowerUnit,
                    EquipmentType.Trailer,
                  ].map((val) => ({
                    label: val,
                    value: val,
                  }))}
                />
              );
            }}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Box className={classes.labels}>Unit Type *</Box>
          <Controller
            control={control}
            defaultValue=""
            name="unitType"
            render={({
              field: { onChange, value, name, ref, onBlur },
              formState,
            }) => {
              return (
                <SelectInput
                  isFormSubmittedOnce={!!formState.submitCount}
                  size={'small'}
                  inputRefObj={ref}
                  errors={errors.unitType?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  onChangeText={(val) => {
                    onChange(val);
                    // annualReportStore.setAnnualDetail('registeredAgent', val);
                  }}
                  isEditable={btnDisable}
                  selectClassName={classes.selectClassName}
                  optionsList={
                    watch('equipmentType') === 'trailer'
                      ? [UnitType.Trailer, UnitType.Chassis].map((val) => ({
                          label: val,
                          value: val,
                        }))
                      : [
                          UnitType.Truck,
                          UnitType.Bus,
                          UnitType.Tanker,
                          UnitType.Van,
                          UnitType.Limousine,
                          UnitType.PickUpTruck,
                          UnitType.Other,
                        ]
                          .concat([
                            ...(addToFleetInfo.AddToFleetInfo &&
                            addToFleetInfo.AddToFleetInfo.drivers &&
                            addToFleetInfo.AddToFleetInfo.drivers.find(
                              (el: any) =>
                                el.contactId ===
                                watch('primaryDriverContactId'),
                            )?.licenseType === 'Non-CDL'
                              ? []
                              : [UnitType.Tractor]),
                          ])
                          .map((val) => ({ label: val, value: val }))
                  }
                />
              );
            }}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Box className={classes.labels}>
            Unit Number {!authStore.getSHSSAccess() && '*'}
          </Box>
          <Controller
            control={control}
            defaultValue=""
            name="unitNumber"
            render={({
              field: { onChange, value, name, ref, onBlur },
              formState,
            }) => {
              return (
                <InputText
                  isFormSubmittedOnce={!!formState.submitCount}
                  inputRefObj={ref}
                  errors={errors.unitNumber?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  onChangeText={onChange}
                  isEditable={btnDisable}
                  className={classes.selectClassName}
                  type={'text'}
                  variant="standard"
                />
              );
            }}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Box className={classes.labels}>VIN *</Box>
          <Controller
            control={control}
            defaultValue=""
            name="vinNumber"
            render={({
              field: { onChange, value, name, ref, onBlur },
              formState,
            }) => {
              return (
                <InputText
                  isFormSubmittedOnce={!!formState.submitCount}
                  inputRefObj={ref}
                  errors={errors.vinNumber?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  onChangeText={onChange}
                  isEditable={btnDisable}
                  className={classes.selectClassName}
                  type={'text'}
                  variant="standard"
                />
              );
            }}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Box className={classes.labels}>Vehicle Year *</Box>
          <Controller
            control={control}
            defaultValue=""
            name="vehicleYear"
            render={({
              field: { onChange, value, name, ref, onBlur },
              formState,
            }) => {
              return (
                <SelectInput
                  isFormSubmittedOnce={!!formState.submitCount}
                  size={'small'}
                  inputRefObj={ref}
                  errors={errors.vehicleYear?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={value ? value : ''}
                  onChangeText={(val) => {
                    onChange(val);
                    // annualReportStore.setAnnualDetail('registeredAgent', val);
                  }}
                  isEditable={btnDisable}
                  selectClassName={classes.selectClassName}
                  sortByOptionDisable={true}
                  optionsList={getYears().map((e) => ({
                    label: String(e),
                    value: String(e),
                  }))}
                />
              );
            }}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Box className={classes.labels}>
            Vehicle Make {!authStore.getSHSSAccess() && '*'}
          </Box>
          <Controller
            control={control}
            defaultValue=""
            name="vehicleMake"
            render={({
              field: { onChange, value, name, ref, onBlur },
              formState,
            }) => {
              return (
                <InputText
                  isFormSubmittedOnce={!!formState.submitCount}
                  inputRefObj={ref}
                  errors={errors.vehicleMake?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  onChangeText={onChange}
                  isEditable={btnDisable}
                  className={classes.selectClassName}
                  type={'text'}
                  variant="standard"
                />
              );
            }}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Box className={classes.labels}>
            License Plate # {!authStore.getSHSSAccess() && '*'}
          </Box>
          <Controller
            control={control}
            defaultValue=""
            name="licensePlateNumber"
            render={({
              field: { onChange, value, name, ref, onBlur },
              formState,
            }) => {
              return (
                <InputText
                  isFormSubmittedOnce={!!formState.submitCount}
                  inputRefObj={ref}
                  errors={errors.licensePlateNumber?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  onChangeText={onChange}
                  isEditable={btnDisable}
                  className={classes.selectClassName}
                  type={'text'}
                  variant="standard"
                />
              );
            }}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Box className={classes.labels}>License Plate State *</Box>
          <Controller
            control={control}
            defaultValue=""
            name="licensePlateState"
            render={({
              field: { onChange, value, name, ref, onBlur },
              formState,
            }) => {
              return (
                <SelectStateInput
                  isFormSubmittedOnce={!!formState.submitCount}
                  inputRefObj={ref}
                  errors={errors.licensePlateState?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={value ? value : ''}
                  onChangeText={onChange}
                  size={'small'}
                  isEditable={btnDisable}
                  selectClassName={classes.selectClassName}
                  placeHolder={'Select'}
                  keyName={'id'}
                />
              );
            }}
          />
        </Grid>
        {!equipmentHide ? (
          <Grid item md={6} sm={12} xs={12}>
            <Box className={classes.labels}>Gross Vehicle Weight *</Box>
            <Controller
              control={control}
              name="grossVehicleWeight"
              render={({
                field: { onChange, value, name, ref, onBlur },
                formState,
              }) => {
                return (
                  <InputText
                    isFormSubmittedOnce={!!formState.submitCount}
                    inputRefObj={ref}
                    errors={errors.grossVehicleWeight?.message || ''}
                    name={name}
                    isRefreshValue={true}
                    value={value}
                    onChangeText={onChange}
                    isEditable={btnDisable}
                    className={classes.selectClassName}
                    type={'text'}
                    variant="standard"
                  />
                );
              }}
            />
          </Grid>
        ) : null}
        <Grid item md={6} sm={12} xs={12}>
          <Box className={classes.labels}>Ownership Type *</Box>
          <Controller
            control={control}
            defaultValue={isOwnerOperator ? OwnershipType.OwnerOperator : ''}
            name="ownershipType"
            render={({
              field: { onChange, value, name, ref, onBlur },
              formState,
            }) => {
              return (
                <SelectInput
                  isFormSubmittedOnce={!!formState.submitCount}
                  size={'small'}
                  defaultValue={
                    isOwnerOperator ? OwnershipType.OwnerOperator : ''
                  }
                  inputRefObj={ref}
                  errors={errors.ownershipType?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={isOwnerOperator ? OwnershipType.OwnerOperator : value}
                  onChangeText={(val) => {
                    onChange(val);
                    // setValue('primaryDriverContactId', '');
                    setPrimaryDriverHastric(
                      val !== 'Client/Company Owned' ? true : false,
                    );
                  }}
                  disabled={isOwnerOperator ? true : false}
                  isEditable={btnDisable}
                  selectClassName={classes.selectClassName}
                  optionsList={[
                    OwnershipType.Client,
                    OwnershipType.OwnerOperator,
                  ].map((val) => ({
                    label: val,
                    value: val,
                  }))}
                />
              );
            }}
          />
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Box className={classes.labels}>
            Primary Driver
            {primaryDriverHastric ? ' *' : ''}
          </Box>
          <Controller
            control={control}
            defaultValue=""
            name="primaryDriverContactId"
            render={({
              field: { onChange, value, name, ref, onBlur },
              formState,
            }) => {
              return (
                <SelectPrimaryDriver
                  disableNoneOption={
                    isOwnership === OwnershipType.OwnerOperator
                  }
                  errors={errors.primaryDriverContactId?.message || ''}
                  initialDriverName={initialDriverName}
                  onChange={onChange}
                  value={value}
                  ref={ref}
                  name={name}
                />
              );
            }}
          />
        </Grid>

        {authStore.getSHSSAccess() && equipmentHide && (
          <Grid item md={6} sm={12} xs={12}>
            <Box className={classes.labels}>Trailer Type</Box>
            <Controller
              control={control}
              defaultValue={Trailerype.Empty}
              name="trailerType"
              render={({
                field: { onChange, value, name, ref, onBlur },
                formState,
              }) => {
                return (
                  <SelectInput
                    isFormSubmittedOnce={!!formState.submitCount}
                    size={'small'}
                    defaultValue={Trailerype.Empty}
                    inputRefObj={ref}
                    errors={errors.trailerType?.message || ''}
                    name={name}
                    isRefreshValue={true}
                    value={value}
                    onChangeText={(val) => {
                      onChange(val);
                    }}
                    disabled={isOwnerOperator ? true : false}
                    isEditable={btnDisable}
                    selectClassName={classes.selectClassName}
                    optionsList={[
                      Trailerype.DryVan,
                      Trailerype.Reefer,
                      Trailerype.Flatbed,
                      Trailerype.StepDeck,
                      Trailerype.PowerOnly,
                    ].map((val) => ({
                      label: val,
                      value: val,
                    }))}
                  />
                );
              }}
            />
          </Grid>
        )}
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          display={'flex'}
          alignItems={'center'}>
          <Controller
            control={control}
            defaultValue={false}
            name="isPermanantRegistration"
            render={({ field: { onChange, value, name, ref }, formState }) => {
              return (
                <Checkbox
                  inputRef={ref}
                  disabled={btnDisable}
                  checked={value}
                  onChange={(ev) => {
                    onChange(ev.target.checked);
                    setDateFieldHide(ev.target.checked);
                  }}
                  name={name}
                  value={value}
                  color="success"
                  tabIndex={0}
                />
              );
            }}
          />
          <Box
            className={classes.labels}
            fontFamily={'FiraSans-Medium !important'}>
            Permanent Registration?
          </Box>
        </Grid>
        {!dateFieldHide ? (
          <Grid item md={6} sm={12} xs={12}>
            <Box className={classes.labels}>Registration Expiration Date *</Box>
            <Controller
              control={control}
              defaultValue={null}
              name="registrationExpDate"
              render={({
                field: { onChange, value, name, ref, onBlur },
                formState,
              }) => {
                return (
                  <SelectDatePicker
                    isFormSubmittedOnce={!!formState.submitCount}
                    inputRefObj={ref}
                    errors={errors.registrationExpDate?.message || ''}
                    name={name}
                    isRefreshValue={true}
                    value={value}
                    onChangeDate={onChange}
                    disabled={btnDisable}
                    className={classes.selectClassName}
                  />
                );
              }}
            />
          </Grid>
        ) : (
          ''
        )}
        {!equipmentHide && (
          <Grid item md={6} sm={12} xs={12}>
            <Box className={classes.labels}>Registration Type *</Box>
            <Controller
              control={control}
              defaultValue=""
              name="registrationType"
              render={({
                field: { onChange, value, name, ref, onBlur },
                formState,
              }) => {
                return (
                  <SelectInput
                    isFormSubmittedOnce={!!formState.submitCount}
                    size={'small'}
                    inputRefObj={ref}
                    errors={errors.registrationType?.message || ''}
                    name={name}
                    isRefreshValue={true}
                    value={value}
                    onChangeText={(val) => {
                      onChange(val);
                      // annualReportStore.setAnnualDetail('registeredAgent', val);
                    }}
                    isEditable={btnDisable}
                    selectClassName={classes.selectClassName}
                    optionsList={
                      watch('isPermanantRegistration')
                        ? [RegistrationType.Local].map((val) => ({
                            label: val,
                            value: val,
                          }))
                        : [RegistrationType.IRP, RegistrationType.Local].map(
                            (val, index) => ({
                              label: val,
                              value: val,
                            }),
                          )
                    }
                  />
                );
              }}
            />
          </Grid>
        )}
        {/* {!dateFieldHide ? ( */}
        <Grid item md={6} sm={12} xs={12}>
          <Box className={classes.labels}>Last Annual Inspection Date</Box>
          <Controller
            control={control}
            defaultValue={null}
            name="lastAnnualInspectionDate"
            render={({
              field: { onChange, value, name, ref, onBlur },
              formState,
            }) => {
              return (
                <SelectDatePicker
                  isFormSubmittedOnce={!!formState.submitCount}
                  inputRefObj={ref}
                  errors={errors.lastAnnualInspectionDate?.message || ''}
                  name={name}
                  isRefreshValue={true}
                  value={value}
                  onChangeDate={onChange}
                  disabled={btnDisable}
                  className={classes.selectClassName}
                  maxDate={new Date()}
                />
              );
            }}
          />
        </Grid>
        {/* ) : (
          ''
        )} */}
        {getValues('dotFtBundleStatus') ? (
          <Grid
            hidden={getValues('equipmentType') === 'trailer'}
            item
            md={12}
            sm={12}
            xs={12}>
            {getValues('equipmentType') === 'trailer'}
            <Box className={classes.labels}>
              Will Simplex be completing the fuel taxes for this unit under the
              current IFTA license? *
            </Box>
            <Controller
              control={control}
              defaultValue=""
              name="isSimplexCompletingFuelTaxes"
              render={({
                field: { onChange, value, name, ref, onBlur },
                formState,
              }) => {
                return (
                  <SelectInput
                    isFormSubmittedOnce={!!formState.submitCount}
                    size={'small'}
                    inputRefObj={ref}
                    errors={errors.isSimplexCompletingFuelTaxes?.message || ''}
                    name={name}
                    isRefreshValue={true}
                    value={
                      getValues('equipmentType') === 'trailer' ? false : value
                    }
                    onChangeText={(val) => {
                      onChange(val);
                      // annualReportStore.setAnnualDetail('registeredAgent', val);
                    }}
                    disabled={getValues('equipmentType') === 'trailer'}
                    isEditable={btnDisable}
                    selectClassName={classes.selectClassName}
                    sortByOptionDisable={true}
                    optionsList={[
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' },
                    ]}
                  />
                );
              }}
            />
          </Grid>
        ) : (
          ''
        )}
        {!fleetid && (
          <>
            <Grid item md={12} sm={12} xs={12}>
              <FilesList
                fileSizeConvert={true}
                acceptMimeType={AllowedUploadMimeTypes}
                headingTitle={
                  <div>
                    Annual Inspection
                    <span style={{ color: '#979598' }}>(Optional)</span>
                  </div>
                }
                files={vqFile}
                uploadLabelName="Upload"
                onFileChange={(e) => fileOnChange(e, 'vqFile')}
                onClearFile={fileClear}
                disabled={btnDisable}
              />
              {fileValidation && (!vqFile || !vqFile.length) ? (
                <div className="error-msg"> Required</div>
              ) : (
                ''
              )}
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <FilesList
                fileSizeConvert={true}
                acceptMimeType={AllowedUploadMimeTypes}
                headingTitle={
                  <div>
                    Attach Registration{' '}
                    <span style={{ color: '#979598' }}>(Optional)</span>
                  </div>
                }
                files={registration}
                uploadLabelName="Upload"
                onFileChange={(e) => fileOnChange(e, 'registration')}
                onClearFile={fileClearReg}
                disabled={btnDisable}
              />
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
};

export default observer(AddToFleetForm);
