import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from 'mobx-react-lite';
import React, { ReactElement } from 'react';

type RadioInputProps = {
  errors?: string;
  value: any;
  title: string;
  name: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  optionsList?: Array<{
    label: string;
    value: string;
    disabled: boolean;
  }>;
  size?: 'small | medium';
  bottomSpacer?: boolean;
  onValueChange?: (src: string) => void;
  refObject?: React.Ref<any>;
};

export const RadioInput = observer((props: RadioInputProps): ReactElement => {
  const classes = useStyles();
  const {
    errors,
    value,
    name,
    title,
    isRequired,
    optionsList = [
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ],
    bottomSpacer,
    onValueChange,
    refObject,
  } = props;

  const [radioValue, setRadioValue] = React.useState(value || '');
  const onRadioChange = React.useCallback(
    (val: string) => {
      setRadioValue(val);
      onValueChange && onValueChange(val);
    },
    [onValueChange],
  );
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    onRadioChange(value);
  };

  return (
    <>
      <FormControl>
        <FormLabel id={`${name}-radio-buttons-group-label`}>
          {`${title} ${isRequired ? '*' : ''}`}
        </FormLabel>
        <RadioGroup
          ref={refObject}
          aria-labelledby={`${name}-radio-buttons-group`}
          name={`${name}-controlled-radio-buttons-group`}
          value={radioValue}
          onChange={handleRadioChange}>
          {optionsList &&
            optionsList.length &&
            optionsList.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
        </RadioGroup>
        {errors && (
          <FormHelperText className={classes.helperText}>
            {errors}
          </FormHelperText>
        )}
      </FormControl>
      {bottomSpacer && <Box sx={{ m: 3 }} />}
    </>
  );
});

const useStyles = makeStyles((theme) =>
  createStyles({
    helperText: {
      color: `${theme.palette.warning.main} !important`,
      marginLeft: 'unset !important',
      position: 'relative',
    },
    optionsClassName: {
      backgroundColor: `${theme.palette.common.white} !important`,
      paddingLeft: '10px !important',
      paddingRight: '27px !important',
      textTransform: 'capitalize',
    },
  }),
);
