import { Config } from './index';

export const baseUrl = Config.BASE_URL;

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export const ApiRoutes = {
  Accidents: {
    Export: {
      Endpoint: '/accidents/export',
      Method: HttpMethod.GET,
    },
    GetAllDrivers: {
      Endpoint: '/drivers/match-driver-list',
      Method: HttpMethod.GET,
    },
    GetAllEquipments: {
      Endpoint: '/equipment/match-unit-list',
      Method: HttpMethod.GET,
    },
    GetList: {
      Endpoint: '/accidents',
      Method: HttpMethod.GET,
    },
    Match: {
      Endpoint: '/accidents/match',
      Method: HttpMethod.POST,
    },
    submitDataQChallenge: {
      Endpoint: '/accidents/submit-data-q',
      Method: HttpMethod.POST,
    },
  },
  ActivityList: {
    GetList: {
      Endpoint: '/tpts/activity-list/search',
      Method: HttpMethod.GET,
    },
  },
  AddToFleet: {
    Create: {
      Endpoint: '/dot/add-fleet',
      Method: HttpMethod.POST,
    },
    Get: {
      Endpoint: '/dot/add-fleet',
      Method: HttpMethod.GET,
    },
    GetById: {
      Endpoint: (id: string) => `/equipment/unit/${id}`,
      Method: HttpMethod.GET,
    },
    Update: {
      Endpoint: (id: string) => `/equipment/${id}`,
      Method: HttpMethod.PUT,
    },
    UpdateFiles: {
      Endpoint: '/dot/add-fleet/documents',
      Method: HttpMethod.POST,
    },
  },
  Admins: {
    CreateAdminRoles: {
      Endpoint: '/company-admins/role',
      Method: HttpMethod.POST,
    },
    CreateAdminUsers: {
      Endpoint: '/company-admins',
      Method: HttpMethod.POST,
    },
    DeleteAdmins: {
      Endpoint: '/company-admins/',
      Method: HttpMethod.DELETE,
    },
    GetAdminRolesList: {
      Endpoint: '/company-admins/roles',
      Method: HttpMethod.GET,
    },
    GetAdminUsersList: {
      Endpoint: '/company-admins/users',
      Method: HttpMethod.GET,
    },
    GetList: {
      Endpoint: '/company-admins',
      Method: HttpMethod.GET,
    },
    ResendPortalInvitation: {
      Endpoint: '/company-admins/resend-portal-invitation',
      Method: HttpMethod.POST,
    },
    UpdateAdmins: {
      Endpoint: '/company-admins/',
      Method: HttpMethod.PUT,
    },
  },
  AnnualReport: {
    Add: {
      Endpoint: '/tpts/annual-reports',
      Method: HttpMethod.POST,
    },
    GetInitialData: {
      Endpoint: '/tpts/annual-reports',
      Method: HttpMethod.GET,
    },
  },
  Auth: {
    Contact: {
      Endpoint: (id: string) => `/users/sign-in/${id}`,
      Method: HttpMethod.POST,
    },
    DirectLogin: {
      Endpoint: '/impersonate/login',
      Method: HttpMethod.POST,
    },
    Login: {
      Endpoint: '/users/sign-in',
      Method: HttpMethod.POST,
    },
    TokenXchange: {
      Endpoint: '/users/exchange',
      Method: HttpMethod.POST,
    },
  },
  Credentials: {
    DOTpin: {
      GET: {
        Endpoint: '/credentials/dot-pin',
        Method: HttpMethod.GET,
      },
      PUT: {
        Endpoint: '/credentials/dot-pin',
        Method: HttpMethod.PUT,
      },
    },
    FMCSA: {
      AUTH: {
        Endpoint: '/credentials/fmcsa-authentication',
        Method: HttpMethod.POST,
      },
      GET: {
        Endpoint: '/credentials/fmcsa',
        Method: HttpMethod.GET,
      },
      POST: {
        Endpoint: '/credentials/fmcsa-login-gov',
        Method: HttpMethod.POST,
      },
      PUT: {
        Endpoint: '/credentials/fmcsa',
        Method: HttpMethod.PUT,
      },
    },
  },
  DB: {
    RetrieveEntityDataAsOptionSet: {
      Endpoint: '/db/entity-optionset',
      Method: HttpMethod.GET,
    },
    RetrieveOptionSet: {
      Endpoint: '/db/optionset',
      Method: HttpMethod.GET,
    },
  },
  DOT: {
    Submit: {
      MVR: {
        Endpoint: '/dot/mvr',
        Method: HttpMethod.POST,
      },
      Terminate_Driver: {
        Endpoint: '/dot/terminate-driver',
        Method: HttpMethod.POST,
      },
    },
    Terminals: {
      Endpoint: '/dot/terminals',
      Method: HttpMethod.GET,
    },
  },
  Dashboard: {
    GetBasicCategories: {
      Endpoint: '/dashboard/basic',
      Method: HttpMethod.GET,
    },
    GetData: {
      Endpoint: '/dashboard/data',
      Method: HttpMethod.GET,
    },
    GetInspectionData: {
      Endpoint: '/dashboard/inspection-count',
      Method: HttpMethod.GET,
    },
    GetTodos: {
      Endpoint: '/dashboard',
      Method: HttpMethod.GET,
    },
    GetTrendingItems: {
      Endpoint: '/dashboard/trending-items',
      Method: HttpMethod.GET,
    },
    Trends: {
      Driver: {
        Endpoint: '/dashboard/top-10-drivers',
        Method: HttpMethod.GET,
      },
      Equipment: {
        Endpoint: '/dashboard/top-10-equipments',
        Method: HttpMethod.GET,
      },
      'Top 10 Trending': {
        Endpoint: '/dashboard/top-10-trending',
        Method: HttpMethod.GET,
      },
    },
  },
  DataQChallenge: {
    Add: {
      Endpoint: '/dot/data-q-challenge',
      Method: HttpMethod.POST,
    },
    Get: {
      Endpoint: '/dot/data-q-challenge',
      Method: HttpMethod.GET,
    },
  },
  DocuSign: {
    DriverApplication: {
      Endpoint: '/docusign',
      Method: HttpMethod.GET,
    },
    MVR: {
      Endpoint: '/docusign/mvr',
      Method: HttpMethod.GET,
    },
    PSP: {
      Endpoint: '/docusign/psp',
      Method: HttpMethod.GET,
    },
  },
  Documents: {
    downloadDocument: {
      Endpoint: '/files/download',
      Method: HttpMethod.GET,
    },
    getAdminList: {
      Endpoint: '/documents',
      Method: HttpMethod.GET,
    },
    getDriverList: {
      Endpoint: '/documents/driver/',
      Method: HttpMethod.GET,
    },
    getUnitList: {
      Endpoint: '/documents/unit/',
      Method: HttpMethod.GET,
    },
  },
  DotActivityList: {
    GetList: {
      Endpoint: '/dot/activity-list/search',
      Method: HttpMethod.GET,
    },
  },
  DotUpdate: {
    Add: {
      Endpoint: '/tpts/dot',
      Method: HttpMethod.POST,
    },
    Get: {
      Endpoint: '/tpts/dot',
      Method: HttpMethod.GET,
    },
  },
  Driver: {
    Export: {
      Endpoint: '/drivers/export',
      Method: HttpMethod.GET,
    },
    FindDriversBySearchParams: {
      Endpoint: '/drivers/find',
      Method: HttpMethod.GET,
    },
    GetDetailsByContactId: {
      Endpoint: '/drivers/',
      Method: HttpMethod.GET,
    },
    GetList: {
      Endpoint: '/drivers',
      Method: HttpMethod.GET,
    },
    GetListBySearch: {
      Endpoint: '/drivers',
      Method: HttpMethod.GET,
    },
    NewDriver: {
      Endpoint: '/dot/new-driver',
      Method: HttpMethod.POST,
    },
    PrimaryDrivers: {
      Endpoint: '/drivers/primary-driver-list',
      Method: HttpMethod.GET,
    },
    UpdateDriver: {
      Endpoint: (id: string) => `/drivers/${id}/driver-info`,
      Method: HttpMethod.PUT,
    },
    UpdateMedical: {
      Endpoint: (id: string) => `/drivers/${id}/medical-card`,
      Method: HttpMethod.PUT,
    },
  },
  Equipment: {
    GetByDriverContactId: {
      Endpoint: '/equipment/',
      Method: HttpMethod.GET,
    },
    GetList: {
      Endpoint: '/equipment',
      Method: HttpMethod.GET,
    },
    GetListBySearch: {
      Endpoint: '/equipment',
      Method: HttpMethod.GET,
    },
  },
  EquipmentInspection: {
    Add: {
      Endpoint: '/dot/equipment-inspection',
      Method: HttpMethod.POST,
    },
  },
  FTQuarterFilling: {
    Add: {
      Endpoint: '/tpts/ft-quarterly',
      Method: HttpMethod.POST,
    },
    Get: {
      Endpoint: '/tpts/ft-quarterly',
      Method: HttpMethod.GET,
    },
  },
  File: {
    GetFile: {
      Endpoint: (urlPath: string) => urlPath,
      Method: HttpMethod.GET,
    },
    Upload: {
      Endpoint: '/files/upload',
      Method: HttpMethod.POST,
    },
  },
  Fleet: {
    Export: {
      Endpoint: '/equipment/export',
      Method: HttpMethod.GET,
    },
    GetList: {
      Endpoint: '/equipment',
      Method: HttpMethod.GET,
    },
    GetListBySearch: {
      Endpoint: '/equipment',
      Method: HttpMethod.GET,
    },
  },

  IFTA: {
    Add: {
      Endpoint: '/tpts/ifta',
      Method: HttpMethod.POST,
    },
    Get: {
      Endpoint: '/tpts/ifta',
      Method: HttpMethod.GET,
    },
  },
  IRPService: {
    CreateIRPService: {
      Endpoint: '/tpts/irp',
      Method: HttpMethod.POST,
    },
    GetIRPService: {
      Endpoint: '/tpts/irp',
      Method: HttpMethod.GET,
    },
  },
  InspectionsAndViolations: {
    Export: {
      Endpoint: '/inspections/export',
      Method: HttpMethod.GET,
    },
    GetAllDrivers: {
      Endpoint: '/drivers/match-driver-list',
      Method: HttpMethod.GET,
    },
    GetAllEquipments: {
      Endpoint: '/equipment/match-unit-list',
      Method: HttpMethod.GET,
    },
    GetList: {
      Endpoint: '/inspections',
      Method: HttpMethod.GET,
    },
    GetMatch: {
      Endpoint: '/inspections/match?inspectionIds=',
      Method: HttpMethod.GET,
    },
    Match: {
      Endpoint: '/inspections/match',
      Method: HttpMethod.POST,
    },
    submitDataQChallenge: {
      Endpoint: '/inspections/submit-data-q',
      Method: HttpMethod.POST,
    },
  },
  Mvr: {
    Add: {
      Endpoint: '/tpts/ucr',
      Method: HttpMethod.POST,
    },
    Get: {
      Endpoint: '/tpts/ucr',
      Method: HttpMethod.GET,
    },
  },
  Notification: {
    Get: {
      Endpoint: '/notifications/me',
      Method: HttpMethod.GET,
    },
  },
  RoadTaxes: {
    CreateRoadTax: {
      Endpoint: '/tpts/road-taxes',
      Method: HttpMethod.POST,
    },
  },
  Settings: {
    getCompanyInfo: {
      Endpoint: '/settings/company-info',
      Method: HttpMethod.GET,
    },
    getNotificationInfo: {
      Endpoint: '/settings/notification',
      Method: HttpMethod.GET,
    },
    putNotificationStatus: {
      Endpoint: '/settings/notification',
      Method: HttpMethod.PUT,
    },
  },
  StatePermits: {
    CreatePermit: {
      Endpoint: '/tpts/state-permit',
      Method: HttpMethod.POST,
    },
    GetStates: {
      Endpoint: '/tpts/state-permit',
      Method: HttpMethod.GET,
    },
  },
  States: {
    GetList: {
      Endpoint: '/options/states',
      Method: HttpMethod.GET,
    },
  },
  TerminateEquipment: {
    Add: {
      Endpoint: '/dot/terminate-equipment',
      Method: HttpMethod.POST,
    },
  },

  Todo: {
    DismissTodo: {
      Endpoint: (todoId: string) => `/todos/dismiss/${todoId}`,
      Method: HttpMethod.PUT,
    },
    Export: {
      Endpoint: '/todos/export',
      Method: HttpMethod.GET,
    },
    FormSubmit: {
      Endpoint: '/todos',
      Method: HttpMethod.PUT,
    },
    GetList: {
      Endpoint: '/todos',
      Method: HttpMethod.GET,
    },
    GetListBySearch: {
      Endpoint: '/todos',
      Method: HttpMethod.GET,
    },
    GetPrimaryDrivers: {
      Endpoint: '/todos/form-data',
      Method: HttpMethod.GET,
    },
    GetTodoDetailsForAnAssignment: {
      Endpoint: '/training-assignments/todo-details',
      Method: HttpMethod.GET,
    },
    UpdateAcknowledgmentFormTodo: {
      Endpoint: '/todos/acknowledgment-form',
      Method: HttpMethod.POST,
    },
  },
  Training: {
    ChangeTrainingAssignmentDueDate: {
      Endpoint: '/training-assignments/{assignmentId}/change-date',
      Method: HttpMethod.PUT,
    },
    CreateCustomTrainingAssignment: {
      Endpoint: '/training-assignments/custom-assignment',
      Method: HttpMethod.POST,
    },
    CreateTrainingAccidentAssignments: {
      Endpoint: '/training-assignments/create-accident-assignments',
      Method: HttpMethod.POST,
    },
    CreateTrainingInspectionAssignments: {
      Endpoint: '/training-assignments/create-inspection-assignments',
      Method: HttpMethod.POST,
    },
    DismissAssignment: {
      Endpoint: '/training-assignments/{assignmentId}/dismiss',
      Method: HttpMethod.PUT,
    },
    GetAllTrainingsForCustomAssignment: {
      Endpoint: '/trainings/custom-assign',
      Method: HttpMethod.GET,
    },
    GetAllUsersForTraining: {
      Endpoint: '/training-assignments/list-users',
      Method: HttpMethod.GET,
    },
    GetCertificateForAnAssignment: {
      Endpoint: '/files/download/certificate',
      Method: HttpMethod.GET,
    },
    GetQuizDetailsForAssignment: {
      Endpoint: '/quizzes/assignment/{assignmentId}',
      Method: HttpMethod.GET,
    },
    GetReassignAssignmentContacts: {
      Endpoint: '/training-assignments/{assignmentId}/reassign-contacts',
      Method: HttpMethod.GET,
    },
    GetTrainingAccidentRecommendations: {
      Endpoint: '/trainings/accident-recommendations',
      Method: HttpMethod.GET,
    },
    GetTrainingAccidents: {
      Endpoint: '/accidents/training',
      Method: HttpMethod.GET,
    },
    GetTrainingAssigned: {
      Endpoint: '/training-assignments/all-assigned',
      Method: HttpMethod.GET,
    },
    GetTrainingAssignmentReasons: {
      Endpoint: '/training-assignments/list-reasons',
      Method: HttpMethod.GET,
    },
    GetTrainingAssignmentStatuses: {
      Endpoint: '/training-assignments/list-statuses',
      Method: HttpMethod.GET,
    },
    GetTrainingCompleted: {
      Endpoint: '/training-assignments/all-completed',
      Method: HttpMethod.GET,
    },
    GetTrainingFileLink: {
      Endpoint: '/files/training-file',
      Method: HttpMethod.POST,
    },
    GetTrainingInspectionRecommendations: {
      Endpoint: '/trainings/inspection-recommendations',
      Method: HttpMethod.GET,
    },
    GetTrainingInspections: {
      Endpoint: '/inspections/training',
      Method: HttpMethod.GET,
    },
    ReassignAssignment: {
      Endpoint: '/training-assignments/{assignmentId}/reassign',
      Method: HttpMethod.POST,
    },
    UpdateQuizAnswersForAssignment: {
      Endpoint: '/quizzes/assignment/{assignmentId}/answers',
      Method: HttpMethod.PUT,
    },
    UpdateTrainingAssignmentStatus: {
      Endpoint: '/training-assignments/{assignmentId}/status',
      Method: HttpMethod.PUT,
    },
  },
  TrendDetails: {
    Driver: {
      Endpoint: '/dashboard/drill-down-by-driver',
      Method: HttpMethod.GET,
    },
    Equipment: {
      Endpoint: '/dashboard/drill-down-by-equipment',
      Method: HttpMethod.GET,
    },
    Top10Trending: {
      Endpoint: '/dashboard/drill-down-by-trending',
      Method: HttpMethod.GET,
    },
  },
  Ucr: {
    Add: {
      Endpoint: '/tpts/ucr',
      Method: HttpMethod.POST,
    },
    Get: {
      Endpoint: '/tpts/ucr',
      Method: HttpMethod.GET,
    },
  },
  User: {
    ChangePassword: {
      Endpoint: '/users/change-password',
      Method: HttpMethod.POST,
    },
    CreatePassword: {
      Endpoint: '/users/create-password',
      Method: HttpMethod.POST,
    },
    ForgotPassword: {
      Endpoint: '/users/forgot-password',
      Method: HttpMethod.POST,
    },
    ProfileAccess: {
      Endpoint: '/users/profile-access',
      Method: HttpMethod.GET,
    },
    ProfileInfo: {
      Endpoint: '/users/me',
      Method: HttpMethod.GET,
    },
    ResetPassword: {
      Endpoint: '/users/reset-password',
      Method: HttpMethod.POST,
    },
    UpdateMedicalInfo: {
      Endpoint: '/users/medical-info',
      Method: HttpMethod.PUT,
    },
    UpdateProfileInfo: {
      Endpoint: '/users/profile-info',
      Method: HttpMethod.PUT,
    },
  },
  Violations: {
    GetList: {
      Endpoint: '/violations',
      Method: HttpMethod.GET,
    },
  },
};
