import { yupResolver } from '@hookform/resolvers/yup';
import {
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useCustomStore } from '../../hooks';
import { NewDriverSetupInputs } from '../../types';
import {
  dot_form_slugs,
  formConfig,
  licenseTypesWithNoEndorsements,
} from '../../utils/config';
import {
  fieldsForFileType,
  getFileNamingConvention,
  getStateNameByStateId,
  todoVariantToFileType,
} from '../../utils/fileName.config';
import { processEndorsementsSelect } from '../../utils/form';
import { capitalizeConfigString } from '../../utils/helper';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import DialogMigrate from '../Dialog/DialogMigrate';
import Footer from '../Footer';
import { InputText } from '../Forms/InputText';
import SelectPrimaryDriver from '../SelectPrimaryDriver';
import SimplexDatepicker from '../SimplexDatepicker';
import UploadFileBox from '../UploadFileBox';
import useFormStyles from './ToDoForm.styles';
import { ToDoFormIProps } from './types';

const fieldsWithStateslist = ['licenseStateId', 'plateState', 'cabCardState'];

type Field = {
  data_type: string;
  name: string;
  label: string;
  config?: any;
  showLabel?: boolean;
};

const getValidatorSchemaObj = (config: any, data_type: string) => {
  if (data_type === 'number') {
    const { max, min } = config;
    const min_: string = min;
    const max_: string = max;
    if (typeof max === 'number' && typeof min === 'number') {
      if (config.isSHSSOptional) {
        return yup
          .number()
          .max(max, `Maximum limit should be ${max_}`)
          .min(min, `Minimum limit should be ${min_}`)
          .transform((value) => (Number.isNaN(value) ? null : value))
          .nullable()
          .optional();
      }
      return yup
        .number()
        .max(max, `Maximum limit should be ${max_}`)
        .min(min, `Minimum limit should be ${min_}`)
        .required('Required');
    }
    if (max) {
      if (config.isSHSSOptional) {
        return yup
          .number()
          .max(max, `Maximum limit should be ${max_}`)
          .transform((value) => (Number.isNaN(value) ? null : value))
          .nullable()
          .optional();
      }
      return yup
        .number()
        .max(max, `Maximum limit should be ${max_}`)
        .required('Required');
    }
    if (typeof min === 'number') {
      if (config.isSHSSOptional) {
        return yup
          .number()
          .integer('Number should be integer. Not decimals')
          .min(min, `Minimum limit should be ${min_}`)
          .transform((value) => (Number.isNaN(value) ? null : value))
          .nullable()
          .optional();
      }
      return yup
        .number()
        .integer('Number should be integer. Not decimals')
        .min(min, `Minimum limit should be ${min_}`)
        .required('Required');
    }
    return yup.number().required('Required');
  }
  if (data_type === 'list') {
    const { min, when } = config;
    if (when) {
      const [field, validator] = when;
      return yup.array().when(field, validator).nullable().optional();
    }
    return yup.array().min(min, 'Required').nullable().optional();
  }
  if (data_type === 'primary_driver') {
    return yup.string().nullable().required('Required');
  }
  if (data_type === 'list->string') {
    if (config.isSHSSOptional) return yup.string().notRequired().nullable();
    else
      return config.isOptional
        ? yup.string().notRequired().nullable()
        : yup.string().required('Required');
  }
  if (data_type === 'string') {
    const { maxlength, minLength, regex, msgForRegex, isSHSSOptional } = config;
    if (maxlength) {
      const str: string = maxlength;
      if (config.isSHSSOptional) {
        return yup
          .string()
          .max(maxlength, `Shoud not exceed ${str} characters`)
          .notRequired()
          .nullable();
      }
      return yup
        .string()
        .max(maxlength, `Shoud not exceed ${str} characters`)
        .required('Required');
    }
    if (minLength) {
      const str: string = minLength;
      if (config.isSHSSOptional) {
        return yup
          .string()
          .min(minLength, `Shoud not be less than ${str} characters`)
          .notRequired()
          .nullable();
      }
      return yup
        .string()
        .min(minLength, `Shoud not be less than ${str} characters`)
        .required('Required');
    }
    if (regex) {
      if (config.isSHSSOptional) {
        return yup.string().nullable().matches(regex, { message: msgForRegex });
      }
      return yup
        .string()
        .matches(regex, { message: msgForRegex })
        .required('Invalid characters entered');
    }
    if (isSHSSOptional) {
      return yup.string().optional().nullable();
    }
  }
  if (data_type === 'bool') {
    return yup.bool().nullable();
  }
  if (config.isSHSSOptional) {
    return yup.string().optional().nullable();
  }
  return yup.string().required('Required');
};

const getValidatorSchema = (formFields: any[]) => {
  const subSchema = formFields.reduce((acc, field) => {
    const { config = {}, data_type = '', name } = field;
    const validatorSchema = getValidatorSchemaObj(config, data_type);
    return {
      ...acc,
      [name]: validatorSchema,
    };
  }, {});
  return yup.object().shape(subSchema);
};

const Label = styled(Typography)(() => ({
  fontSize: 13,
  margin: '2px 0',
  padding: 0,
}));

const useStyles = makeStyles(() => ({
  checkbox: {
    fontSize: 14,
  },
  fcContainer: {
    margin: '10px 0',
  },
}));

type TodoFCProps = {
  control: Control;
  field: Field;
  formValue: any;
  onValueChange: (e: any, name: string) => any;
  variant: TodoFormVariant;
};

// FormControl from TODO form
const Error = styled(Typography)(() => ({
  color: 'red',
  fontSize: 12,
}));

const TodoFC: React.FC<TodoFCProps> = ({
  field,
  formValue,
  control,
  onValueChange,
  variant,
}) => {
  const { showLabel = true } = field;
  const [, setCurrentVal] = useState<any>();
  const styles = useStyles();

  const getInputByDataType = (inputProps: any, data_type = 'alphanumeric') => {
    const inputs: { [key: string]: (props_: any) => JSX.Element } = {
      bool: (props_: any) => {
        props_.value =
          typeof props_.value === 'boolean' ? props_.value : !!props_.value;
        return (
          <FormControlLabel
            onChange={(e: any) => {
              props_.onChangeText(e.target.checked);
            }}
            className={styles.checkbox}
            label={field.label}
            control={<Checkbox checked={props_.value} {...props_} />}
          />
        );
      },
      date: (props_: any) => {
        if (Array.isArray(props_.minDate)) {
          const [fun, param] = props_.minDate;
          props_.minDate = fun(formValue[param]);
        }
        return (
          <SimplexDatepicker
            {...props_}
            disableHighlightToday
            onChange={(e: any) => {
              if (e) {
                props_.onChangeText(moment(e).format('MM/DD/YYYY'));
              } else {
                props_.onChangeText('');
              }
            }}
          />
        );
      },
      list: (props_: any) => {
        const newProps = { ...props_, value: props_.value || [] };
        const { disabled = [] } = props_;
        const [fieldKey, isDisabled] = disabled;

        return (
          <FormControl fullWidth>
            <Select
              {...newProps}
              disabled={isDisabled(formValue[fieldKey])}
              displayEmpty
              multiple
              renderValue={(selected: any) => {
                return selected?.map((option: any) => option).join(', ');
              }}
              onChange={(e) => {
                const value = e.target.value as string[];
                const newValue = processEndorsementsSelect(value);
                newProps.onChangeText(newValue);
              }}>
              <MenuItem value="None" key={-1}>
                <Checkbox checked={props_.value.indexOf('None') > -1} />
                None
              </MenuItem>
              {Array.isArray(props_.options) &&
                props_.options.map((option: any, index: number) => (
                  <MenuItem key={index} value={option.value}>
                    <Checkbox
                      checked={props_.value.indexOf(option.value) > -1}
                    />
                    {option.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        );
      },
      'list->string': (props_: any) => {
        const newProps = {
          ...props_,
          value: props_.value || '-',
        };
        return (
          <Select
            {...newProps}
            onChange={(e) => {
              const value = e.target.value;
              if (value === '-') return;
              newProps.onChangeText(value);
            }}>
            <MenuItem value="-" selected key={-1}>
              Select
            </MenuItem>
            {Array.isArray(props_.options) &&
              props_.options.map((option: any, index: number) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        );
      },
      number: (props_: any) => (
        <input
          min={0}
          step="1"
          className="input-number"
          {...props_}
          onChange={(e) => {
            props_.onChangeText(e.currentTarget.value);
          }}
          type="number"
        />
      ),
      primary_driver: ({ onChangeText, props_ }) => (
        <SelectPrimaryDriver onChange={onChangeText} {...props_} />
      ),
      string: (props_: any) => (
        <InputText
          {...props_}
          isEditable={false}
          isRefreshValue={true}
          type="text"
          variant="standard"
          {...(props_.maxlength ? { maxLengthVal: props_.maxlength } : {})}
        />
      ),
    };
    return (
      <>
        {inputs[data_type](inputProps)}
        {(!inputProps.isOptional &&
          (inputProps.value === '' ||
            inputProps.value === null ||
            inputProps.value === undefined) && (
            <Error paragraph>Required</Error>
          )) ||
          (inputProps.formState.errors[inputProps.name] && (
            <Error paragraph>
              {inputProps.formState.errors[inputProps.name].message}
            </Error>
          ))}
      </>
    );
  };

  return (
    <div className={styles.fcContainer}>
      {showLabel && <Label paragraph>{field.label}</Label>}
      <Controller
        control={control}
        {...field}
        render={({
          field: { onChange, value = '', name, ref, onBlur, ...rest },
          formState,
        }) => {
          const controlConfig = {
            formState,
            inputRefObj: ref,
            name,
            onBlurText: onBlur,
            onChangeText: (e: any) => {
              onChange(e);
              setCurrentVal(e);
              onValueChange(e, field.config.aliasKey || name);
            },
            value,
            ...(field.config || {}),
            required: field.config.required ? field.config.required : false,
            ...rest,
            isOptional: field.config.isSHSSOptional,
          };
          return getInputByDataType(controlConfig, field.data_type);
        }}
      />
    </div>
  );
};

const JustForm: React.FC<ToDoFormIProps> = ({
  contactId = '',
  fieldsConfig,
  onClose,
  preventSubmit = false,
  permitInfo,
  renderHeader,
  title,
  todoType = '',
  todoId = '',
  unitId = '',
  variant,
  newDriver,
}) => {
  const [fieldsChanged, setFieldsChanged] = useState<string[]>([]);
  const styles = useFormStyles();
  const [formUtils, setFormUtils] = useState<any>();
  const { authStore, driverListStore, todoStore, primaryDriversStore } =
    useCustomStore();
  const isAccountSHSS = authStore.getSHSSAccess();
  const {
    fields,
    hasFileUpload,
    title: preDefinedTitle,
    isFileOptional = authStore.getSHSSAccess(),
  } = formConfig[variant] || {
    fields: [],
    hasFileUpload: false,
    isFileOptional: authStore.getSHSSAccess(),
  };
  const [primaryDrivers, setPrimaryDrivers] = useState<PrimaryDriver[]>([]);
  const [unit, setUnit] = useState<{ [key: string]: string }>();
  const [driver, setDriver] = useState<{ [key: string]: string }>();
  const [permit, setPermit] = useState<{ [key: string]: string }>();
  const [attachment, setAttachment] = useState<File | null>();
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const [isTodoFormDirty, setIsTodoFormDirty] = useState(false);
  const [isInitRequestSent, setIsInitReqSent] = useState<boolean>();

  useEffect(() => {
    if (isInitRequestSent) {
      return;
    }

    setIsInitReqSent(true);
    (async () => {
      const permitId = permitInfo?.permitId as string;
      const ids = {
        ...((contactId && { contactId }) || {}),
        ...((unitId && { unitId }) || {}),
        ...((todoId && { todoId }) || {}),
        ...((permitId && { permitId }) || {}),
      };
      const { primaryDrivers, unit, driver, permit } =
        await driverListStore.getPrimaryDriversAndData({
          ...ids,
          todoType,
        });
      setIsLoading(false);
      if (primaryDrivers?.length) {
        setPrimaryDrivers([...primaryDrivers]);
      }
      if (contactId) setDriver(driver);
      if (unitId) {
        primaryDriversStore.setPrimaryDriverName(unit?.primaryDriverName);
        setUnit(unit);
      }
      if (!unitId && !contactId) setPermit(permit);
    })();
  }, [
    driverListStore,
    contactId,
    unitId,
    todoId,
    todoType,
    primaryDrivers,
    primaryDriversStore,
    permitInfo,
    isInitRequestSent,
  ]);

  const formFields = useMemo(() => {
    let fieldsWithConfig = fields.map((field) => {
      const selectedField = fieldsConfig?.find(
        (fieldConfig) => fieldConfig._key === field.name,
      ) || { _key: '', config: {} };
      return { ...field, config: { ...field.config, ...selectedField.config } };
    });

    fieldsWithConfig = fieldsWithConfig.map((field) => {
      if (fieldsWithStateslist.includes(field.name)) {
        const tempField = { ...field };
        tempField.config = {
          ...tempField.config,
          options: authStore.statesListOption.map(
            ({ id: value, fullName: label }) => ({ label, value }),
          ),
        };
        return tempField;
      }
      if (field.name === 'primaryDriverId' && primaryDrivers.length) {
        const tempField = { ...field };
        tempField.config = {
          ...tempField.config,
          options: primaryDrivers.map(
            ({ fullName: label, contactId: value }) => ({ label, value }),
          ),
        };
        return tempField;
      }
      return field;
    });
    return fieldsWithConfig;
  }, [fieldsConfig, fields, authStore, primaryDrivers]);

  const formFields_ = useMemo(() => {
    const { fullFormValue = {}, unregister = () => {} } = formUtils || {};
    let i = 0;
    const temp = [];
    while (i < formFields.length) {
      const { config = {}, name } = formFields[i];
      const { shouldHide, isSHSSOptional } = config;
      if (Array.isArray(shouldHide)) {
        const [fun, param] = shouldHide;
        const shouldHide_ = fun(fullFormValue[param]);
        if (shouldHide_) {
          i++;
          unregister(name, { keepDefaultValue: false, keepValue: false });
          continue;
        }
      }

      /** Validate SHSS Account Requirements */
      if (isSHSSOptional) {
        config.isSHSSOptional = isAccountSHSS;
      }

      if (
        isSHSSOptional &&
        isAccountSHSS &&
        variant === 'mvr' &&
        newDriver?.preEmploymentMVR === '140320002' &&
        (name === 'totalPoints' ||
          name === 'noOfAccidents' ||
          name === 'dateRan')
      ) {
        config.isSHSSOptional = false;
      }
      if (
        !preventSubmit &&
        isAccountSHSS &&
        variant === 'mvr' &&
        (name === 'totalPoints' ||
          name === 'noOfAccidents' ||
          name === 'dateRan')
      ) {
        config.isSHSSOptional = false;
      }
      if (
        isSHSSOptional &&
        isAccountSHSS &&
        variant === 'clearinghouse' &&
        newDriver?.preEmploymentCH === '140320002' &&
        (name === 'queryResult' || name === 'dateRan')
      ) {
        config.isSHSSOptional = false;
      }
      if (
        !preventSubmit &&
        isAccountSHSS &&
        variant === 'clearinghouse' &&
        (name === 'queryResult' || name === 'dateRan')
      ) {
        config.isSHSSOptional = false;
      }
      if (
        isSHSSOptional &&
        isAccountSHSS &&
        variant === 'medical-card' &&
        newDriver?.preEmploymentMC === '140320002' &&
        (name === 'issueDate' ||
          name === 'expirationDate' ||
          name === 'medicalExaminerName' ||
          name === 'nationalRegistryNumber')
      ) {
        config.isSHSSOptional = false;
      }
      if (
        !preventSubmit &&
        isAccountSHSS &&
        variant === 'medical-card' &&
        (name === 'issueDate' ||
          name === 'expirationDate' ||
          name === 'medicalExaminerName' ||
          name === 'nationalRegistryNumber')
      ) {
        config.isSHSSOptional = false;
      }
      if (
        isSHSSOptional &&
        isAccountSHSS &&
        variant === 'mvr' &&
        newDriver?.preEmploymentMVR === '140320000'
      ) {
        config.isSHSSOptional = true;
      }
      if (
        isSHSSOptional &&
        isAccountSHSS &&
        variant === 'clearinghouse' &&
        newDriver?.preEmploymentCH === '140320000'
      ) {
        config.isSHSSOptional = true;
      }
      if (
        isSHSSOptional &&
        isAccountSHSS &&
        variant === 'medical-card' &&
        newDriver?.preEmploymentMC === '140320000'
      ) {
        config.isSHSSOptional = true;
      }
      temp.push(formFields[i]);
      i++;
    }
    return temp;
  }, [
    formFields,
    formUtils,
    isAccountSHSS,
    newDriver?.preEmploymentCH,
    newDriver?.preEmploymentMC,
    newDriver?.preEmploymentMVR,
    preventSubmit,
    variant,
  ]);

  const schema = useMemo(() => getValidatorSchema(formFields_), [formFields_]);
  const { control, setValue, getValues, handleSubmit, unregister } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (unit != undefined && unitId) {
      const unitKeys = Object.keys(unit);
      unitKeys.forEach((unitKey) => {
        let value: any = unit[unitKey] || null;
        if (unitKey === 'expirationDate' && value) {
          const expDate = new Date(value);
          if (expDate < new Date()) {
            value = null;
          }
        }
        setValue(unitKey, value);
      });
    }
  }, [unit, unitId, setValue]);

  useEffect(() => {
    if (driver != undefined && contactId) {
      const driverKeys = Object.keys(driver);
      driverKeys.forEach((driverKey) => {
        let value = driver[driverKey] || null;
        if (driverKey === 'expirationDate' && value) {
          const expDate = new Date(value);
          if (expDate < new Date()) {
            value = null;
          }
        }
        setValue(driverKey, value);
      });
    }
  }, [driver, contactId, setValue]);

  useEffect(() => {
    if (permit != undefined && !contactId && !unitId) {
      const permitKeys = Object.keys(permit);
      permitKeys.forEach((permitKey) => {
        let value = permit[permitKey] || null;
        if (permitKey === 'expirationDate' && value) {
          const expDate = new Date(value);
          if (expDate < new Date()) {
            value = null;
          }
        }
        setValue(permitKey, value);
      });
    }
  }, [permit, contactId, setValue, unitId]);

  const allValues = getValues();

  useEffect(() => {
    if (newDriver && !isTodoFormDirty) {
      const driverKeys = Object.keys(allValues) as Array<
        keyof NewDriverSetupInputs
      >;
      driverKeys.forEach((driverKey) => {
        let value = newDriver[driverKey] || null;
        if (driverKey === 'licenseExpirationDate' && value) {
          const expDate = new Date(value as string);
          if (expDate < new Date()) {
            value = null;
          }
        }
        setValue(driverKey, value);
        setIsTodoFormDirty(true);
      });
    }
  }, [allValues, isTodoFormDirty, newDriver, setValue]);

  const handleFileUpload = (file: File | null) => {
    if (file) {
      setError('');
      setAttachment(file);
    }
  };

  const getPayLoadForUpload = () => {
    const fileType: FileType | undefined = todoVariantToFileType[variant];
    const fileTypeFieldsArray = fileType ? fieldsForFileType[fileType] : [];
    const fileTypeFieldsObj: Record<string, unknown> = {};

    fileTypeFieldsArray.forEach((x) => {
      if (x === 'state') {
        const stateId = fieldsWithStateslist.reduce(
          (acc, cv) => acc || allValues[cv],
          undefined,
        );

        fileTypeFieldsObj[x] = getStateNameByStateId(
          authStore.statesListOption,
          stateId,
        );
      } else if (x.toLocaleLowerCase().includes('date') && allValues[x]) {
        fileTypeFieldsObj[x] = moment(allValues[x]).format('MM/DD/YYYY');
      } else {
        fileTypeFieldsObj[x] = allValues[x]?.toString() || '';
      }
    });

    const fileName =
      fileType && getFileNamingConvention[fileType]
        ? getFileNamingConvention[fileType].name(fileTypeFieldsObj)
        : variant;
    const obj: { [key: string]: any } = {
      fileName,
      fileType,
      service: variant,
      type: 'account',
    };
    if (contactId) {
      obj.driver = {
        contactId,
      };
      obj.type = 'driver';
    }
    if (unitId) {
      obj.unit = {
        unitId,
      };
      obj.type = 'unit';
    }

    return [obj];
  };

  const uploadFile = async () => {
    const fData = new FormData();
    fData.set('files', attachment as File);
    fData.set('data', JSON.stringify(getPayLoadForUpload()));

    try {
      const { value, error } = await driverListStore.uploadFileForSignature(
        fData,
      );
      if (error) {
        return { error };
      }
      return value[0];
    } catch (error) {
      return { error };
    }
  };

  const handleFormControlChange = (text: any, name: string) => {
    setFormUtils({
      fullFormValue: getValues(),
      setValue,
      text,
      unregister,
    });
    setFieldsChanged([...fieldsChanged, name]);
  };

  const doSubmit = async (payload_: any) => {
    let document: any;
    if (hasFileUpload && !isFileOptional && !attachment) {
      setError('File is required');
      return;
    }
    const payload: { [key: string]: any } = {};
    for (const key in payload_) {
      if (payload_[key] === null && key !== 'isPermanantRegistration') {
        continue;
      }
      // Form is converting booleans to string.
      // So parsing back to booleans.
      if ({}.hasOwnProperty.call(payload_, key)) {
        const currentValue: string = payload_[key];
        if (key === 'isPermanantRegistration') {
          payload[key] = !!currentValue;
        } else {
          const x: { [key: string]: boolean } = {
            false: false,
            true: true,
          };

          payload[key] =
            x[currentValue] !== undefined ? x[currentValue] : currentValue;
        }
      }
      if (key === 'licenseEndorsements') {
        payload[key] =
          payload_[key].includes('None') ||
          licenseTypesWithNoEndorsements.includes(payload_.licenseType)
            ? []
            : payload_[key];
      }
      if (key === 'primaryDriverId')
        payload[key] = payload_[key] === 'None' ? '' : payload_[key];
    }

    /** Add new key for file upload */
    payload[`hasAttachment${capitalizeConfigString(variant)}`] = !!attachment;

    const setOfFields = new Set(fieldsChanged);
    payload.fieldsChanged = Array.from(setOfFields);
    if (preventSubmit) {
      onClose({ attachment, payload });
      return;
    }
    setIsLoading(true);

    if (hasFileUpload && attachment) {
      document = await uploadFile();
      if (!document || (document && document.error)) {
        enqueueSnackbar(
          'Failed to Upload File. Try again',
          SnackBarConfig('e'),
        );
        setIsLoading(false);
        return;
      } else if (newDriver) {
        enqueueSnackbar('File uploaded', SnackBarConfig('s'));
        setIsLoading(false);
        if (payload && payload.fieldsChanged) delete payload.fieldsChanged;
        onClose({ payload });
        return;
      }
    }
    (async (doc) => {
      const kind_: string = dot_form_slugs.includes(variant) ? 'dot' : 'tpts';
      const variant_: string = variant;
      const todoId_: string = todoId;
      const url = `${kind_}/${variant_}/${todoId_}`;
      const { success } = await todoStore.submitTodo(url, {
        ...(doc ? { document: doc[0] } : {}),
        ...payload,
      });
      setIsLoading(false);
      if (success) {
        enqueueSnackbar(
          'Request submitted successfully',
          SnackBarConfig('success'),
        );
        onClose({});
        return;
      }
      enqueueSnackbar(
        'Failed to submit request. Try again',
        SnackBarConfig('e'),
      );
    })(document);
  };

  return (
    <DialogMigrate
      aria-labelledby="equipment-select"
      className=""
      maxWidth="xs"
      open={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={() => {}}>
      {(title || preDefinedTitle) && (
        <DialogTitle>
          <Typography variant="h4">
            <b>{title || preDefinedTitle}</b>
          </Typography>
        </DialogTitle>
      )}
      <DialogContent>
        <>
          {typeof renderHeader === 'function' && renderHeader()}
          <br />
          {isLoading && (
            <div className={styles.overlay}>
              <CircularProgress size={30} sx={{ color: '#DEC330' }} />
            </div>
          )}
          <>
            <div>
              {formFields_.map((field, index) => (
                <TodoFC
                  formValue={allValues}
                  onValueChange={handleFormControlChange}
                  key={index}
                  field={field}
                  control={control}
                  variant={variant}
                />
              ))}
            </div>
            {hasFileUpload && (
              <>
                <UploadFileBox
                  onRemove={() => {
                    setAttachment(null);
                  }}
                  title={preDefinedTitle}
                  onUpload={handleFileUpload}
                />
                {error && <Error paragraph> {error} </Error>}
              </>
            )}
          </>
        </>
      </DialogContent>
      <DialogActions>
        <Footer
          disabled={isLoading}
          disableLoader={true}
          onSubmit={() => {
            handleSubmit(doSubmit)();
          }}
          onCancel={() => onClose({})}
        />
      </DialogActions>
    </DialogMigrate>
  );
};

export default JustForm;
