import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Worker } from '@react-pdf-viewer/core';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useCustomStore } from '../../../hooks';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import ComingSoon from '../ComingSoon';
import DocumentsFilter from './documentsFilter';
import DocumentsList from './documentsLists';
import useStyles from './documentsStyle';

type DocumentsProps = {
  headingName?: string;
  backButton?: boolean | any;
  backUrl?: string;
  type?: string;
};
type FiltersTypes = { tags: Array<string>; fileName: string };
const Documents: React.FC<DocumentsProps> = (props: DocumentsProps) => {
  const { backUrl = '/', backButton = false, type: routeType = '' } = props;
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const { documentsStore } = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const useNavigator = useNavigate();

  const [show] = React.useState<boolean>(false);
  const [getMoreLoader, setGetMoreLoader] = React.useState<boolean>(false);
  const [filters, setFilters] = React.useState<FiltersTypes>({
    fileName: '',
    tags: [],
  });

  const BackToPage = () => {
    useNavigator(backUrl);
  };
  const setAnchorEl = (ele: any) => {
    if (
      ele === 'reset' &&
      (documentsStore.Filters.fileName || documentsStore.Filters.tags.length)
    ) {
      setFilters((pre) => ({
        ...pre,
        fileName: '',
        tags: [],
      }));
      documentsStore.resetFilters();
      getDocumentList();
    } else if (ele === 'apply' && (filters.fileName || filters.tags.length)) {
      documentsStore.setFilters({
        ...documentsStore.Filters,
        ...filters,
        nextLink: '',
      });
      getDocumentList();
    }
    documentsStore.setAnchorEl(null);
    documentsStore.setFilterShowOrHide(false);
  };

  const getDocumentListInitial = useCallback(async () => {
    documentsStore.resetDocumentsList();
    documentsStore.setFilters({
      ...documentsStore.Filters,
      sortBy: 'fileName',
      sortDirection: 'asc',
    });
    documentsStore.resetFilters();
    const documentResp = await documentsStore.getDocuments({
      docType: documentsStore.ApiCallType.docType,
      id: documentsStore.ApiCallType.id,
    });
    if (documentResp.isErr()) {
      enqueueSnackbar(String(documentResp.error.message), SnackBarConfig('e'));
      documentsStore.setDocumentReponse([]);
    }
  }, [documentsStore, enqueueSnackbar]);
  const getDocumentList = useCallback(async () => {
    documentsStore.resetDocumentsList();
    const documentResp = await documentsStore.getDocuments({
      docType: documentsStore.ApiCallType.docType,
      id: documentsStore.ApiCallType.id,
    });
    if (documentResp.isErr()) {
      enqueueSnackbar(String(documentResp.error.message), SnackBarConfig('e'));
      documentsStore.setDocumentReponse([]);
    }
  }, [documentsStore, enqueueSnackbar]);
  const getMoreList = useCallback(async () => {
    setGetMoreLoader(true);
    const documentResp = await documentsStore.getDocuments({
      docType: documentsStore.ApiCallType.docType,
      id: documentsStore.ApiCallType.id,
    });
    setGetMoreLoader(false);
    if (documentResp.isErr()) {
      enqueueSnackbar(String(documentResp.error.message), SnackBarConfig('e'));
    }
  }, [documentsStore, enqueueSnackbar]);

  const sortBY = (e: string) => {
    documentsStore.setFilters({
      ...documentsStore.Filters,
      ...filters,
      nextLink: '',
      sortBy: e,
      sortDirection:
        documentsStore.Filters.sortDirection === 'desc' ? 'asc' : 'desc',
    });
    getDocumentList();
  };
  const removeFilter = (key: string, value: string) => {
    const arr = [...documentsStore.Filters.tags];
    if (key === 'tags') {
      arr.splice(Number(value), 1);
    }
    documentsStore.setFilters({
      ...documentsStore.Filters,
      ...{ [key]: key === 'tags' ? arr : value },
      ...{ nextLink: '' },
    });
    getDocumentList();
  };
  useEffect(() => {
    documentsStore.setApiCallType({
      docType: routeType,
      id: id ? id : '',
    });
    getDocumentListInitial();
  }, [documentsStore, getDocumentListInitial, id, routeType]);

  useEffect(() => {
    documentsStore.setHeaderName(searchParams && searchParams.get('name'));
  }, [documentsStore, searchParams]);

  if (show) {
    return <ComingSoon />;
  }
  return (
    <Grid container>
      <Grid item md={12}>
        <Typography component={'div'} className={classes.filterList}>
          {documentsStore.Filters.tags && documentsStore.Filters.tags.length
            ? documentsStore.Filters.tags.map((val: any, index: number) => (
                <Typography
                  component={'div'}
                  className={classes.filterItem}
                  key={index}
                  onClick={() => removeFilter('tags', String(index))}>
                  <Typography component={'div'} className={classes.filterText}>
                    {val}
                  </Typography>
                  <Typography className={classes.close} component={'span'}>
                    X
                  </Typography>
                </Typography>
              ))
            : ''}
          {documentsStore.Filters.fileName ? (
            <Typography
              component={'div'}
              className={classes.filterItem}
              onClick={() => removeFilter('fileName', '')}>
              <Typography component={'div'} className={classes.filterText}>
                {documentsStore.Filters.fileName}
              </Typography>
              <Typography className={classes.close} component={'span'}>
                X
              </Typography>
            </Typography>
          ) : (
            ''
          )}
        </Typography>
        <Typography
          sx={{
            alignItems: 'center',
            display: 'flex',
            letterSpacing: 0,
            lineHeight: '19px',
            padding: '0rem 2rem 0.5rem 2rem',
          }}>
          {backButton ? (
            <div
              style={{
                alignItems: 'center',
                cursor: 'pointer',
                display: 'flex',
                paddingRight: '8px',
              }}
              onClick={BackToPage}>
              <ArrowBackIcon fontSize={'small'} />
              <Typography
                sx={{
                  color: '#241A2E',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '14px',
                }}>
                {'Back'}
              </Typography>
            </div>
          ) : (
            ''
          )}
          <Typography
            sx={{
              color: '#241A2E',
              fontSize: '16px',
              textTransform: 'capitalize',
            }}>
            {documentsStore.DocumentsTotalCount} Documents
          </Typography>
        </Typography>
        <Typography component={'div'} style={{ padding: '0 2rem' }}>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
            <DocumentsList
              {...{
                HasMoreResults: documentsStore.HasMoreResults,
                enqueueSnackbar,
                getMoreList,
                getMoreLoader,
                records: documentsStore.DocumentsList
                  ? documentsStore.DocumentsList
                  : null,
                sortBY,
                storeFilter: documentsStore.Filters,
              }}
            />
          </Worker>
        </Typography>
      </Grid>
      <DocumentsFilter
        {...{
          anchorEl: documentsStore.AnchorEl,
          filters,
          open: documentsStore.ShowFilter,
          setAnchorEl,
          setFilters,
          storeFilter: documentsStore.Filters,
        }}
      />
    </Grid>
  );
};

export default observer(Documents);
