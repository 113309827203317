import { makeAutoObservable } from 'mobx';

import { ApiRoutes } from '../../config/ApiRoutes';
import { RootStore } from '..';

type Response<T> = {
  value: T;
};

type DriverSearchResults = {
  count?: number;
  nextLink?: string;
  response: any[];
};

type PrimaryDriversAndData = {
  primaryDrivers?: PrimaryDriver[];
  driver?: Driver;
  unit?: Unit;
  permit?: Permit;
};

type SubmitRequestResponse = {
  value: string;
};
export class DriverListApiStore {
  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  getDriverList = async (searchString: string, nextLink: string) => {
    const res = {
      count: 0,
      nextLink: '',
      value: [],
    };
    const {
      FindDriversBySearchParams: { Endpoint = '', Method },
    } = ApiRoutes.Driver;
    const response = (await this.rootStore.apiStore.call({
      method: Method,
      params: {
        ...((nextLink && { nextLink }) || {}),
      },
      url: `${Endpoint}${searchString}`,
    })) as Response<DriverSearchResults>;
    return (response.value && (response.value || res)) || res;
  };

  uploadFile = async (data: any, mailNotification?: boolean) => {
    const {
      Upload: { Endpoint, Method },
    } = ApiRoutes.File;
    let response;
    try {
      response = (await this.rootStore.apiStore.call({
        data,
        method: Method,
        params: {
          mailNotification: !!mailNotification,
        },
        url: Endpoint,
      })) as { error?: any; value: any[] };
      if (response.error) {
        return { error: response.error.message, value: [] };
      }
      return response;
    } catch (error) {
      return { error, value: [] };
    }
  };

  submitRequest = async (data: any, pageId: number) => {
    const { MVR } = ApiRoutes.DOT.Submit;

    const apiObj: { [key: number]: any } = {
      0: MVR,
    };

    try {
      const { Method, Endpoint } = apiObj[pageId];
      const response = (await this.rootStore.apiStore.call({
        data,
        method: Method,
        url: Endpoint,
      })) as SubmitRequestResponse;
      return response.value === 'Request Submitted Successfully!'
        ? true
        : false;
    } catch (err) {
      return err ? false : true;
    }
  };

  getDriverByContactId = async (contactId: string) => {
    const {
      GetDetailsByContactId: { Endpoint = '', Method },
    } = ApiRoutes.Driver;

    const response: any = await this.rootStore.apiStore.call({
      method: Method,
      url: `${Endpoint}${contactId}`,
    });
    return response.value;
  };

  getEquipmentByContactId = async (contactId: string) => {
    try {
      const {
        GetByDriverContactId: { Endpoint = '', Method },
      } = ApiRoutes.Equipment;

      const response: any = await this.rootStore.apiStore.call({
        method: Method,
        url: `${Endpoint}${contactId}`,
      });
      return response.value;
    } catch (error) {
      return error ? { error, response: [] } : { response: [] };
    }
  };

  terminateDriver = async (data: TerminateDriverPayload) => {
    try {
      const {
        Submit: {
          Terminate_Driver: { Endpoint, Method },
        },
      } = ApiRoutes.DOT;

      const response: any = (await this.rootStore.apiStore.call({
        data,
        method: Method,
        url: Endpoint,
      })) as SubmitRequestResponse;
      return response.value === 'Request Submitted Successfully!'
        ? true
        : false;
    } catch (err) {
      return err ? false : true;
    }
  };

  getTerminals = async () => {
    try {
      const {
        Terminals: { Endpoint = '', Method },
      } = ApiRoutes.DOT;

      const response: any = await this.rootStore.apiStore.call({
        method: Method,
        url: Endpoint,
      });
      response;
      return response.value as Terminal[];
    } catch (error) {
      return error ? [] : [];
    }
  };

  getPrimaryDriversAndData = async (params: {
    [key: string]: string;
  }): Promise<PrimaryDriversAndData> => {
    const {
      GetPrimaryDrivers: { Endpoint: url = '', Method },
    } = ApiRoutes.Todo;
    let absoluteRes: PrimaryDriversAndData;
    try {
      const response = (await this.rootStore.apiStore.call({
        method: Method,
        params: {
          ...params,
        },
        url,
      })) as Response<{ primaryDrivers: PrimaryDriversAndData }>;
      absoluteRes = ((response && response.value && { ...response.value }) || {
        primaryDrivers: [],
      }) as PrimaryDriversAndData;
    } catch (error) {
      absoluteRes = error
        ? { primaryDrivers: [] }
        : ({ primaryDrivers: [] } as PrimaryDriversAndData);
    }
    return absoluteRes;
  };
}
