import moment from 'moment';
import * as yup from 'yup';

import { RequestType } from '../types';
import { licenseTypesWithNoEndorsements } from './config';
import { deformatPhoneNumber } from './helper';

const MAX_DATE = moment().subtract(18, 'years');

export const loginCompanySchema = yup.object().shape({
  contactId: yup.string().required('Required'),
  password: yup.string().required('Required'),
});

export const loginEmailSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email should have correct format')
    .required('Required'),
});

export const TerminateEquipmentSchema = yup.object().shape({
  equipments: yup
    .array()
    .of(
      yup.object().shape({
        dotFtBundle: yup.mixed().nullable().optional(),
        equipmentType: yup.string().nullable().optional(),
        filePeriodYear: yup.string().nullable().optional(),
        grossVehicleWeight: yup.string().nullable().optional(),
        simplexId: yup.string().nullable().optional(),
        status: yup.string().nullable().optional(),
        terminateStatus: yup
          .string()
          .nullable()
          .when('equipmentType', {
            is: 'trailer',
            otherwise: (schema) => schema.required('Required'),
            then: (schema) => schema.nullable().optional(),
          }),
        terminationReason: yup
          .string()
          .nullable()
          .required('Please select a Reason for Termination'),
        unitId: yup.string().nullable().optional(),
        unitNumber: yup.string().nullable().optional(),
        unitTerminationDate: yup.string().nullable().required('Required'),
        unitType: yup.string().nullable().optional(),
        vinNumber: yup.string().nullable().optional(),
      }),
    )
    .min(1, 'Minimum one equipment must be required'),
});

export const forgotPasswordSchema = yup.object().shape({
  contactId: yup.string().required('Required'),
  email: yup
    .string()
    .email('Email should have correct format')
    .required('Required'),
});

export const verifyOtpSchema = yup.object().shape({
  otp: yup
    .number()
    .required('Required') // optional
    .typeError('Required') // optional as well
    .test(
      'len',
      'Otp needs to be excatly 6 digits',
      (val) => val!.toString().length === 6,
    ),
});

export const ResetPasswordSchema = yup.object().shape({
  confirmPassword: yup
    .string()
    .when('password', {
      is: (p: string) => !!p,
      then: yup.string().oneOf([yup.ref('password')], 'Passwords must match'),
    })
    .required('Required'),
  password: yup.string().required('Required'),
});

export const SignUpSchema = yup.object().shape({
  confirmPassword: yup
    .string()
    .when('password', {
      is: (p: string) => !!p,
      then: yup.string().oneOf([yup.ref('password')], 'Passwords must match'),
    })
    .required('Required'),
  email: yup
    .string()
    .email('Email should have correct format')
    .required('Required'),
  firstName: yup
    .string()
    .required('Required')
    .matches(/^[A-Za-z ]*$/, 'Please enter valid first name')
    .max(40),
  lastName: yup
    .string()
    .required('Required')
    .matches(/^[A-Za-z ]*$/, 'Please enter valid last name')
    .max(40),
  password: yup.string().required('Required'),
});

export const UcrFormSchema = yup.object().shape({
  permitYear: yup.string().required('Required'),
  type: yup.string().required('Required'),
});

export const FTQuarterFillingSchema = yup.object().shape({
  quarter: yup.string().required('Required'),
  year: yup.string().required('Required'),
});

export const RoadTaxNewFormSchema = yup.object().shape({
  requestType: yup.string().required('Required'),
  years: yup.array().of(
    yup.object().shape({
      filePeriodYear: yup.string().nullable().required('Required'),
    }),
  ),
});

export const AnnualReportFormSchema = yup.object().shape({
  mailingAddress: yup.string().required('Required'),
  mailingCity: yup
    .string()
    .matches(/^[a-zA-Z ]+$/, 'Enter valid City Name')
    .required('Required'),
  mailingState: yup.string().required('Required'),
  mailingZip: yup
    .string()
    .required('Required')
    .matches(/^\d*$/gim, 'Enter valid zip code'),
  physicalAddress: yup.string().required('Required'),
  physicalCity: yup
    .string()
    .matches(/^[a-zA-Z ]+$/, 'Enter valid City Name')
    .required('Required'),
  physicalState: yup.string().required('Required'),
  physicalZip: yup
    .string()
    .required('Required')
    .matches(/^\d*$/gim, 'Enter valid zip code'),
});

export const NewDriverSetupSchema = yup.object().shape({
  address: yup.string().required('Required').nullable(),
  applicationDate: yup
    .string()
    .nullable()
    .when('driverApplication', {
      is: (val: any) => val === '140320002',
      then: yup
        .string()
        .required('Required')
        .typeError('Application Date required field'),
    }),
  city: yup
    .string()
    .matches(/^[a-zA-Z ]+$/, 'Enter valid City Name')
    .required('Required')
    .nullable(),
  dahire: yup
    .string()
    .nullable()
    .when('driverApplication', {
      is: (val: any) => val === '140320002',
      then: yup
        .string()
        .required('Required')
        .typeError('Hire Date required field'),
    }),
  dob: yup.string().required('Required').nullable(),
  driverApplication: yup
    .string()
    .nullable()
    .when('isSHSSAccount', {
      is: true,
      then: yup.string().required('Required').nullable(),
    }),
  driverLicenseEndorsements: yup
    .array()
    .of(yup.string())
    .when('licenseType', (licenseType, schema: any) =>
      licenseTypesWithNoEndorsements.includes(licenseType)
        ? schema.min(0)
        : schema.min(1, 'Required'),
    )
    .nullable(),
  driverType: yup.string().required('Required').nullable(),
  email: yup
    .string()
    .email('Enter valid email')
    .required('Required')
    .nullable(),
  firstName: yup
    .string()
    .matches(/^[a-zA-Z ]+$/, 'Enter valid Driver First Name')
    .required('Required')
    .nullable(),
  isSHSSAccount: yup.boolean(),
  lastName: yup
    .string()
    .matches(/^[a-zA-Z ]+$/, 'Enter valid Driver Last Name')
    .required('Required')
    .nullable(),
  licenseExpirationDate: yup.string().required('Required').nullable(),
  licenseNumber: yup
    .string()
    .matches(/^[A-Za-z0-9_.]+$/, "Enter valid Driver's Licence")
    .required('Required')
    .nullable(),
  licenseStateId: yup.string().when('licenseType', {
    is: 'Mexican',
    otherwise: (schema) => schema.required('Required').nullable(),
    then: (schema) => schema.nullable(),
  }),
  licenseType: yup.string().required('Required').nullable(),
  middleName: yup
    .string()
    .matches(/^$|^[a-zA-Z ]+$/, 'Enter valid Driver Middle Name')
    .nullable(),
  phoneNumber: yup
    .string()
    .transform(deformatPhoneNumber)
    .required('Required')
    .test(
      'phoneNumberLenghtByCountry',
      'Enter a valid phone number',
      (val: any) => {
        if (val.length < 11 && val.startsWith('1')) {
          return false;
        } else if (val.length < 12 && val.startsWith('52')) {
          return false;
        } else if (val.length > 12) {
          return false;
        }
        return true;
      },
    )
    .test('', 'Only country codes +1 and +52 are supported.', (value: any) => {
      if (value.startsWith('1') || value.startsWith('52')) {
        return true;
      } else {
        return false;
      }
    })
    .nullable(),
  preBackgroundCheck: yup
    .string()
    .nullable()
    .when('isSHSSAccount', {
      is: true,
      then: yup.string().required('Required').nullable(),
    }),
  preEmploymentCH: yup
    .string()
    .nullable()
    .when('isSHSSAccount', {
      is: true,
      then: yup.string().required('Required').nullable(),
    }),
  preEmploymentDL: yup
    .string()
    .nullable()
    .when('isSHSSAccount', {
      is: true,
      then: yup.string().required('Required').nullable(),
    }),
  preEmploymentDT: yup
    .string()
    .nullable()
    .when('isSHSSAccount', {
      is: true,
      then: yup.string().required('Required').nullable(),
    }),
  preEmploymentMC: yup
    .string()
    .nullable()
    .when('isSHSSAccount', {
      is: true,
      then: yup.string().required('Required').nullable(),
    }),
  preEmploymentMVR: yup
    .string()
    .nullable()
    .when('isSHSSAccount', {
      is: true,
      then: yup.string().required('Required').nullable(),
    }),
  preEmploymentPSP: yup
    .string()
    .nullable()
    .when('isSHSSAccount', {
      is: true,
      then: yup.string().required('Required').nullable(),
    }),
  ssn: yup
    .string()
    .required('Required')
    .matches(/[0-9]{3}-[0-9]{2}-[0-9]{4}/gim, 'Enter valid ssn')
    .nullable(),
  state: yup.string().required('Required').nullable(),
  terminalId: yup.string().nullable(),
  zip: yup
    .string()
    .nullable()
    .min(5, 'Enter minimum 5 number')
    .max(5, 'Please enter maximum 5 number')
    .matches(/^[0-9]*$/, 'Enter valid Zip number')
    .required('Required'),
});

export const UpdateDriverSetupSchema = yup.object().shape({
  address: yup.string().required('Required').nullable(),
  city: yup
    .string()
    .required('Required')
    .matches(/^[a-zA-Z ]+$/, 'Enter valid City Name')
    .nullable(),
  dob: yup
    .date()
    .default(new Date(moment(MAX_DATE).format('YYYY-MM-DD')))
    .max(
      moment(MAX_DATE).format('YYYY-MM-DD'),
      'DOB should be greater than 18 years',
    )
    .required('Required')
    .nullable(),
  driverType: yup.string().required('Required').nullable(),
  email: yup
    .string()
    .email('Enter valid email')
    .required('Required')
    .nullable(),
  firstName: yup
    .string()
    .required('Required')
    .matches(/^[a-zA-Z ]+$/, 'Enter valid First Name')
    .nullable(),
  lastName: yup
    .string()
    .required('Required')
    .matches(/^[a-zA-Z ]+$/, 'Enter valid Last Name')
    .nullable(),
  licenseEndorsements: yup
    .array()
    .of(yup.string())
    .when('licenseType', (licenseType, schema) =>
      licenseTypesWithNoEndorsements.includes(licenseType)
        ? schema.min(0)
        : schema.min(1, 'Required'),
    )
    .nullable(),
  licenseExpirationDate: yup.string().required('Required').nullable(),
  licenseNumber: yup
    .string()
    .matches(/^[A-Za-z0-9_.]+$/, "Enter valid Driver's Licence")
    .required('Required')
    .nullable(),
  licenseState: yup
    .string()
    .when('licenseType', {
      is: (val1: string) => {
        return val1 === 'Mexican';
      },
      then: yup
        .string()
        .required('Required')
        .matches(/MEX/, 'State should be Mexico as per selected License Type'),
    })
    .when('licenseType', {
      is: (val1: string) => {
        return val1 !== 'Mexican';
      },
      then: yup
        .string()
        .required('Required')
        .not(
          ['MEX'],
          'State should not be Mexico as per selected license Type',
        ),
    })
    .required('Required')
    .nullable(),
  licenseType: yup.string().required('Required').nullable(),
  middleName: yup
    .string()
    .matches(/^$|^[a-zA-Z ]+$/, 'Enter valid Driver Middle Name')
    .nullable(),
  phoneNumber: yup
    .string()
    .required('Required')
    .transform(deformatPhoneNumber)
    .test(
      'phoneNumberLenghtByCountry',
      'Enter a valid phone number',
      (val: any) => {
        if (val.length < 11 && val.startsWith('1')) {
          return false;
        } else if (val.length < 12 && val.startsWith('52')) {
          return false;
        } else if (val.length > 12) {
          return false;
        }
        return true;
      },
    )
    .test('', 'Only country codes +1 and +52 are supported.', (value: any) => {
      if (value.startsWith('1') || value.startsWith('52')) {
        return true;
      } else {
        return false;
      }
    })
    .nullable(),
  phoneNumberLenghtByCountry: yup.string(),
  ssn: yup
    .string()
    .required('Required')
    .matches(/[0-9]{3}-[0-9]{2}-[0-9]{4}/gim, 'Enter valid ssn')
    .nullable(),
  state: yup.string().required('Required').nullable(),
  terminalId: yup.string().nullable(),
  zip: yup
    .string()
    .nullable()
    .min(5, 'Enter minimum 5 number')
    .max(5, 'Elease enter maximum 5 number')
    .matches(/^[0-9]*$/, 'Enter valid Zip number')
    .required('Required'),
});

export const MedicalCardFormSchema = yup.object().shape({
  expirationDate: yup.string().required('Required').nullable(),
  issueDate: yup.string().required('Required').nullable(),
  medicalExaminerName: yup.string().required('Required').nullable(),
  nationalRegistry: yup.string().required('Required').nullable(),
});

export const UpdateMedicalCardFormSchema = yup.object().shape({
  medicalCardExpiryDate: yup.string().required('Required').nullable(),
  medicalCardIssueDate: yup.string().required('Required').nullable(),
  medicalExaminerName: yup.string().required('Required').nullable(),
  nationalRegistryNumber: yup.string().required('Required').nullable(),
});

export const AddToFleetFormSchema = yup.object().shape({
  dotFtBundleStatus: yup.bool().optional(),
  equipmentType: yup.string().required('Required'),
  grossVehicleWeight: yup
    .string()
    .matches(/^$|^[0-9]*$/gim, 'Enter valid Gross vehicle weight')
    .when('equipmentType', {
      is: (val: any) => val !== 'trailer',
      then: yup.string().required('Required'),
    }),
  isPermanantRegistration: yup.boolean().optional(),
  isSHSS: yup.bool().optional(),
  isSimplexCompletingFuelTaxes: yup
    .string()
    .when(['dotFtBundleStatus', 'equipmentType'], {
      is: (val: any, eq: any) => val && eq !== 'trailer',
      then: yup.string().required('Required'),
    }),
  lastAnnualInspectionDate: yup
    .string()
    .nullable()
    .when('isSHSS', {
      is: (val: boolean) => !val,
      then: yup.string(),
    }),
  licensePlateNumber: yup
    .string()
    .matches(/^[0-9a-zA-z]*$/gim, 'Enter valid license plate number')
    .when('isSHSS', {
      is: (val: boolean) => !val,
      then: yup.string().required('Required'),
    }),
  licensePlateState: yup.string().required('Required'),
  ownershipType: yup.string().required('Required'),
  primaryDriverContactId: yup.string().when('ownershipType', {
    is: (val: any) => val !== 'Client/Company Owned',
    otherwise: yup.string().optional().nullable(),
    then: yup
      .string()
      .required('Required')
      .nullable()
      .notOneOf(
        ['None'],
        'None is not a valid primary driver for Owner Operator',
      ),
  }),
  registration: yup.array().optional(),
  registrationExpDate: yup.string().nullable().when('isPermanantRegistration', {
    is: false,
    then: yup.string().nullable(),
  }),
  registrationType: yup.string().when('equipmentType', {
    is: (val: any) => val !== 'trailer',
    then: yup.string().required('Required'),
  }),
  trailerType: yup.string().nullable(),
  unitNumber: yup
    .string()

    .when('isSHSS', {
      is: (val: boolean) => !val,
      then: yup
        .string()
        .matches(/^\w+$/, 'Enter valid unit number')
        .required('Required'),
    }),
  unitType: yup.string().required('Required'),
  vehicleMake: yup
    .string()
    .matches(/^[0-9a-zA-z -]*$/gim, 'Enter valid vehicle make')
    .when('isSHSS', {
      is: (val: boolean) => !val,
      then: yup.string().required('Required'),
    }),
  vehicleYear: yup.string().nullable().required('Required'),
  vinNumber: yup
    .string()
    .min(8, 'Enter minimum 8 character')
    .max(17, 'Enter maximum 17 character')
    .matches(/^[0-9a-zA-z]*$/gim, 'Enter valid Vin')
    .required('Required'),
  vqFile: yup.array().optional(),
});

export const UpdateFleetFormSchema = yup.object().shape({
  dotFtBundleStatus: yup.bool().optional(),
  equipmentType: yup.string().required('Required'),
  grossVehicleWeight: yup
    .string()
    .matches(/^$|^[0-9]*$/gim, 'Enter valid Gross vehicle weight')
    .when('equipmentType', {
      is: (val: any) => val !== 'trailer',
      then: yup.string().required('Required'),
    }),
  isPermanantRegistration: yup.boolean().optional(),

  isSHSS: yup.bool().optional(),
  isSimplexCompletingFuelTaxes: yup
    .string()
    .when(['dotFtBundleStatus', 'equipmentType'], {
      is: (val: any, eq: any) => val && eq !== 'trailer',
      then: yup.string().required('Required'),
    }),
  lastAnnualInspectionDate: yup
    .string()
    .nullable()
    .when('isSHSS', {
      is: (val: boolean) => !val,
      then: yup.string().required('Required'),
    }),
  licensePlateNumber: yup
    .string()
    .nullable()
    .matches(/^[0-9a-zA-z]*$/gim, 'Enter valid license plate number')
    .when('isSHSS', {
      is: (val: boolean) => !val,
      then: yup
        .string()
        .required('Required')
        .matches(/^[0-9a-zA-z]*$/gim, 'Enter valid license plate number')
        .typeError('Enter valid license plate number'),
    }),
  licensePlateState: yup.string().required('Required').nullable(),
  ownershipType: yup.string().required('Required'),
  // primaryDriverContactId: yup.string().required('Required').nullable(),
  primaryDriverContactId: yup
    .string()
    .nullable()
    .when('ownershipType', {
      is: (val: any) => val !== 'Client/Company Owned',
      otherwise: yup.string().optional().nullable(),
      then: yup
        .string()
        .required('Required')
        .nullable()
        .notOneOf(
          ['None'],
          'None is not a valid primary driver for Owner Operator',
        ),
    }),
  registrationExpDate: yup
    .string()
    .nullable()
    .when('isPermanantRegistration', {
      is: false,
      then: yup.string().nullable().required('Required'),
    }),
  // registrationType: yup.string().required('Required'),
  registrationType: yup.string().when('equipmentType', {
    is: (val: any) => val !== 'trailer',
    then: yup.string().required('Required'),
  }),
  trailerType: yup.string().nullable(),
  unitNumber: yup
    .string()
    .nullable()
    .matches(/^\w+$/, 'Enter valid unit number')
    .when('isSHSS', {
      is: (val: boolean) => !val,
      then: yup
        .string()
        .required('Required')
        .matches(/^\w+$/, 'Enter valid unit number')
        .typeError('Enter valid unit number'),
    }),
  unitType: yup.string().nullable().required('Required'),
  vehicleMake: yup
    .string()
    .matches(/^[0-9a-zA-z -]*$/gim, 'Enter valid vehicle make')
    .nullable()
    .when('isSHSS', {
      is: (val: boolean) => !val,
      then: yup.string().nullable().required('Required'),
    }),
  vehicleYear: yup.string().nullable().required('Required'),
  vinNumber: yup
    .string()
    .min(8, 'Enter minimum 8 character')
    .max(17, 'Enter maximum 17 character')
    .matches(/^[0-9a-zA-z]*$/gim, 'Enter valid Vin')
    .required('Required'),
});

export const IFTAFormSchema = yup.object().shape({
  ifta: yup
    .string()
    .nullable()
    .matches(/^[0-9.-]*$/gim, 'Enter valid IFTA Account'),
  iftaState: yup.string().when('type', {
    is: (value: any) => value === 'new',
    then: yup.string().required('Required'),
  }),
  type: yup.string().required('Required'),
});

export const StatePermitsFormSchema = yup.object().shape({
  dotNumber: yup
    .string()
    .nullable()
    .matches(/^[a-zA-z0-9\s]*$/, 'Enter valid DOT')
    .required('Required'),
  mailingAddress: yup
    .string()
    .nullable()
    .when('requestType', {
      is: (val: string) => val === RequestType.New,
      then: yup.string().nullable().required('Required'),
    }),
  mailingCity: yup
    .string()
    .nullable()
    .when('requestType', {
      is: (val: string) => val === RequestType.New,
      then: yup.string().nullable().required('Required'),
    }),
  mailingState: yup
    .string()
    .nullable()
    .when('requestType', {
      is: (val: string) => val === RequestType.New,
      then: yup.string().nullable().required('Required'),
    }),
  mailingZip: yup
    .string()
    .nullable()
    .when('requestType', {
      is: (val: string) => val === RequestType.New,
      then: yup
        .string()
        .nullable()
        .min(5, 'Enter minimum 5 number')
        .max(5, 'Elease enter maximum 5 number')
        .matches(/^[0-9]*$/, 'Enter valid Zip number')
        .required('Required'),
    }),

  // mailingAddress: yup.string().nullable().required('Required'),
  // mailingCity: yup.string().nullable().required('Required'),
  // mailingState: yup.string().nullable().required('Required'),
  // mailingZip: yup
  //   .string()
  //   .nullable()
  //   .min(5, 'Enter minimum 5 number')
  //   .max(5, 'Elease enter maximum 5 number')
  //   .matches(/^[0-9]*$/, 'Enter valid Zip number')
  //   .required('Required'),
  requestType: yup.string().required('Required'),
});

export const StatePermitsKentuckyFormSchema = yup.object().shape({
  dotNumber: yup.string().matches(/^[a-zA-z0-9\s]*$/, 'Enter valid DOT'),
  hasUcrFiled: yup.string().when('ucrExpDate', {
    is: (val: string) =>
      val === val && moment().isAfter(moment(val).add(1, 'day')),
    then: yup.string().required('Required'),
  }),
  requestType: yup.string().required('Required'),
  ucrExpDate: yup.string().nullable(),
});

export const StatePermitsOregonFormSchema = yup.object().shape({
  dateOfEntry: yup
    .string()
    .nullable()
    .when('requestType', {
      is: (val: string) => val === RequestType.Temporary,
      then: yup.string().nullable().required('Required'),
    }),
  dateOfExit: yup
    .string()
    .nullable()
    .when('requestType', {
      is: (val: string) => val === RequestType.Temporary,
      then: yup.string().nullable().required('Required'),
    }),
  deliveryAddress: yup.string().when('requestType', {
    is: (val: string) => val === RequestType.Temporary,
    then: yup.string().required('Required'),
  }),
  deliveryCity: yup.string().when('requestType', {
    is: (val: string) => val === RequestType.Temporary,
    then: yup
      .string()
      .required('Required')
      .matches(/^[a-zA-Z ]+$/, 'Enter valid City Name'),
  }),
  deliveryState: yup.string().when('requestType', {
    is: (val: string) => val === RequestType.Temporary,
    then: yup.string().required('Required'),
  }),
  deliveryZip: yup.string().when('requestType', {
    is: (val: string) => val === RequestType.Temporary,
    then: yup
      .string()
      .nullable()
      .min(5, 'Enter minimum 5 number')
      .max(5, 'Enter maximum 5 number')
      .matches(/^[0-9]*$/, 'Enter valid Zip number')
      .required('Required'),
  }),
  dotNumber: yup.string().when('requestType', {
    is: (val: string) => val === RequestType.Temporary,
    then: yup
      .string()
      .matches(/^[a-zA-z0-9\s]*$/, 'Enter valid DOT')
      .required('Required'),
  }),
  loadDescription: yup.string().when('requestType', {
    is: (val: string) => val === RequestType.Temporary,
    then: yup.string().required('Required'),
  }),
  milesTraveled: yup
    .number()
    .nullable()
    .when('requestType', {
      is: (val: string) => val === RequestType.Temporary,
      then: yup.number().nullable().required('Required'),
    }),
  pointOfEntry: yup.string().when('requestType', {
    is: (val: string) => val === RequestType.Temporary,
    then: yup.string().required('Required'),
  }),
  pointOfExit: yup.string().when('requestType', {
    is: (val: string) => val === RequestType.Temporary,
    then: yup.string().required('Required'),
  }),
  requestType: yup.string().required('Required'),
  routeEntry: yup.string().when('requestType', {
    is: (val: string) => val === RequestType.Temporary,
    then: yup.string().required('Required'),
  }),
  routeExit: yup.string().when('requestType', {
    is: (val: string) => val === RequestType.Temporary,
    then: yup.string().required('Required'),
  }),
  units: yup.array().when('requestType', {
    is: (val: string) => val === RequestType.Temporary,
    then: yup.array().of(
      yup.object().shape({
        odometer: yup
          .number()
          .nullable()
          .when('equipmentType', {
            is: (val: string) => val === 'power unit',
            then: yup.number().nullable().required('Required'),
          }),
      }),
    ),
  }),
});

export const IRPServiceNewFormSchema = yup.object().shape({
  irpAccountNumber: yup
    .string()
    .matches(/^[a-zA-z0-9\-s]*$/, 'Enter valid IRP Account')
    .when('requestType', {
      is: (value: any) => value !== 'new',
      then: yup.string().required('Required'),
    }),
  irpState: yup.string().when('requestType', {
    is: (value: any) => value === 'new',
    then: yup.string().required('Required'),
  }),
  requestType: yup.string().required('Required'),
  units: yup.array(),
});

export const DotUpdateFormSchema = yup.object().shape({
  biennial: yup.boolean().optional(),
  dot: yup.string().optional(),
  dotPin: yup
    .string()
    .matches(/^[0-9a-zA-Z]*$/gim, 'Enter valid DOT PIN')
    .required('Required'),
  email: yup.string().email('Enter valid email').required('Required'),
  estimatedMiles: yup.string().when('vmt', {
    is: true,
    then: yup
      .string()
      .matches(/^[0-9]*$/gim, 'Enter valid miles')
      .required('Required'),
  }),
  fein: yup
    .string()
    .matches(/^[0-9a-zA-Z]*$/gim, 'Enter valid fein')
    .required('Required'),
  mailingAddress: yup.string().required('Required'),
  mailingCity: yup
    .string()
    .required('Required')
    .matches(/^[a-zA-Z ]+$/, 'Enter valid City Name'),
  mailingState: yup.string().required('Required'),
  mailingZip: yup
    .string()
    .required('Required')
    .matches(/^\d*$/gim, 'Zip code must be number'),
  name: yup.string().required('Required'),
  phoneNumber: yup
    .string()
    .required('Required')
    .transform(deformatPhoneNumber)
    .test(
      'phoneNumberLenghtByCountry',
      'Enter a valid phone number',
      (val: any) => {
        if (val.length < 11 && val.startsWith('1')) {
          return false;
        } else if (val.length < 12 && val.startsWith('52')) {
          return false;
        } else if (val.length > 12) {
          return false;
        }
        return true;
      },
    )
    .test('', 'Only country codes +1 and +52 are supported.', (value: any) => {
      if (value.startsWith('1') || value.startsWith('52')) {
        return true;
      } else {
        return false;
      }
    }),
  physicalAddress: yup.string().required('Required'),
  physicalCity: yup
    .string()
    .required('Required')
    .matches(/^[a-zA-Z ]+$/, 'Enter valid City Name'),
  physicalState: yup.string().required('Required'),
  physicalZip: yup
    .string()
    .required('Required')
    .matches(/^\d*$/gim, 'Zip code must be number'),
  sameAs: yup.string().optional(),
  ssn: yup
    .string()
    .required('Required')
    .matches(/[0-9]{3}-[0-9]{2}-[0-9]{4}/gim, 'Enter valid owner ssn'),
  vmt: yup.boolean().optional(),
});

export const EquipmentSearchSchema = yup.object().shape({
  simplexId: yup.string().optional(),
  unitNumber: yup.string().optional(),
  vinNumber: yup.string().optional(),
});

export const CreatePasswordSchema = yup.object().shape({
  password: yup.string().required('Required'),
});

export const UpdateUserFormSchema = yup.object().shape({
  address: yup.string().required('Required').nullable(),
  city: yup
    .string()
    .required('Required')
    .matches(/^[a-zA-Z ]+$/, 'Enter valid City Name')
    .nullable(),
  dob: yup.string().required('Required').nullable(),
  email: yup.string().required('Required').nullable(),
  firstName: yup
    .string()
    .matches(/^[a-zA-Z ]+$/, 'Enter valid First Name')
    .required('Required')
    .nullable(),
  lastName: yup
    .string()
    .matches(/^[a-zA-Z ]+$/, 'Enter valid Last Name')
    .required('Required')
    .nullable(),
  phoneNumber: yup
    .string()
    .required('Required')
    .transform(deformatPhoneNumber)
    .test(
      'phoneNumberLenghtByCountry',
      'Enter a valid phone number',
      (val: any) => {
        if (val.length < 11 && val.startsWith('1')) {
          return false;
        } else if (val.length < 12 && val.startsWith('52')) {
          return false;
        } else if (val.length > 12) {
          return false;
        }
        return true;
      },
    )
    .test('', 'Only country codes +1 and +52 are supported.', (value: any) => {
      if (value.startsWith('1') || value.startsWith('52')) {
        return true;
      } else {
        return false;
      }
    })
    .nullable(),
  state: yup.string().required('Required').nullable(),
  zip: yup.string().required('Required').nullable(),
});

export const AdminUsersSearchSchema = yup.object().shape({
  contactType: yup.string().optional(),
  email: yup.string().optional(),
  firstName: yup.string().optional(),
  lastName: yup.string().optional(),
});

export const AdminRoleSchema = yup.object().shape({
  name: yup.string().required('Required'),
});

export const AssignTrainingSchema = yup.object().shape({
  contactId: yup
    .string()
    .required('Required')
    .nullable()
    .notOneOf(['None'], 'None is not a valid assignee for Training'),
  dueDate: yup.string().required('Required').nullable(),
  reasonId: yup.string().required('Required').nullable(),
  trainingId: yup.string().required('Required').nullable(),
});

/** Data Q Challenge form validator schema */
export const DataQChallengeSchema = yup.object().shape({
  challengeReason: yup.string().required('Required'),
  challengeType: yup.string().required('Required'),
  explanation: yup.string().notRequired().nullable(),
  reasonType: yup.string().when('challengeType', {
    is: (value: string) => value !== 'Violation',
    then: yup.string().required('Required'),
  }),
  source: yup.string().required('Required'),
  violationCodes: yup.array().when('challengeType', {
    is: (value: string) => value === 'Violation',
    then: yup.array().min(1, 'Violations required').required('Required'),
  }),
});

export const AutoLiabilityInsuranceTodoSchema = yup.object().shape({
  preferredContact: yup
    .string()
    .when('receiveQuote', {
      is: 'yes',
      then: yup.string().required('Please select an option.'),
    })
    .optional(),
  preferredTime: yup
    .string()
    .when('receiveQuote', {
      is: 'yes',
      then: yup.string().required('Please select an option.'),
    })
    .optional(),
  receiveQuote: yup
    .string()
    .when('renewPlanning', {
      is: 'no',
      then: yup.string().required('Please select an option.'),
    })
    .optional(),
  renewPlanning: yup.string().required('Please select an option.'),
});
