import { makeAutoObservable } from 'mobx';
import moment from 'moment';

import { dateKeysForFormatting } from '../config/Constants';
// import { API_LIMIT } from '../config';
import { RootStore } from '.';

export class DriverListStore {
  // private _limit = 10;
  private _driversList: Array<any> = [];
  private _showLoader = false;
  private _hasMoreResults = true;
  private _nextLink = '';
  private _selectedDriver = {};
  private _equipmentsList: Array<any> = [];
  private _count = 0;

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: true });
  }

  setHasMoreResults(hasMoreResults: boolean) {
    this._hasMoreResults = hasMoreResults;
  }

  setDriversList(data: Array<any>) {
    this._driversList = data;
  }

  setCount(count: number) {
    this._count = count;
  }

  setEquipments(data: Array<any>) {
    this._equipmentsList = data;
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }

  setSelectedDriver(driver: any) {
    this._selectedDriver = driver;
  }

  setNextLink(link: string) {
    this._nextLink = link;
  }

  resetDriversList() {
    this.setNextLink('');
    this.setHasMoreResults(true);
    this.setDriversList([]);
  }

  get Count() {
    return this._count;
  }
  get ShowLoader() {
    return this._showLoader;
  }

  get DriversList() {
    return this._driversList;
  }
  get EquipmentsList() {
    return this._equipmentsList;
  }

  get NextLink() {
    return this._nextLink;
  }

  get HasMoreResults() {
    return this._hasMoreResults;
  }

  get SelectedDriver() {
    return this._selectedDriver;
  }

  searchDrivers = async (searchString: string) => {
    const {
      response: list = [],
      nextLink = '',
      count = 0,
    } = await this.rootStore.apiStore.driverListApiStore.getDriverList(
      searchString,
      this._nextLink,
    );
    const newDriversList = [...this._driversList, ...list];
    this.setNextLink(nextLink || '');
    this.setCount(count);
    this.setDriversList(newDriversList);
    return newDriversList;
  };

  getDriverById = async (id: string) => {
    const driver =
      await this.rootStore.apiStore.driverListApiStore.getDriverByContactId(id);
    this.setSelectedDriver(driver);
    return driver;
  };

  getTerminals = async () =>
    await this.rootStore.apiStore.driverListApiStore.getTerminals();

  getPrimaryDriversAndData = async (params: { [key: string]: string }) => {
    const res =
      await this.rootStore.apiStore.driverListApiStore.getPrimaryDriversAndData(
        params,
      );
    if (res?.driver) {
      const tempDriver = res.driver;
      for (const key of dateKeysForFormatting) {
        const date = tempDriver[key as keyof Driver];
        if (date && date.includes('T')) {
          const newDate = date.split('T')[0];
          tempDriver[key as keyof Driver] =
            moment(newDate).format('MM/DD/YYYY');
        }
      }
      res.driver = tempDriver;
    }
    return res;
  };

  getEquipmentByDriverId = async (id: string) => {
    const equipments =
      await this.rootStore.apiStore.driverListApiStore.getEquipmentByContactId(
        id,
      );
    const { response } = equipments;
    this.setEquipments(response);
    return response;
  };

  terminateDriver = async (payload: TerminateDriverPayload) =>
    await this.rootStore.apiStore.driverListApiStore.terminateDriver(payload);
  uploadFileForSignature = async (file: any, mailNotification?: boolean) =>
    await this.rootStore.apiStore.driverListApiStore.uploadFile(
      file,
      mailNotification,
    );

  submitSignatureRequest = async (data: any, pageId: number) =>
    await this.rootStore.apiStore.driverListApiStore.submitRequest(
      data,
      pageId,
    );
}
