import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useState } from 'react';

import SimplexDatepicker from '../../../../../../components/SimplexDatepicker';

type Props = {
  defaultValue?: string;
  onChange: (date: string) => void;
  title: string;
  minDate?: Date;
};

export const PSPDateForm: React.FC<Props> = ({
  onChange,
  title,
  minDate,
  defaultValue = '',
}) => {
  const [value, setValue] = useState<unknown>(defaultValue);

  return (
    <>
      <br />
      <Typography paragraph>
        {title} <sup>*</sup>
      </Typography>
      <SimplexDatepicker
        value={value}
        maxDate={new Date()}
        {...(minDate ? { minDate } : {})}
        onChange={(e: any) => {
          onChange(moment(e).format('MM/DD/YYYY'));
          setValue(e);
        }}
      />
    </>
  );
};
