import { FieldErrors } from 'react-hook-form';

import {
  AuthErrorType,
  DateErrorType,
  ErrorResponseActions,
  FileErrorType,
  GeneralErrorType,
  ServerErrorType,
  UserErrorType,
} from './backendError.types';

export type themeObj = {
  common: {
    black: string;
    white: string;
  };
  primary: {
    main: string;
    dark: string;
    light: string;
    contrastText: string;
  };
  secondary: {
    contrastText: string;
    dark: string;
    main: string;
    light: string;
  };
  success: {
    contrastText: string;
    dark: string;
    main: string;
    light: string;
  };
  info: {
    contrastText: string;
    dark: string;
    main: string;
    light: string;
  };
  warning: {
    contrastText: string;
    dark: string;
    main: string;
    light: string;
  };
  error: {
    contrastText: string;
    dark: string;
    main: string;
    light: string;
  };
  text: {
    primary: string;
    secondary: string;
    disabled: string;
    hint: string;
  };
  background: {
    default: string;
    paper: string;
  };
  icon: string;
  divider: string;
  border: string;
};

export type OptionSet = {
  label: string;
  value: string;
};

export type ActivityListFilters = {
  categories: Array<any>;
  dateSort: string;
  lastSubmittedBy: string;
  limit: number;
  nextLink: string;
  submittedBy: string;
  unitNumber: string;
};
export type DotActivityListFilters = {
  categories: Array<any>;
  dateSort: string;
  lastSubmittedBy: string;
  limit: number;
  nextLink: string;
  submittedBy: string;
  driver: string;
};
export type AppPage = {
  title: string;
  icon: string;
  whiteIcon: string;
  route: string;
  show?: boolean;
  subRouteList?: any[];
};

export type CustomAppPage = {
  title: string;
  icon: string;
  aicon?: string;
  caicon?: string;
  whiteIcon: string;
  route: string;
  haschildren?: boolean;
  childrens?: AppPage[];
  routename?: string;
  subRouteList?: any[];
  show?: boolean;
  roles?: Array<any>;
};

export type customNavTabs = {
  activeTabKey?: string;
  title: string;
  icon?: string;
  activeIcon?: string;
  route?: string;
  index?: string;
  shortkey: string;
  tab: boolean;
  filter: boolean;
  isDisabled: boolean; // Hides it only in production if set true
};
export type customAppStyles = {
  icon: string;
  whiteIcon: string;
  index: number;
};

export type UcrFormInputs = {
  permitYear: string;
  expirationDate: string;
  type: string;
};

export type FTQuarterFilling = {
  year: string;
  quarter: string;
  statePermits: any[];
  equipments: any[];
  fuelCardReports: any[];
};

export type RoadTaxNewFormInputs = {
  requestType: string;
  years: Array<any>;
};

export type EquipmentSearchFormInputs = {
  simplexId: string;
  unitNumber: string;
  vinNumber: string;
};

// export type RoadTaxRenewalFormInputs = {
//   // units: string;
//   // selectUnit: string;
//   endPeriod: string;
//   period: string;
//   year: string;
//   // vin: string;
// };
export type AddressFormInputs = {
  address: string;
  city: string;
  state: string;
  zip: number;
};
export type CreateNYAndNMStatePermitsInput = {
  todoId?: string;
  dotNumber: string;
  equipment: Array<any>;
  mailingAddress?: AddressFormInputs | null;
  requestType: string;
  state: StatesPermits;
};
export type CreateIrpServiceInput = {
  todoId?: string;
  equipment: Array<any>;
  irpAccountNumber: string;
  // lastFuelTaxFiled: {
  //   quarter: string;
  //   year: number;
  // };
  requestType: string;
};
export type CreateKentuckyPermitsInput = {
  dotNumber: string;
  equipment: Array<any>;
  requestType: string;
  state: StatesPermits;
  hasUcrFiled: boolean | null;
  ucrExpDate: string | null;
};
export type CreateOregonPermitsInput = {
  equipment: Array<any>;
  requestType: string;
  state: StatesPermits;
};

export type RoadTaxPermitsFormInputs = {
  todoId?: string;
  requestType: string;
  equipment: Array<any>;
};

export type AnnualReportFormInputs = {
  physicalAddress: string;
  physicalCity: string;
  physicalState: string;
  physicalZip: string;
  mailingAddress: string;
  mailingCity: string;
  mailingState: string;
  mailingZip: string;
  // confirmOwner: string;
  // registeredAgent: string;
};

export type SHSSOptions = {
  isSHSS?: boolean;
  DriverApplication?: number;
  HireDate?: string;
  PreEmploymentMVR?: number;
  PreEmploymentClearingHouse?: number;
  PreEmploymentDrugTest?: number;
  PreEmploymentMedicalCard?: number;
  PreEmploymentPSP?: number;
  PreEmploymentBackgroundCheck?: number;
  PreEmploymentDriverLicense?: number;
  ApplicationDate?: string;
};

export type NewDriverSetupInputs = {
  firstName: string;
  middleName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  dob: string;
  ssn: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  licenseNumber: string;
  licenseStateId: string;
  licenseExpirationDate: string;
  driverLicenseEndorsements: Array<string>;
  licenseType: string;
  driverType: string;
  preEmploymentMVR: string;
  preEmploymentCH: string;
  preEmploymentDT: string;
  preEmploymentMC: string;
  preEmploymentDL: string;
  preEmploymentPSP: string;
  terminalId: string | null;
  dahire: string;
  applicationDate: string;
  driverApplication: string;
  preBackgroundCheck: string;
  isSHSSAccount: boolean;
  shssOptions: SHSSOptions | null;
};

export type UpdateDriverSetupInputs = {
  firstName: string;
  middleName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  dob: string;
  ssn: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  hireDate: string;
  licenseNumber: string;
  licenseState: string;
  licenseExpirationDate: string;
  licenseEndorsements: Array<string>;
  licenseType: string;
  driverType: string;
  terminalId: string | null;
};

export type AddToFleetFormInputs = {
  isSHSS: boolean;
  dotFtBundleStatus: boolean;
  registration: Array<any>;
  vqFile: Array<any>;
  equipmentType: string;
  unitType: string;
  unitNumber: string;
  vinNumber: string;
  grossVehicleWeight: string;
  vehicleYear: string;
  vehicleMake: string;
  licensePlateNumber: string;
  licensePlateState: string;
  ownershipType: string;
  primaryDriverContactId: string;
  isPermanantRegistration: boolean;
  registrationExpDate: string | null;
  registrationType: string;
  lastAnnualInspectionDate: string | null;
  isSimplexCompletingFuelTaxes: string | boolean | null;
};

export type UpdateFleetFormInputs = {
  isSHSS: boolean;
  dotFtBundleStatus: boolean;
  equipmentType: string;
  unitType: string;
  trailerType: string;
  unitNumber: string;
  vinNumber: string;
  grossVehicleWeight: string;
  vehicleYear: string;
  vehicleMake: string;
  licensePlateNumber: string;
  licensePlateState: string;
  ownershipType: string;
  primaryDriverContactId: string | null;
  isPermanantRegistration: boolean;
  registrationExpDate: string | null;
  registrationType: string;
  lastAnnualInspectionDate: string | null;
  isSimplexCompletingFuelTaxes: string | boolean | null;
};

export type IFTAFormInputs = {
  ifta: string;
  iftaExpirationDate: string;
  iftaState: string;
  selectUnit: string;
  type: string;
  unit: string;
  vin: string;
};

export type StatePermitsFormInputs = {
  dotNumber: string;
  mailingAddress?: string;
  mailingCity?: string;
  mailingState?: string;
  mailingZip?: string | null;
  requestType: string;
  expirationDate: string;
};

export type StatePermitsKentuckyFormInputs = {
  dotNumber: string;
  ucrExpDate: string | null;
  hasUcrFiled: string;
  requestType: string;
};

export type StatePermitsOregonFormInputs = {
  dateOfEntry: string | null;
  dateOfExit: string | null;
  deliveryAddress: string;
  deliveryCity: string;
  deliveryState: string;
  deliveryZip: string;
  dotNumber: string;
  loadDescription: string;
  requestType: string;
  milesTraveled: number | null;
  pointOfEntry: string;
  pointOfExit: string;
  routeEntry: string;
  routeExit: string;
  units: Array<any>;
};

export type DotUpdateFormInputs = {
  dot: string;
  dotPin: string;
  email: string;
  estimatedMiles: string;
  fein: string;
  physicalAddress: string;
  physicalCity: string;
  physicalState: string;
  physicalZip: string;
  mailingAddress: string;
  mailingCity: string;
  mailingState: string;
  mailingZip: string;
  name: string;
  phoneNumber: string;
  sameAs: boolean;
  ssn: string;
  biennial: boolean;
  vmt: boolean;
};

export type IRPServiceNewFormInputs = {
  irpExpirationDate: string;
  irpAccountNumber: string;
  irpState: string;
  units: Array<any>;
  requestType: string;
};

export type AuthTokenType = {
  accessToken: string;
  refreshToken: string;
  userId: string;
};

export type AccessType = {
  accidents: boolean;
  dashboard: DashboardAccess;
  documents: boolean;
  dot: DotAccess;
  drivers: boolean;
  fleet: boolean;
  notification: NotifiationAccess;
  reports: boolean;
  tpts: TptsAccess;
  inspectionsAndViolations: boolean;
  violations: boolean;
  training: boolean;
  trends: boolean;
};

export type TptsAccess = {
  annualReport: boolean;
  dotUpdate: boolean;
  ftQuarterlyFiling: boolean;
  ifta: boolean;
  irpService: boolean;
  roadTaxes: boolean;
  statePermits: boolean;
  ucr: boolean;
};

export type NotifiationAccess = {
  company: boolean;
  drivers: boolean;
  fleet: boolean;
};

export type AdminNotificationSearchParam = {
  limit?: number;
  title: string;
  type?: 'new' | 'weekly' | 'ad-hoc' | '';
  description: string;
  onlySplash?: any;
  nextLink?: string;
};

export type DisplayBoardSearchParam = {
  onlySplash: any;
  lastSeenOn: string;
};

export type DotAccess = {
  addToFleet: boolean;
  backgroundCheck: boolean;
  dataQ: boolean;
  drugTest: boolean;
  equipmentInspection: boolean;
  mvr: boolean;
  newDriverSetup: boolean;
  physicalExamLocator: boolean;
  terminateDriver: boolean;
  terminateEquipment: boolean;
};

export type DashboardAccess = {
  issScore: boolean;
  activeDriversCount: boolean;
  basicCategories: boolean;
  dailyUpdates: boolean;
  dotPin: boolean;
  companyTodo: boolean;
  driverTodo: boolean;
  fleetCount: boolean;
  fleetTodo: boolean;
  fmcsa: boolean;
  trainingTodo: boolean;
  inspectionsCount: boolean;
  last24MonthsInspectionCount: boolean;
  monthlyUpdates: boolean;
  trendingViolations: boolean;
};

export type AppDataType = {
  appId: string;
  clientApiKey: string;
  publisherAppId: string;
  appName: string;
  publisherClientApiKey: string;
};

export type LoginParams =
  | {
      authType: 'emailSignup';
      params: {
        email: string;
        password: string;
        referralCode?: string;
      };
    }
  | { authType: 'emailLogin'; params: { email: string; password: string } }
  | {
      authType: 'apple';
      params: {
        identityToken: string;
        authorizationCode: string;
      };
    }
  | { authType: 'google'; params: { idToken: string } }
  | { authType: 'facebook'; params: { fbAccessToken: string } };

export enum UserRole {
  Driver = 'driver',
  Admin = 'admin',
}

export enum AccessTypes {
  SimplexAdmin = 'simplexAdmin',
  CompanyAdmin = 'companyAdmin',
  OwnerOperator = 'ownerOperator',
  Driver = 'driver',
}

export type EquipmentInspection = {
  equipments: {
    equipmentType: string;
    grossVehicleWeight: string;
    simplexId: string;
    status: string;
    unitId: string;
    unitNumber: string;
    unitType: string;
    vinNumber: string;
    terminateStatus: boolean;
  }[];
  acknowledgmentForm?: acknowledgmentFormPayload;
};

export enum StatesPermits {
  Empty = '',
  Kentucky = 'KY',
  Oregon = 'OR',
  NewMexico = 'NM',
  NewYork = 'NY',
}

export enum EquipmentType {
  Empty = '',
  PowerUnit = 'power unit',
  Trailer = 'trailer',
}

export enum UnitType {
  Empty = '',
  Truck = 'Straight Truck',
  Tractor = 'Truck Tractor',
  Trailer = 'Trailer',
  Chassis = 'Chassis',
  Bus = 'Bus',
  Tanker = 'Tanker',
  Van = 'Van',
  Limousine = 'Limousine',
  PickUpTruck = 'Pick-Up Truck',
  Other = 'Other',
}

export enum OwnershipType {
  Empty = '',
  Client = 'Client/Company Owned',
  OwnerOperator = 'Owner Operator',
}

export enum Trailerype {
  Empty = '',
  DryVan = 'Dry Van',
  Reefer = 'Reefer',
  Flatbed = 'Flatbed',
  StepDeck = 'Step Deck',
  PowerOnly = 'Power Only',
}

export enum RegistrationType {
  Empty = '',
  IRP = 'IRP',
  Local = 'local',
}

export interface GetContactDataItem {
  contactId: string;
  simplexId: string;
  accountName: string;
  accountId: string;
  status: string;
}

export interface ContactData {
  contactId: string;
  password: string;
}

export type GetContactData = Array<GetContactDataItem>;

export type GetContactsDTO = {
  contactId: string;
  simplexId: string;
  accountName: string;
  accountId: string;
  status: string;
}[];

export type TerminateEquipments = {
  equipments: {
    equipmentType: string;
    grossVehicleWeight: string;
    simplexId: string;
    status: string;
    unitId: string;
    unitNumber: string;
    unitType: string;
    vinNumber: string;
    terminateStatus: string;
    dotFtBundle: any;
    unitTerminationConsent: boolean;
    nameConsentUnits: string;
    jobTitleConsentUnits: string;
    unitTerminationDate: string;
    terminationReason: string;
  }[];
};

export type GetContactDTO = {
  contactId: string;
  simplexId: string;
  accountName: string;
  accountId: string;
  status: string;
};

export enum RequestType {
  Empty = '',
  New = 'new',
  Renewal = 'renewal',
  Additional = 'additional',
  Temporary = 'temporary',
}

export type LoginDTO = {
  accessToken: string;
  refreshToken: string;
  id: string;
};

export type LoginFormCompanyInputs = {
  contactId: string;
  password: string;
};

export type LoginFormEmailInputs = {
  email: string;
};

export type EquipmentDTO = {
  response: Array<any>;
  nextLink: string;
};

export type NYAndNMStateDto = {
  companyId: string | null;
  dotNumber: string | null;
  expirationDate?: string | null;
  hasPermit?: boolean;
  mailingAddress: {
    address: string | null;
    city: string | null;
    state: string | null;
    zip: number | null;
  };
};

export type ActivityListResponseDTO = {
  category: string;
  notes: string;
  referenceId: string;
  subject: string;
  submittedOn: string;
  submittedBy: string;
  unitNumbers: string;
};

export type DotActivityListResponseDTO = {
  referenceId: string;
  subject: string;
  driver: string;
  submittedOn: string;
  submittedBy: string;
  category: string;
  notes: string;
};

export type ActivityListDTO = {
  count: number;
  response: Array<ActivityListResponseDTO>;
  nextLink: string;
};

export type SettingsCompanyInfo = {
  companyName: string;
  phoneNumber: string;
  dotNumber: string;
  mcNumber: string;
  businessAddress: string;
  businessCity: string;
  businessState: string;
  businessZip: string;
  mailingAddress: string;
  mailingCity: string;
  mailingState: string;
  mailingZip: string;
};

export type SettingsNotificationInfo = {
  id?: string;
  subtypeId?: string;
  status: boolean;
  type: string;
  subtype: string;
};

export type DocumentsListReponseDTO = {
  id: string;
  tags: string;
  fileName: string;
  fileSize: string;
  uploadedOn: string;
  url: string;
  status: string;
  accountId: string;
  unitId: string;
  contactId: string;
};

export type DocumentsDTO = {
  count: number;
  response: Array<DocumentsListReponseDTO>;
  nextLink: string;
};

export type DotActivityListDTO = {
  count: number;
  response: Array<DotActivityListResponseDTO>;
  nextLink: string;
};

export type ForgotPasswordFormInputs = {
  contactId: string;
  email: string;
};

export type ChangePasswordInput = {
  currentPassword: string;
  newPassword: string;
};

export type GetUsersProfileInfoDTO = {
  contactId: string;
  name: string;
  firstName?: string;
  lastName?: string;
  email: string;
  phoneNumber: string;
  dob: string;
  address: string;
  city?: string;
  state?: string;
  zip?: string;
  accountName: string;
  accessType: string;
};

export type UpdateUserProfileInfo = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dob: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  name: string;
  ssn: string;
  licenseNumber: string;
  driverType: string;
  licenseType: string;
  licenseState: string;
  licenseExpirationDate: string;
  driverLicenseEndorsements: any[];
};

export type VerifyOtpFormInputs = {
  otp: string;
};

export type CreatePasswordFormInputs = {
  newPassword: string;
  token?: string;
};

export type CreatePasswordValidationInput = {
  password: string;
};

export type ResetPasswordFormInputs = {
  password: string;
  resetToken: string;
};

export type ResetPasswordValidationInput = {
  password: string;
  confirmPassword: string;
};

export type AddUserFormInput = {
  first_name: string;
  last_name: string;
  phoneNumber?: string;
  role?: UserRole;
  email: string;
  notes?: string;
};

export type AddUserFormInput2 = {
  userName: string;
  email: string;
};

export type AddUserDTO = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  deletedAt: string;
  role: UserRole;
  signupComplete: boolean;
  active: boolean;
};

export type ApiErrorType =
  | AuthErrorType
  | FileErrorType
  | UserErrorType
  | ServerErrorType
  | GeneralErrorType
  | DateErrorType;

export class ApiError {
  message: string;
  status?: number;
  type?: ApiErrorType;
  actions: ErrorResponseActions;

  constructor({
    message,
    type,
    status,
    actions = {},
  }: {
    message: string;
    type?: ApiErrorType;
    status?: number;
    actions: ErrorResponseActions;
  }) {
    this.message = message;
    this.status = status;
    this.type = type;
    this.actions = actions;
  }
}

export interface GetUsersDataItem {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  deletedAt: string;
  signupComplete: boolean;
  active: boolean;
  phoneNumber: string;
  notes: string;
}

export type GetUsersData = Array<GetUsersDataItem>;

export type UpdateUserInput = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dob: string;
  address: string;
  city: string;
  state: string;
  zip: string;
};

export type AddTextDataItem = {
  type: string;
  value: string;
  isDraft: boolean;
};

export type GetTextInput = {
  type: string;
  isDraft: boolean;
};

export interface GetTextDataItem {
  type: string;
  value: string;
  isDraft: boolean;
}

export type UserStatusInput = {
  id: string;
  status: string;
};

export type UcrInput = {
  units: string;
  permitYear: string;
};

export type Address = {
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
};

export type AnnualReport = {
  physicalAddress: Address;
  mailAddress: Address;
  physicalMailAddressSameStatus: boolean;
};

export type CompanyDetails = {
  profileName: string;
  email: string;
  phoneNumber: string;
};

export type DotUpdate = {
  dot: string;
  dotpin: string;
  fein: string;
  ownerssn: string;
  physicalAddress: Address;
  mailAddress: Address;
  physicalMailAddressSameStatus: boolean;
  companyDetails: CompanyDetails;
  biennialReport: boolean;
  vmtReport: boolean;
  estimatedMiles: string;
};

export type UnitOptions = {
  value: string;
  title: string;
  disabled: boolean;
};

export type PreEmploymentOptions = {
  value: string;
  title: string;
  disabled: boolean;
};

export type TODOOptions = {
  value: string;
  label: string;
  disabled: boolean;
};

export type AddressField = {
  address: string;
  state: string;
  city: string;
  zipcode: string;
};

export type AddressProps = {
  control: any;
  errors: FieldErrors;
  onChangeFn: (addressKey: any, fieldKey: string, fieldValue: any) => void;
  addressName: string;
  disableStatus: boolean;
  fields: AddressField;
};

export type TaxAndPermitsDetail = {
  UCR: string;
  ROADTAXES: string;
  ANNUALREPORT: string;
  IFTA: string;
  STATEPERMITS: string;
  IRPSERVICE: string;
  DOTUPDATE: string;
  FTQUARTERFILLING: string;
  DRIVERS: string;
  FLEETS: string;
};

export type DriverAndFleetNotification = {
  DRIVERS: string;
  FLEETS: string;
};

export type ImageSizeType = 'tiny' | 'small' | 'medium' | 'large' | 'xlarge';

export type GetFileInput = {
  size?: ImageSizeType;
  noRedirect: boolean;
};

export type MVRFormInputs = {
  type: string;
};

export type DirverSearchParams = {
  firstName?: string;
  lastName?: string;
  cdlNumber?: string;
  dob?: string | Date;
};

export type CompanyOrDriverDTO = {
  count?: number | undefined;
  response: Array<any>;
  nextLink: string;
};

export type FleetDTO = {
  countPowerUnit?: number | undefined;
  countTrailer?: number | undefined;
  response: Array<any>;
  nextLink: string;
  sortInfo?: string;
};

export type AdminDTO = {
  response: Array<any>;
  nextLink: string;
};

export type DriverSearchFilters = {
  name: string;
  driverType: string;
  simplexId: string;
  limit: number;
  nextLink: string;
};

export type DriverSearchFormInputs = {
  name: string;
  driverType: string;
  simplexId: string;
};

export type TodoSearchFilters = {
  categories: Array<any>;
  firstName: string;
  lastName: string;
  simplexId: string;
  year: string;
  make: string;
  vinNumber: string;
  unitNumber: string;
  dueIn: string;
  type: string;
  limit: number;
  nextLink: string;
  trainingAssignedTo?: string;
  trainingReason?: string;
  todoTrainingReason?: string;
  todoTrainingAssignedTo?: string;
  unitId?: string;
  driverId?: string;
  driverType?: string;
};

export type TrendTodoSearchFilters = {
  todoCategory?: string[];
  todoType?: string;
  todoDueIn?: string;
  searchType?: string;
  firstName?: string;
  lastName?: string;
  simplexId?: string;
  year?: string;
  make?: string;
  vinNumber?: string;
  unitNumber?: string;
  todoTrainingReason?: string;
  todoTrainingAssignedTo?: string;
};

export type TodoSearchFormInputs = {
  firstName: string;
  category?: string[];
  driverId?: string;
  lastName: string;
  simplexId: string;
  year: string;
  make: string;
  vinNumber: string;
  unitNumber: string;
  dueIn: string;
  type: string;
  searchType?: string;
  trainingAssignedTo?: string;
  trainingReason?: string;
  unitId?: string;
};

export type TrendTodoSearchFormInputs = TodoSearchFormInputs & {
  violationGroup: string;
  todoType?: string;
  todoTrainingReason?: string;
  todoTrainingAssignedTo?: string;
  todoDueIn?: string;
  todoCategory?: string[];
};

export type MedicalCardFormInputs = {
  issueDate: string;
  expirationDate: string;
  medicalExaminerName: string;
  nationalRegistry: string;
};

export type UpdateMedicalCardFormInputs = {
  medicalCardExpiryDate: string;
  medicalCardIssueDate: string;
  medicalExaminerName: string;
  nationalRegistryNumber: string;
};

export type FleetSearchFilters = {
  unitNumber: string;
  simplexId: string;
  vinNumber: string;
  limit?: number;
  nextLink?: string;
  unitType?: string;
  expirationStartDate?: string;
  expirationEndDate?: string;
};

export type FleetSearchFormInputs = {
  simplexId: string;
  vinNumber: string;
  unitNumber: string;
  unitType: string;
  expirationStartDate?: string | null;
  expirationEndDate?: string | null;
};

export type UsersSearchFilters = {
  name: string;
  limit: number;
  nextLink: string;
};

export type UsersSearchFormInputs = {
  name: string;
};

export type UsersDTO = {
  response: Array<any>;
  nextLink: string;
};

export type AdminUsersSearchFilters = {
  firstName: string;
  lastName: string;
  email: string;
  limit: number;
  nextLink: string;
};

export type AdminUsersSearchFormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  contactType: string;
};

export type RoleFormInputs = {
  name: string;
};

export type DataQChallenge = {
  dataQChallenges: any[];
};

export interface TodoRequest {
  todoId: string;
}

export type SingleDriverVinMatch = {
  primaryDriverName: string;
  secondaryDriverName: string;
  powerUnitVin: string;
  trailerVin: string;
  matchedPrimaryName: string;
  matchedSecondaryName: string;
  matchedPowerUnitVin: string;
  matchedTrailerVin: string;
  primaryDriverMessage: string;
  secondaryDriverMessage: string;
  powerUnitVINMessage: string;
  trailerVINMessage: string;
  primaryDriverIncludeCheck: boolean;
  secondaryDriverIncludeCheck: boolean;
  powerUnitVINIncludeCheck: boolean;
  trailerVINIncludeCheck: boolean;
  powerUnitVinMatchedId: string;
  secondaryDriverMatchedId: string;
  primaryDriverMatchedId: string;
  trailerVinMatchedId: string;
};

export enum PreEmploymentOptionsValues {
  SELF_HANDLE = 140320000,
  SIMPLEX_HANDLE = 140320001,
  ALREADY_COMPLETED = 140320002,
}

/* ***************** Training Module START ******************* */
export type TrainingInspectionFilters = {
  reportNumber?: string;
  inspectionState?: string;
  limit?: string;
  nextLink?: string;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  sortChanged?: boolean;
  driverName?: string;
  driverId?: string;
  driverSimplexId?: string;
  inspectionDateStart?: string;
  inspectionDateEnd?: string;
};

export type TrainingAccidentFilters = {
  accidentState?: string;
  accidentNumber?: string;
  driverName?: string;
  driverId?: string;
  powerUnitVin?: string;
  limit?: string;
  nextLink?: string;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  sortChanged?: boolean;
  accidentDateStart?: string;
  accidentDateEnd?: string;
};

export enum TrainingStatus {
  ASSIGNED = 'Assigned',
  VIDEO_PENDING = 'Video Pending',
  QUIZ_PENDING = 'Quiz Pending',
  QUIZ_FAILED = 'Quiz Failed',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
  DISMISSED = 'Dismissed',
}

export enum TrainingType {
  ALL = 'all',
  VIDEO = 'video',
  DOCUMENT = 'document',
}

export enum TrainingAssignedSortOptions {
  CONTACT_NAME = 'contactName',
  VIDEO_ASSIGNED = 'videoAssigned',
  ATTEMPTS_LEFT = 'attemptsList',
  STATUS = 'status',
  ASSIGNED_BY = 'assignedBy',
  ASSIGNED_DATE = 'assignedDate',
  DUE_DATE = 'dueDate',
}

export enum TrainingCompletedSortOptions {
  CONTACT_NAME = 'contactName',
  VIDEO_ASSIGNED = 'videoAssigned',
  SCORE = 'score',
  STATUS = 'status',
  ASSIGNED_BY = 'assignedBy',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

export type TrainingAssignedFilters = {
  limit?: string;
  nextLink?: string;
  sortBy?: TrainingAssignedSortOptions;
  sortDirection?: 'asc' | 'desc';
  sortChanged?: boolean;
  contactName?: string;
  contactId?: string;
  videoAssigned?: string;
  reason?: string;
  status?: TrainingStatus;
  assignedBy?: string;
  assignedById?: string;
  assignedDateStart?: string;
  assignedDateEnd?: string;
  dueDateStart?: string;
  dueDateEnd?: string;
  trainingType?: TrainingType;
  inspectionReportNumber?: string;
  accidentNumber?: string;
};

export type TrainingCompletedFilters = {
  limit?: string;
  nextLink?: string;
  sortBy?: TrainingCompletedSortOptions;
  sortDirection?: 'asc' | 'desc';
  sortChanged?: boolean;
  contactName?: string;
  contactId?: string;
  videoAssigned?: string;
  reason?: string;
  status?: TrainingStatus;
  assignedBy?: string;
  assignedById?: string;
  startDateStart?: string;
  startDateEnd?: string;
  endDateStart?: string;
  endDateEnd?: string;
  trainingType?: TrainingType;
  inspectionReportNumber?: string;
  accidentNumber?: string;
};

export type CreateTrainingAccidentAssignmentInputs = {
  accidentId: string;
  assignments: {
    trainingId: string;
    isAssigned: boolean;
    contactId: string;
    violationId: string;
  }[];
};

export type CreateTrainingInspectionAssignmentInputs = {
  inspectionId: string;
  driverType: string;
  assignments: {
    trainingId: string;
    isAssigned: boolean;
    contactId: string;
    violationId: string;
  }[];
};

export type UpdateQuizAnswersForAssignmentInputs = {
  attemptNumber: number;
  quizId: string;
  quizQuestionId: string;
  answers: {
    quizQuestionAnswerId: string;
    isCorrect: boolean;
    attempt: number;
  }[];
  isFirstAnswer: boolean;
  passingScorePercentage?: number;
  totalQuestionsCount?: number;
  isLastAnswer: boolean;
  correctAnswersCount?: number;
  incorrectAnswersCount?: number;
  score?: number;
  status?: string;
};
/* ***************** Training Module END ******************* */

export type TodoAutoLiabilityInput = {
  renewPlanning: string;
  receiveQuote: string;
  preferredContact: string;
  preferredTime: string;
};
