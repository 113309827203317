import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  Link,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';

import { SelectDatePicker } from '../../../../../components/Forms/datePicker';
// import { InputText } from '../../../../../../components/Forms/InputText';
import { SelectInput } from '../../../../../components/Forms/selectInput';
import SuccessNotification from '../../../../../components/SuccessNotification';
import { useCustomStore } from '../../../../../hooks';
import { OptionSet, TerminateEquipments } from '../../../../../types';
import { consentAcknowledgmentTermination } from '../../../../../utils/config';
import { SnackBarConfig } from '../../../../../utils/SnackBarConfig';
import { TerminateEquipmentSchema } from '../../../../../utils/ValidatorSchema';
import TerminationContracts from '../../../Todos/TerminationContracts';
import TaxPermitsButtons from '../../components/ButtonsTaxPermits/ButtonTaxPermits';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import TaxPermitsEquipmentCard from '../../components/TaxPermitsEquipmentCard/EquipmentCardContent/TaxPermitsEquipmentCard';
import TaxPermitsEquipmentHeader from '../../components/TaxPermitsEquipmentCard/EquipmentCardHeader/EquipmentCardHeader';
import useStyles from './TerminateEquipmentStyles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  color: theme.palette.text.primary,
  maxWidth: 400,
  padding: theme.spacing(2),
}));

const TerminateEquipmentComp: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const { terminateEquipmentStore, dotActivityListStore, fleetStore }: any =
    useCustomStore();
  const { dbsStore } = useCustomStore();
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [notification, setNotification] = useState(false);

  const [unitTerminationConsent, setUnitTerminationConsent] = useState(false);
  const [nameConsentUnits, setNameConsentUnit] = useState('');
  const [jobTitleConsentUnits, setJobTitleConsentUnit] = useState('');
  const [typeContract, setTypeContract] = useState('UnitTermination');
  const [terminatinReasonOptions, setTerminatinReasonOptions] = useState<
    OptionSet[]
  >([]);
  const [acknowledgmentFormDialog, setAcknowledgmentFormDialog] =
    useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    // watch,
    // resetField
    reset: newFormRest,
  } = useForm<TerminateEquipments>({
    criteriaMode: 'all',
    defaultValues: {
      equipments: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(TerminateEquipmentSchema),
  });

  const {
    fields: equimentfields,
    // append: equipmentappend,
    remove: equipmentremove,
    replace: equipmentreplace,
  }: any = useFieldArray({
    control,
    name: 'equipments',
  });

  const onselect = useCallback(
    async (equipments: any) => {
      if (equipments.length) {
        const ArrayEquipment: any = equipments.map((e: any) => {
          return {
            ...toJS(e),
            terminateStatus: '',
            terminationReason: '',
            unitTerminationDate: null,
          };
        });
        const ExistedEquipmentDetails: any = [...getValues('equipments')];
        const newEquipmentArr: any = [];

        ArrayEquipment.forEach((equipment: any) => {
          const findStatusIndex: any = ExistedEquipmentDetails.findIndex(
            (e: any) => e.unitId === equipment.unitId,
          );
          if (findStatusIndex !== -1) {
            ExistedEquipmentDetails.splice(findStatusIndex, 1);
          } else {
            newEquipmentArr.push(equipment);
          }
        });

        let originalEquipmentArray: any = [...getValues('equipments')];
        if (ExistedEquipmentDetails.length) {
          ExistedEquipmentDetails.forEach((equipment: any) => {
            const findIndexElem = originalEquipmentArray.findIndex(
              (e: any) => e.unitId === equipment.unitId,
            );
            if (findIndexElem !== -1) {
              originalEquipmentArray.splice(findIndexElem, 1);
            }
          });
        }

        originalEquipmentArray = [
          ...originalEquipmentArray,
          ...newEquipmentArr,
        ].map((equipment: any) => {
          const obj = { ...equipment };
          if (!obj.terminateStatus) {
            obj.terminateStatus = '';
          }
          return obj;
        });

        equipmentreplace(originalEquipmentArray);

        originalEquipmentArray.forEach(
          (equipment: any, findIndexElem: number) => {
            const stringIndex: string = findIndexElem.toString();
            const terminateStatusStr: any =
              'equipments.' + stringIndex + '.terminateStatus';
            if (equipment.terminateStatus) {
              trigger(terminateStatusStr);
              setValue(terminateStatusStr, equipment.terminateStatus);
            } else {
              // resetField(terminateStatusStr);
              setValue(terminateStatusStr, '');
            }
          },
        );

        terminateEquipmentStore.addEquipmentDetails(originalEquipmentArray);

        await dbsStore.getOptionSet('sg_terminationreason');
        const data = dbsStore.OptionSet;
        const result: OptionSet[] = [];
        if (data) {
          for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
              result.push({ label: key, value: String(data[key]) });
            }
          }
          setTerminatinReasonOptions(result);
        }
      }
    },
    [
      equipmentreplace,
      getValues,
      setValue,
      terminateEquipmentStore,
      trigger,
      dbsStore,
    ],
  );

  const remove = (equipment: any) => {
    const findindexedEquipment: any = [...getValues('equipments')].findIndex(
      (e: any) => e.unitId === equipment.unitId,
    );
    if (findindexedEquipment !== -1) {
      equipmentremove(findindexedEquipment);
      terminateEquipmentStore.removeEquipmentDetail(findindexedEquipment);
    }
  };

  const resetForm = useCallback(() => {
    newFormRest();
    setNameConsentUnit('');
    setJobTitleConsentUnit('');
    setUnitTerminationConsent(false);
    terminateEquipmentStore.resetEquipmentInspection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllActivityList = useCallback(async () => {
    dotActivityListStore.setShowLoader(true);
    dotActivityListStore.setActivityListApiReset();
    await dotActivityListStore.fetchActivityLists();
    dotActivityListStore.setShowLoader(false);
  }, [dotActivityListStore]);

  const submit = useCallback(async (data: any) => {
    setBtnDisable(true);
    if (terminateEquipmentStore.getAcknowledgmentVaidation()) {
      setBtnDisable(false);
      return;
    }
    const response = await terminateEquipmentStore.addEquipmentInspection();
    if (response.isOk()) {
      enqueueSnackbar(
        'Submission Successful, Simplex has your request. Please allow 24-48 for your request to be processed.',
        SnackBarConfig('s'),
      );
      setNotification(true);
      successDefiner();
    }

    if (response.isErr()) {
      let message: any = 'Error while processing data';
      if (typeof response.error.message === 'string') {
        message = response.error.message;
      }
      if (typeof response.error.message === 'object') {
        message = 'Validation Error';
      }
      enqueueSnackbar(message, SnackBarConfig('e'));
    }
    setBtnDisable(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const successDefiner = () => {
    getAllActivityList();
    resetForm();
    const equipArr =
      getValues('equipments') && getValues('equipments').length
        ? getValues('equipments').map((value, index) => index)
        : [];

    equipmentremove(equipArr);
  };

  const handleShowDialog = (show: boolean) => {
    setAcknowledgmentFormDialog(show);
  };

  const errorDefiner = () => {
    const errorObj: any = { ...errors };
    return errorObj?.equipments?.message || '';
  };

  useEffect(() => {
    let data = fleetStore.EquipmentFromFleet;
    if (data) {
      data = toJS(data);
      data = [data];
      onselect(data);
      fleetStore.setEquipmentFromFleet();
    }
  }, [fleetStore, onselect]);

  useEffect(() => {
    const payloadAcknowledgmentUnit: acknowledgmentFormPayload = {
      caseId: 'data',
      consent: unitTerminationConsent,
      name: nameConsentUnits,
      position: jobTitleConsentUnits,
      terminationType: 'UnitTermination',
      todoId: '',
    };
    terminateEquipmentStore.setAcknowledgmentData(payloadAcknowledgmentUnit);
  }, [
    nameConsentUnits,
    jobTitleConsentUnits,
    unitTerminationConsent,
    terminateEquipmentStore,
  ]);

  if (notification) {
    return (
      <SuccessNotification
        title="Terminate Equipment"
        handleNotification={() => setNotification(false)}
      />
    );
  }
  if (btnDisable) {
    return <LoadingModal isOpen={btnDisable} />;
  }
  return (
    <Grid className={classes.container}>
      <TerminationContracts
        todoType={typeContract}
        handleShowDialog={handleShowDialog}
        showDialog={acknowledgmentFormDialog}
      />
      <form
        style={{
          width: '100%',
        }}
        id={'terminate-equipment'}
        onSubmit={handleSubmit(submit)}>
        <FormControl variant="outlined" fullWidth size="small">
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ md: 3, sm: 2, xs: 1 }}
            minHeight={'100%'}>
            <Grid item md={12} sm={12} xs={12}>
              <TaxPermitsEquipmentHeader
                selecetedEquipment={[...equimentfields]}
                onSelectEquipment={onselect}
                disabled={false}
                requestType="DOT"
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              {equimentfields.length ? (
                equimentfields.map((ele: any, index: number) => {
                  return (
                    <TaxPermitsEquipmentCard
                      key={index}
                      ele={ele}
                      removedCard={remove}>
                      {ele.equipmentType !== 'trailer' && (
                        <>
                          <Divider
                            style={{ margin: '1rem 0' }}
                            className={classes.divider}
                          />
                          <Grid
                            container
                            rowSpacing={2}
                            columnSpacing={{ md: 3, sm: 2, xs: 1 }}>
                            <Grid item md={10} sm={12} xs={12}>
                              <Box className={classes.formLabelClass}>
                                Does the selected equipment have any state
                                permits or registrations that need to be
                                surrendered or terminated? *
                              </Box>
                              <Controller
                                control={control}
                                name={`equipments.${index}.terminateStatus`}
                                shouldUnregister={true}
                                render={({
                                  field: { onChange, value, name, ref },
                                  formState,
                                }) => {
                                  let message: any = '';
                                  const errorsobj: any = { ...errors };
                                  if (
                                    errorsobj?.equipments?.[index]
                                      ?.terminateStatus?.message
                                  ) {
                                    message =
                                      errorsobj.equipments[index]
                                        .terminateStatus.message;
                                  }
                                  return (
                                    <FormControl className={classes.w100}>
                                      <SelectInput
                                        isFormSubmittedOnce={
                                          !!formState.submitCount
                                        }
                                        inputRefObj={ref}
                                        errors={message}
                                        name={name}
                                        size={'small'}
                                        isRefreshValue={true}
                                        value={value}
                                        onChangeText={(value: any) => {
                                          onChange(value);
                                          terminateEquipmentStore.setIndexEquipmentDetails(
                                            index,
                                            'terminateStatus',
                                            value,
                                          );
                                        }}
                                        isEditable={false}
                                        selectClassName={
                                          classes.selectClassName
                                        }
                                        sortByOptionDisable={true}
                                        optionsList={[
                                          { label: 'Yes', value: 'yes' },
                                          { label: 'No', value: 'no' },
                                        ]}
                                      />
                                    </FormControl>
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {/* Adding Reason terminatin fields */}
                      {ele.equipmentType === 'trailer' && (
                        <Divider
                          style={{ margin: '1rem 0' }}
                          className={classes.divider}
                        />
                      )}
                      <Grid container wrap="nowrap" spacing={2}>
                        <Grid item xs>
                          <Box className={classes.formLabelClass}>
                            Reason for Termination *
                          </Box>
                          {/* Reason for termination */}
                          <Controller
                            control={control}
                            name={`equipments.${index}.terminationReason`}
                            shouldUnregister={true}
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              let message: any = '';
                              const errorsobj: any = { ...errors };
                              if (
                                errorsobj?.equipments?.[index]
                                  ?.terminationReason?.message
                              ) {
                                message =
                                  errorsobj.equipments[index].terminationReason
                                    .message;
                              }
                              return (
                                <FormControl className={classes.w100}>
                                  <SelectInput
                                    isFormSubmittedOnce={
                                      !!formState.submitCount
                                    }
                                    inputRefObj={ref}
                                    errors={message}
                                    name={name}
                                    size={'small'}
                                    isRefreshValue={true}
                                    value={value}
                                    onChangeText={(value: any) => {
                                      onChange(value);
                                      terminateEquipmentStore.setIndexEquipmentDetails(
                                        index,
                                        'terminationReason',
                                        value,
                                      );
                                    }}
                                    isEditable={false}
                                    selectClassName={classes.selectClassName}
                                    optionsList={terminatinReasonOptions}
                                  />
                                </FormControl>
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs>
                          <Box className={classes.formLabelClass}>
                            Unit Termination Date *
                          </Box>
                          {/* Termination Date */}
                          <Controller
                            control={control}
                            name={`equipments.${index}.unitTerminationDate`}
                            shouldUnregister={true}
                            render={({
                              field: { onChange, value, name, ref },
                              formState,
                            }) => {
                              let message: any = '';
                              const errorsobj: any = { ...errors };
                              if (
                                errorsobj?.equipments?.[index]
                                  ?.unitTerminationDate?.message
                              ) {
                                message =
                                  errorsobj.equipments[index]
                                    .unitTerminationDate.message;
                              }
                              return (
                                <FormControl className={classes.w100}>
                                  <SelectDatePicker
                                    isFormSubmittedOnce={
                                      !!formState.submitCount
                                    }
                                    inputRefObj={ref}
                                    errors={message}
                                    name={name}
                                    isRefreshValue={true}
                                    value={value}
                                    onChangeDate={(e) => {
                                      onChange(e);
                                      terminateEquipmentStore.setIndexEquipmentDetails(
                                        index,
                                        'unitTerminationDate',
                                        value,
                                      );
                                    }}
                                    disabled={false}
                                  />
                                </FormControl>
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </TaxPermitsEquipmentCard>
                  );
                })
              ) : (
                <div
                  className="custom-error"
                  style={{ padding: '1rem 0', textAlign: 'center' }}>
                  {' '}
                  {errorDefiner() ? errorDefiner() : 'Please Select Equipment'}
                </div>
              )}
              {equimentfields.length ? (
                <Box
                  sx={{
                    borderRadius: 2,
                    marginBottom: '8px',
                    width: '100%',
                  }}>
                  <StyledPaper
                    sx={{
                      background: '#eee',
                      borderColor: '#a6a6a6',
                      margin: '2px',
                      maxWidth: '100%',
                      mx: 'auto',
                      my: 1,
                      p: 2,
                    }}>
                    <Grid
                      container
                      wrap="nowrap"
                      spacing={2}
                      sx={{
                        borderBottom: '2px',
                        borderBottomWidth: '2px',
                        marginBottom: '2px',
                      }}>
                      <Grid item>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={() => {
                            setTypeContract('UnitTermination');
                            handleShowDialog(true);
                          }}>
                          Unit Termination Acknowledgment Form
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      wrap="nowrap"
                      spacing={2}
                      sx={{ marginBottom: '20px' }}>
                      <Grid item>
                        <Checkbox
                          checked={unitTerminationConsent}
                          sx={{
                            '& .MuiSvgIcon-root': { fontSize: 35 },
                            '&.Mui-checked': {
                              color: green[500],
                            },
                          }}
                          onChange={(event) => {
                            setUnitTerminationConsent(event.target.checked);
                          }}
                        />
                      </Grid>
                      <Grid item xs>
                        <Typography align="justify">
                          I,<b> {nameConsentUnits}</b>, hereby Acknowledgment
                          that i have read and understand the terms outlined in
                          the
                          {consentAcknowledgmentTermination.UTAF}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container wrap="nowrap" spacing={2}>
                      <Grid item xs>
                        <TextField
                          required
                          disabled={!unitTerminationConsent}
                          id="nameConsentUnits"
                          label="Name"
                          value={nameConsentUnits}
                          fullWidth
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            setNameConsentUnit(event.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs>
                        <TextField
                          required
                          disabled={!unitTerminationConsent}
                          id="jobTitleConsentUnits"
                          label="Job Title"
                          value={jobTitleConsentUnits}
                          fullWidth
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            setJobTitleConsentUnit(event.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      wrap="nowrap"
                      spacing={2}
                      style={{ paddingTop: '4px' }}>
                      <Grid item xs>
                        {!unitTerminationConsent && (
                          <p style={{ color: red[500], fontSize: '9pt' }}>
                            *Please indicate that you have read and agree to the
                            Acknowledgment Form
                          </p>
                        )}
                        {(!nameConsentUnits || !jobTitleConsentUnits) && (
                          <p style={{ color: red[500], fontSize: '9pt' }}>
                            *Please enter Name and Job title
                          </p>
                        )}
                      </Grid>
                    </Grid>
                  </StyledPaper>
                </Box>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </FormControl>
      </form>
      <Divider className={classes.divider} />
      <TaxPermitsButtons
        formId={'terminate-equipment'}
        disabled={btnDisable}
        formReset={resetForm}
        submitButtonName="Terminate"
      />
    </Grid>
  );
};

export default observer(TerminateEquipmentComp);
