import { makeAutoObservable } from 'mobx';

import { API_LIMIT } from '../config';
import { ApiError, TodoSearchFilters, TodoSearchFormInputs } from '../types';
import { RootStore } from '.';

const docSignSlugConfig: Record<string, string> = {
  'MVR Authorization': 'mvr',
  PSP: 'psp',
  'Pre-Employment PSP Authorization': 'psp',
};

export class TodosStore {
  private _limit = API_LIMIT;
  private _status = 'Open';
  private _todoList: Array<any> = [];
  private _showLoader = false;
  private _hasMoreResults = false;
  private _nextLink = '';
  private _filter: TodoSearchFilters = {
    categories: [],
    driverType: '',
    dueIn: '',
    firstName: '',
    lastName: '',
    limit: API_LIMIT,
    make: '',
    nextLink: this.NextLink,
    simplexId: '',
    trainingAssignedTo: '',
    trainingReason: '',
    type: '',
    unitNumber: '',
    vinNumber: '',
    year: '',
  };
  private _sortBy = 'dueIn';
  private _sortDirection = 'asc';
  private _refresh = false;
  private _sortClicked = false;
  private _equipmentFromTodo: any = null;

  private _trainingTodoDetails: any = {};
  private _selectedTrainingAssignment: any = null;
  private _setSearchType = '';

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false });
  }

  setHasMoreResults(hasMoreResults: boolean) {
    this._hasMoreResults = hasMoreResults;
  }

  setFilters(data: TodoSearchFilters) {
    this.setTodoApiReset(false, true);
    this._filter = data;
  }

  setSearchType(searchType: string) {
    this._setSearchType = searchType;
  }

  resetFilters() {
    this.setFilters({
      categories: [],
      dueIn: '',
      firstName: '',
      lastName: '',
      limit: 0,
      make: '',
      nextLink: '',
      simplexId: '',
      trainingAssignedTo: '',
      trainingReason: '',
      type: '',
      unitNumber: '',
      vinNumber: '',
      year: '',
    });
  }

  setTodoList(data: Array<any>) {
    this._todoList = data;
  }

  setShowLoader(value: boolean) {
    this._showLoader = value;
  }

  setNextLink(link: string) {
    this._nextLink = link;
  }

  setStatus(value: string) {
    this._status = value;
  }

  setIfRefresh(value: boolean) {
    this._refresh = value;
  }

  setTodoApiReset(hasMoreResult = false, firstTry = true) {
    firstTry ? this.setNextLink('') : null;
    firstTry ? this.setTodoList([]) : null;
    this.setIfRefresh(false);
    firstTry ? this.resetTrainingTodoDetails() : null;
  }

  setSortBy(data: string) {
    this._sortBy = data;
  }

  setSortDirection(data: string) {
    this._sortDirection = data;
  }

  setEquipmentFromTodo(data: any = null) {
    this._equipmentFromTodo = data;
  }

  get EquipmentFromTodo() {
    return this._equipmentFromTodo;
  }

  get ShowLoader() {
    return this._showLoader;
  }

  get Filters() {
    return this._filter;
  }

  get TodoList() {
    return this._todoList;
  }

  get NextLink() {
    return this._nextLink;
  }

  get Status() {
    return this._status;
  }

  get IsRefresh() {
    return this._refresh;
  }

  get IfClickedSortIcon() {
    return this._sortClicked;
  }

  get HasMoreResults() {
    return this._hasMoreResults;
  }

  get SortBy() {
    return this._sortBy;
  }

  get SortDirection() {
    return this._sortDirection;
  }

  get SearchType() {
    return this._setSearchType;
  }

  setTrainingTodoDetails(details: any) {
    this._trainingTodoDetails = { ...this._trainingTodoDetails, ...details };
  }
  resetTrainingTodoDetails() {
    this._trainingTodoDetails = {};
  }
  get TrainingTodoDetails() {
    return this._trainingTodoDetails;
  }

  setSelectedTrainingAssignment(details: any) {
    this._selectedTrainingAssignment = details;
  }
  get SelectedTrainingAssignment() {
    return this._selectedTrainingAssignment;
  }
  get SelectedTrainingAssignmentId() {
    return this._selectedTrainingAssignment
      ? this._selectedTrainingAssignment.assignmentId
      : '';
  }
  get SelectedTrainingAssignmentTodoId() {
    return this._selectedTrainingAssignment
      ? this._selectedTrainingAssignment.todoId
      : '';
  }

  async getTrainingTodoDetails(data: any[]) {
    if (data.length === 0) {
      return;
    }
    const promiseReq: any[] = [];
    data.forEach((todo: any) => {
      if (todo.category === 'Training') {
        promiseReq.push(this.getTodoDetailsForAnAssignment(todo.id));
        this.setTrainingTodoDetails({
          [todo.id]: {
            data: null,
            loader: true,
          },
        });
      }
    });
    await Promise.all(promiseReq);
  }

  async fetchTodosBySorting(clicked?: boolean) {
    if (clicked) this._sortClicked = true;
    this.setTodoApiReset(false);
    return await this.fetchTodos();
  }

  async fetchTodos(id = '') {
    const params: any = {};
    this.Filters.firstName != ''
      ? (params['firstName'] = this.Filters.firstName)
      : null;
    this.Filters.lastName != ''
      ? (params['lastName'] = this.Filters.lastName)
      : null;
    this.Filters.simplexId != ''
      ? (params['simplexId'] = this.Filters.simplexId)
      : null;
    this.Filters.year != '' ? (params['year'] = this.Filters.year) : null;
    this.Filters.make != '' ? (params['make'] = this.Filters.make) : null;
    this.Filters.vinNumber != ''
      ? (params['vinNumber'] = this.Filters.vinNumber)
      : null;
    this.Filters.unitNumber != ''
      ? (params['unitNumber'] = this.Filters.unitNumber)
      : null;
    this.Filters.dueIn != '' ? (params['dueIn'] = this.Filters.dueIn) : null;
    this.Filters.type != '' ? (params['type'] = this.Filters.type) : null;
    this.Filters.categories.length
      ? (params['category'] = this.Filters.categories)
      : null;

    this._status === 'Open'
      ? (params['sortBy'] = this.SortBy)
      : this.IfClickedSortIcon
      ? (params['sortBy'] = this.SortBy)
      : null;
    this._status === 'Open'
      ? (params['sortDirection'] = this.SortDirection)
      : this.IfClickedSortIcon
      ? (params['sortDirection'] = this.SortDirection)
      : null;

    params['limit'] = this._limit;
    params['nextLink'] = this._nextLink;
    params['status'] = this._status;

    if (id) {
      params.todoId = id;
    }

    const response = await this.rootStore.apiStore.todoApi.getTodoList(params);
    if (response.isOk()) {
      const { response: results, nextLink } = response.value;
      const fullResult = this._todoList.concat(results);

      this.setTodoList(fullResult);
      this.setNextLink(nextLink);
      this.setHasMoreResults(nextLink ? true : false);
      this.getTrainingTodoDetails(results);
    }
    return response;
  }

  async fetchTodosBySearchBased(
    searchVal: TodoSearchFormInputs,
    firstTry = true,
  ) {
    this.setTodoApiReset(true, firstTry);
    const params: any = {
      ...searchVal,
    };

    this.Filters.categories.length
      ? (params['category'] = this.Filters.categories)
      : null;

    this._status === 'Open'
      ? (params['sortBy'] = this.SortBy)
      : this.IfClickedSortIcon
      ? (params['sortBy'] = this.SortBy)
      : null;
    this._status === 'Open'
      ? (params['sortDirection'] = this.SortDirection)
      : this.IfClickedSortIcon
      ? (params['sortDirection'] = this.SortDirection)
      : null;

    params['limit'] = this._limit;
    params['nextLink'] = this._nextLink;
    params['status'] = this._status;

    const response = await this.rootStore.apiStore.todoApi.getTodosBySearch(
      params,
    );
    if (response.isOk()) {
      const { response: results, nextLink } = response.value;
      if (results.length > 0) {
        const fullResult = this._todoList.concat(results);
        this.setTodoList(fullResult);
        this.setNextLink(nextLink);
        this.getTrainingTodoDetails(results);
      }
      this.setHasMoreResults(results.length === this._limit);
    }
    return response;
  }

  async exportTodos(searchVal: TodoSearchFormInputs) {
    const params: {
      nextLink: string;
      status: string;
      category: Array<any>;
      firstName: string;
      lastName: string;
      simplexId: string;
      year: string;
      make: string;
      vinNumber: string;
      unitNumber: string;
      dueIn: string;
      type: string;
      sortBy: string;
      sortDirection: string;
    } = {
      nextLink: '',
      status: this._status,
      ...searchVal,
      category: this.Filters.categories,
      sortBy: this.SortBy,
      sortDirection: this.SortDirection,
    };

    const response: any = await this.rootStore.apiStore.todoApi.exportTodos(
      params,
    );

    return response;
  }

  async submitTodo(url: string, payload: any) {
    const response = await this.rootStore.apiStore.todoApi.submitTodo(
      url,
      payload,
    );

    if (response.isOk()) {
      this.setIfRefresh(true);
      return { success: true };
    } else {
      return { success: false };
    }
  }

  async dismissTodo(id: string) {
    const params: {
      todoId: string;
    } = { todoId: id };
    const response = await this.rootStore.apiStore.todoApi.dismissTodo(params);
    return response;
  }

  async docuSign(todo: any) {
    const { todoType = '' } = todo;
    const slug = docSignSlugConfig[todoType] || '';
    return await this.rootStore.apiStore.todoApi.docuSign(todo, slug);
  }

  async getTodoDetailsForAnAssignment(todoId: string): Promise<any> {
    const response =
      await this.rootStore.apiStore.todoApi.getTodoDetailsForAnAssignment(
        todoId,
      );
    if (response.isOk()) {
      const { response: data } = response.value;
      this.setTrainingTodoDetails({
        [todoId]: {
          data: data || null,
          loader: false,
        },
      });
      if (
        this.SelectedTrainingAssignment &&
        this.SelectedTrainingAssignment.todoId === todoId
      ) {
        this.setSelectedTrainingAssignment(data);
      }
    }
    return response;
  }
  getTrainingTodoById = async (
    todoId: string,
  ): Promise<[data: TodoDetails | null, err: ApiError | null]> => {
    const response =
      await this.rootStore.apiStore.todoApi.getTodoDetailsForAnAssignment(
        todoId,
      );
    if (response.isOk()) {
      const { response: data } = response.value;
      return [data, null];
    }
    return [null, response.error];
  };

  async submitTodoAcknowledgmentForm(payload: any) {
    const response =
      await this.rootStore.apiStore.todoApi.submitTodoAcknowledgmentFormRequest(
        payload,
      );

    if (response.isOk()) return { success: response.value };
    else return { error: response };
  }
}
