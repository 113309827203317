import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useCustomStore } from '../../hooks';
import useStyles from '../../screens/private/ServiceRequest/TaxPermits/DotUpdate/DotUpdateStyles';
import { TodoAutoLiabilityInput, TODOOptions } from '../../types';
import { SnackBarConfig } from '../../utils/SnackBarConfig';
import { AutoLiabilityInsuranceTodoSchema } from '../../utils/ValidatorSchema';
import DialogMigrate from '../Dialog/DialogMigrate';
import Footer from '../Footer';
import { RadioInput } from '../Forms/RadioInput';
import { SelectInput } from '../Forms/selectInput';
import { ToDoFormIProps } from './types';

const AutoLiabilityActionsForm: React.FC<ToDoFormIProps> = ({
  onClose,
  todoId = '',
  policyExpirationDate,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { todoStore } = useCustomStore();
  const [valueRenew, setValueRenew] = React.useState('');
  const [valueRequest, setValueRequest] = React.useState('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [expiration, setValueExpiration] = React.useState(0);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<TodoAutoLiabilityInput>({
    criteriaMode: 'all',
    defaultValues: {
      preferredContact: '',
      preferredTime: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(AutoLiabilityInsuranceTodoSchema),
  });

  useEffect(() => {
    if (policyExpirationDate) {
      setValueExpiration(moment(policyExpirationDate).diff(new Date(), 'days'));
    }
  }, [policyExpirationDate]);

  const preferredContactOptions: TODOOptions[] = [
    { disabled: false, label: 'Phone', value: 'phone' },
    { disabled: false, label: 'Email', value: 'email' },
  ];

  const preferredTimeOptions: TODOOptions[] = [
    { disabled: false, label: 'Mornings (9am-11:45am)', value: 'morning' },
    {
      disabled: false,
      label: 'Early Afternoons (12pm-2:30pm)',
      value: 'early_afternoon',
    },
    {
      disabled: false,
      label: 'Late Afternoons (2:45pm-5pm)',
      value: 'late_afternoon',
    },
  ];

  const doSubmit = async (data: TodoAutoLiabilityInput) => {
    setIsLoading(false);
    const todoId_: string = todoId;
    const payload: { [key: string]: any } = {
      preferredContact: data.preferredContact || '',
      preferredTime: data.preferredTime || '',
      receiveQuote: data.receiveQuote === 'yes' ? true : false,
      renewPlanning: data.renewPlanning === 'yes' ? true : false,
    };
    const url = `insurance/auto-liability/${todoId_}`;
    const { success } = await todoStore.submitTodo(url, {
      ...payload,
    });
    setIsLoading(false);
    if (success) {
      enqueueSnackbar(
        'Request submitted successfully',
        SnackBarConfig('success'),
      );
      onClose({});
      return;
    }
    enqueueSnackbar('Failed to submit request. Try again', SnackBarConfig('e'));
  };

  return (
    <DialogMigrate
      aria-labelledby="equipment-select"
      className=""
      maxWidth="xs"
      open={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={() => {}}>
      <DialogTitle>
        <Typography variant="h4">
          <b>Auto Liability Insurance Update</b>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <Controller
              control={control}
              defaultValue={''}
              name="renewPlanning"
              render={({
                field: { onChange, value, name, ref },
                formState,
              }) => {
                return (
                  <RadioInput
                    refObject={ref}
                    value={value}
                    title={`Are you planning to renew your policy within the next ${expiration} days?`}
                    isRequired={true}
                    name={name}
                    onValueChange={(value) => {
                      onChange(value);
                      setValueRenew(value);
                    }}
                    errors={errors.renewPlanning?.message || ''}
                  />
                );
              }}
            />
          </Grid>
          {valueRenew === 'no' && (
            <Grid item>
              <Controller
                control={control}
                defaultValue={''}
                name="receiveQuote"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <RadioInput
                      refObject={ref}
                      value={value}
                      title={
                        'Would you like to receive a quote from a Simplex Representative?'
                      }
                      isRequired={true}
                      name={name}
                      onValueChange={(value) => {
                        onChange(value);
                        setValueRequest(value);
                      }}
                      errors={errors.receiveQuote?.message || ''}
                    />
                  );
                }}
              />
            </Grid>
          )}
          {valueRequest === 'yes' && (
            <>
              <Grid item>
                <FormLabel id="renew-radio-buttons-group">
                  Request Simplex to contact you to help renew your policy *
                </FormLabel>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Box className={classes.formLabelClass}>
                  Preferred Contact Method *
                </Box>
                <Controller
                  control={control}
                  defaultValue=""
                  name="preferredContact"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <SelectInput
                        isFormSubmittedOnce={!!formState.submitCount}
                        inputRefObj={ref}
                        errors={errors.preferredContact?.message || ''}
                        name={name}
                        size={'small'}
                        isRefreshValue={true}
                        value={value}
                        onChangeText={(val: string) => {
                          onChange(val);
                        }}
                        isEditable={false}
                        selectClassName={classes.selectClassName}
                        optionsList={preferredContactOptions.map(
                          ({ value, disabled, label }) => ({
                            disabled,
                            label,
                            value,
                          }),
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs>
                <Box className={classes.formLabelClass}>Preferred Time *</Box>
                <Controller
                  control={control}
                  defaultValue=""
                  name="preferredTime"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <SelectInput
                        isFormSubmittedOnce={!!formState.submitCount}
                        inputRefObj={ref}
                        errors={errors.preferredTime?.message || ''}
                        name={name}
                        size={'small'}
                        isRefreshValue={true}
                        value={value}
                        onChangeText={(val: string) => {
                          onChange(val);
                        }}
                        isEditable={false}
                        selectClassName={classes.selectClassName}
                        optionsList={preferredTimeOptions.map(
                          ({ value, disabled, label }) => ({
                            disabled,
                            label,
                            value,
                          }),
                        )}
                      />
                    );
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Footer
          disabled={isLoading}
          disableLoader={true}
          onSubmit={() => {
            handleSubmit(doSubmit)();
          }}
          onCancel={() => onClose({})}
        />
      </DialogActions>
    </DialogMigrate>
  );
};

export default AutoLiabilityActionsForm;
