import * as CryptoJS from 'crypto-js';
import moment from 'moment';

import { Config } from '../config';
import { PreEmploymentOptions, UnitOptions } from '../types';

export const DATE_PICKER_DATE_FORMAT = 'MM/DD/YYYY';
export const API_DATE_FORMAT = 'YYYY-MM-DD';

export const delay = (time: number) =>
  new Promise((r) =>
    setTimeout(() => {
      r(null);
    }, time),
  );

export const trimLeadingZeros = (val: string | number) => {
  return `${val}`.replace(/\b0+\B/, '');
};

export const fillingPeriod: any = [
  {
    id: 0,
    year: moment().year(),
  },
  {
    id: 1,
    year: moment().year() - 1,
  },
];

export const fileSize = (x: string) => {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0;
  let n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  // Include a decimal point and a tenths-place digit if presenting
  // Less than ten of KB or greater units
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
};

export const isJsonStringify = (val: any): boolean => {
  try {
    JSON.parse(val);
  } catch (error) {
    return false;
  }
  return true;
};
export const TransformSsn = (valueAssigned: any) => {
  if (!valueAssigned) {
    return '';
  }
  const valueAssignedLength: number = valueAssigned.length;
  const valueStr: any = valueAssigned
    .replace(/([0-9]{0,3})-?([0-9]{0,2})-?([0-9]{0,4})/gim, '***-**-$3')
    .substr(0, valueAssignedLength);
  return valueStr;
};
export const handleGetUploadName = (selectedSectionIndex: number) => {
  switch (selectedSectionIndex) {
    case 0:
      return 'Drivers Application';
    case 1:
      return 'Drivers License';
    case 2:
      return 'MVR';
    case 3:
      return 'Pre-employment Drug Test';
    case 4:
      return 'Medical Card';
    case 5:
      return 'Clearinghouse Query';
    case 6:
      return 'PSP';
    case 7:
      return 'Background Check';
    default:
      return;
    // code block
  }
};

export const userNotificationDataAccess = (
  key: string,
  userId: any = '',
  setDate?: any,
) => {
  let lastSeenOnValue: any = '';
  let lastSeenData: any = localStorage.getItem('usersNotificationData');
  if (lastSeenData) lastSeenData = JSON.parse(lastSeenData);
  let userAccessedDetails: any = {};

  if (userId && lastSeenData) {
    if (lastSeenData && lastSeenData[userId]) {
      userAccessedDetails = { ...lastSeenData[userId] };
      if (lastSeenData[userId][key]) {
        lastSeenOnValue = lastSeenData[userId][key];
      }
    }
  }

  // let anotherAccessStaus: any = 'loggedIn';

  // if (key === 'loggedIn') {
  //   anotherAccessStaus = 'lastMsgReadlog';
  // } else if (key === 'lastMsgReadlog') {
  //   anotherAccessStaus = 'loggedIn';
  // }

  if (setDate) {
    lastSeenData = {
      ...(lastSeenData || {}),
      [userId]: {
        ...userAccessedDetails,
        // [anotherAccessStaus]: new Date().toISOString(),
        [key]: new Date(setDate).toISOString(),
      },
    };
    if (!lastSeenOnValue) {
      lastSeenOnValue = lastSeenData[userId][key];
    }
  }

  const strdata: any = JSON.stringify(lastSeenData);

  localStorage.setItem('usersNotificationData', strdata);
  return lastSeenOnValue;
};

export const driverTypes: UnitOptions[] = [
  { disabled: false, title: 'House', value: 'House' },
  { disabled: false, title: 'Owner Operator', value: 'Owner Operator' },
  { disabled: false, title: 'Third Party', value: 'Third Party' },
];

const preEmploymentOptionsBase: PreEmploymentOptions[] = [
  {
    disabled: false,
    title: 'I will handle this new driver requirement.',
    value: '140320000',
  },
  {
    disabled: false,
    title: 'I want Simplex to handle this new driver requirement.',
    value: '140320001',
  },
  {
    disabled: false,
    title: 'This is already completed.',
    value: '140320002',
  },
];

export const preEmploymentMVRValues: PreEmploymentOptions[] =
  preEmploymentOptionsBase;

export const preEmploymentCHValues: PreEmploymentOptions[] =
  preEmploymentOptionsBase;

export const preEmploymentDTValues: PreEmploymentOptions[] =
  preEmploymentOptionsBase;

export const preEmploymentMCValues: PreEmploymentOptions[] =
  preEmploymentOptionsBase;

export const preEmploymentDLValues: PreEmploymentOptions[] =
  preEmploymentOptionsBase;

export const preEmploymentPSPValues: PreEmploymentOptions[] =
  preEmploymentOptionsBase;

export const preEmploymentDAValues: PreEmploymentOptions[] =
  preEmploymentOptionsBase;
export const preEmploymentBCValues: PreEmploymentOptions[] =
  preEmploymentOptionsBase;

export const driverLicenceTypes: UnitOptions[] = [
  { disabled: false, title: 'CDL A', value: 'CDL A' },
  { disabled: false, title: 'CDL B', value: 'CDL B' },
  { disabled: false, title: 'CDL C', value: 'CDL C' },
  { disabled: false, title: 'Non CDL', value: 'Non-CDL' },
  { disabled: false, title: 'Mexican', value: 'Mexican' },
];
export const drivingEndorsements = ['H', 'N', 'P', 'S', 'T', 'X'];
export const isExpired = (date: any) => {
  const comparsionDate = moment(new Date(date));
  const currentDate = moment(new Date());
  const difference = comparsionDate.diff(currentDate, 'days');
  if (difference < 0) {
    return 'Expired';
  } else if (difference < 45) {
    return 'Expiring';
  } else {
    return '';
  }
};

export const formatPhoneNumber = (phoneNumber = '') => {
  if (
    phoneNumber.includes('(') ||
    phoneNumber.includes(')') ||
    phoneNumber.includes('-')
  ) {
    return phoneNumber;
  }

  let countryCode = '';
  // format US/CAN
  if (phoneNumber.startsWith('+1')) {
    countryCode = '+1';
    phoneNumber = phoneNumber.replace(countryCode, '');
    const str1 = phoneNumber.substring(0, 3);
    const str2 = phoneNumber.substring(3, 6);
    const str3 = phoneNumber.substring(6, 10);
    return `${countryCode} (${str1}) ${str2}-${str3}`;
  }

  // format MX
  if (phoneNumber.startsWith('+52')) {
    let index = 0;
    countryCode = '+52';
    phoneNumber = phoneNumber.replace(countryCode, '');
    if (phoneNumber.length > 10) {
      index = 1;
    }
    const str1 = phoneNumber.substring(0, 2 + index);
    const str2 = phoneNumber.substring(2 + index, 6 + index);
    const str3 = phoneNumber.substring(6 + index, 10 + index);
    return `${countryCode} (${str1}) ${str2}-${str3}`;
  }

  // default
  const str1 = phoneNumber.substring(0, 3);
  const str2 = phoneNumber.substring(3, 6);
  const str3 = phoneNumber.substring(6, 10);
  return `(${str1}) ${str2}-${str3}`;
};

export const getMaxDateOfBirthForDriver = () => {
  const tempDate = moment().subtract(18, 'years');
  return tempDate.subtract(1, 'day').toDate();
};

export const refreshActivityListByStore = async (dotActivityListStore: any) => {
  dotActivityListStore.setShowLoader(true);
  dotActivityListStore.setActivityListApiReset();
  const res = await dotActivityListStore.fetchActivityLists();
  dotActivityListStore.setShowLoader(false);
  return res;
};
export const getTaxPermitsActivityList = async (activityListStore: any) => {
  activityListStore.setShowLoader(true);
  activityListStore.setActivityListApiReset();
  const res = await activityListStore.fetchActivityLists();
  activityListStore.setShowLoader(false);
  return res;
};

export const getDatesBetweenQuarters = (startQuarter = 1, endQuarter = 1) => {
  const date = new Date();
  const month = date.getMonth();
  const currrentQuarter = Math.floor((month + 3) / 3);
  const startLimit = currrentQuarter - startQuarter;

  const start = {
    month: 0,
    prevYear: false,
  };
  if (startLimit < 0) {
    start.prevYear = true;
    start.month = 12 + startLimit * 3;
  }
  const endMonth = endQuarter * 3;
  return { ...start, endMonth };
};

export const getYearsWithLimit = (
  date = new Date(),
  startLimit: number,
  endLimit: number,
) => {
  const year = date.getFullYear();
  let lowerLimit = year - startLimit;
  const upperLimit = year + endLimit;
  const years = [];
  while (lowerLimit <= upperLimit) {
    years.push({ label: lowerLimit, value: lowerLimit });
    lowerLimit++;
  }
  return years;
};

export const getYearsWithQuarters = (
  no_of_prev_quarters = 0,
  no_next_quarters = 0,
  date = new Date(),
) => {
  const month = date.getMonth();
  const currrentQuarter = Math.floor((month + 3) / 3);
  const options = [];
  const year = date.getFullYear();
  if (currrentQuarter - no_of_prev_quarters <= 0) {
    const prevYear = year - 1;
    options.push(`${prevYear} - Q4`);
    options.push(`${year} - Q${currrentQuarter}`);
  } else {
    options.push(`${year} - Q${currrentQuarter - 1}`);
    options.push(`${year} - Q${currrentQuarter}`);
  }
  if (currrentQuarter + no_next_quarters > 4) {
    const nextYear = year + 1;
    options.push(`${nextYear} - Q1`);
  } else {
    options.push(`${year} - Q${currrentQuarter + 1}`);
  }
  return options;
};

export const getPrevQuarterMonths = (date = new Date()) => {
  const month = date.getMonth();
  const currrentQuarter = Math.floor((month + 3) / 3);
  const options = [];
  const config: { [key: string]: string[] } = {
    1: ['JAN', 'FEB', 'MAR'],
    2: ['APR', 'MAY', 'JUN'],
    3: ['JULY', 'AUG', 'SEP'],
    4: ['OCT', 'NOV', 'DEC'],
  };
  const year = date.getFullYear();
  if (currrentQuarter - 1 <= 0) {
    const prevYear = year - 1;
    options.push(`${prevYear}-OCT`);
    options.push(`${prevYear}-NOV`);
    options.push(`${prevYear}-DEC`);
  } else {
    const months = config[currrentQuarter - 1];
    months.forEach((month) => options.push(`${year}-${month}`));
  }
  return options;
};
export const handleGetUpdatedProfile = (
  data: any,
  initVal: any,
  updatedVal: any,
) => {
  const updatedData: any = {};
  for (const key of Object.keys(data)) {
    if (initVal[key] !== updatedVal[key]) {
      updatedData[key] = updatedVal[key];
    }
  }
  if (initVal.middleName !== updatedVal.middleName) {
    updatedData.middleName = updatedVal.middleName;
  }
  updatedVal.phoneNumber = updatedVal.phoneNumber.replace(' ', '');
  updatedVal.phoneNumber = updatedVal.phoneNumber.replace('-', '');
  updatedVal.phoneNumber = updatedVal.phoneNumber.replace('(', '');
  updatedVal.phoneNumber = updatedVal.phoneNumber.replace(')', '');
  return updatedData;
};
export const handleGetUpdatedDriverData = (
  data: any,
  initVal: any,
  updatedVal: any,
) => {
  initVal['licenseEndorsements'].replace(',', '');
  if (initVal.phoneNumber) {
    initVal.phoneNumber = initVal.phoneNumber.replace(' ', '');
    initVal.phoneNumber = initVal.phoneNumber.replace('-', '');
    initVal.phoneNumber = initVal.phoneNumber.replace('(', '');
    initVal.phoneNumber = initVal.phoneNumber.replace(')', '');
  }
  const updatedData: any = {};
  for (const key of Object.keys(data)) {
    if (initVal[key] !== updatedVal[key]) {
      updatedData[key] = updatedVal[key];
    }
  }
  if (
    updatedVal['licenseEndorsements'].length !==
    initVal['licenseEndorsements'].length
  ) {
    delete Object.assign(updatedData, {
      ['driverLicenseEndorsements']: updatedVal['licenseEndorsements'],
    })['licenseEndorsements'];
  }
  if (initVal.middleName !== updatedVal.middleName) {
    updatedData.middleName = updatedVal.middleName;
  }
  return updatedData;
};
export const handleGetUpdatedFleetData = (
  data: any,
  updatedVal: any,
  initVal: any,
) => {
  const updatedData: any = {};
  if (updatedVal.registrationType !== initVal.registrationType) {
    updatedData.registrationType = updatedVal.registrationType;
  }
  for (const key of Object.keys(data)) {
    updatedData[key] = updatedVal[key];
  }
  return updatedData;
};
export const handleGetUpdatedData = (data: any, updatedVal: any) => {
  const updatedData: any = {};
  for (const key of Object.keys(data)) {
    updatedData[key] = updatedVal[key];
  }
  return updatedData;
};

export const postLogoutSteps = () => {
  const lastSeenData: any = localStorage.getItem('usersNotificationData');
  localStorage.clear();
  localStorage.setItem('usersNotificationData', lastSeenData || '');
};

export const objectClean = (obj: any) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && !obj[key]) {
      delete obj[key];
    }
  }
  return obj;
};

export const decryptFileUrl = (value: string | null): string => {
  const key = Config.APP_DECRYPT_SECRET;
  const decryptValue = value
    ? CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8)
    : '';
  return decryptValue;
};

/**
 * Extracts the numbers from a given string, avoiding special characters.
 * Mostly used for phone number validations
 * @returns Numbers only string
 */
export const deformatPhoneNumber = (value: any) => {
  const number = value.toString().match(/\d/g);
  if (number) {
    return number.join('');
  }
  return '';
};

/**
 * On Form Section for Driver Application, separates and capitalize
 * each word taken to return the complete name of the section
 * to be used as a flag
 * @param value Section Name
 * @returns Capitalized section name
 */
export const capitalizeConfigString = (value: string): string => {
  if (value.includes('-')) {
    const words = value.split('-');

    for (let index = 0; index < words.length; index++) {
      words[index] = words[index][0].toUpperCase() + words[index].substring(1);
    }
    return words.join('');
  } else {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
};
