import Close from '@mui/icons-material/Close';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import styled from '@mui/material/styles/styled';
import React, { useContext, useEffect, useState } from 'react';

import TodoForm from '../../../../../components/ToDoForm';
import { useCustomStore } from '../../../../../hooks';
import NewDriverCtx from '../../Dot/NewDriverSetup/NewDriverCtx';

const ActionContainer = styled(Grid)(() => ({
  border: '1px solid #E6E6E6',
  borderWidth: '0 0 1px 0',
  margin: '10px 0',
  padding: '5px 0',
}));

const Label = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 'bold',
  margin: '15px 0',
}));

const DefaultMessage = styled(Typography)(() => ({
  color: '#979598',
  fontSize: '14px',
  margin: '15px 0',
}));

const ErrorMessage = styled(Typography)(() => ({
  color: 'red',
  fontSize: '14px',
  fontWeight: 'bold',
  margin: '15px 0',
}));

const ActionButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#241A2E',
    borderColor: '#E6E6E6',
    color: '#FFF',
  },
  backgroundColor: '#FFF',
  border: '2px solid #E6E6E6',
  borderRadius: '8px',
  color: theme.palette.getContrastText('#FFF'),
  fontWeight: 'bold',
  margin: '10px 0',
}));

const OptionalText = styled('span')`
  color: #9e9e9e;
  margin-left: 10px;
`;

const ReqText = styled('span')`
  color: red;
`;

const FeedbackRow = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: 5,
}));

const CloseIcon = styled(IconButton)(() => ({
  height: 30,
  marginTop: -10,
  width: 30,
}));

type Option = {
  label: string;
  value: string;
};

type Props = {
  options: Option[];
  label: string;
  labelForNoDoc?: string;
  isOptional?: boolean;
  showError?: boolean;
  defaultValue?: any;
  fileInputRef: React.RefObject<HTMLInputElement>;
  requestType: string;
  attachment?: File;
  onClick?: () => any;
  onSelect?: (requestType: string) => any;
  onRemoveRequest?: () => any;
  value: File | any;
  error?: string;
  subFormError?: string;
  disable?: boolean;
  renderChild?: () => any;
  todoFormVariant?: TodoFormVariant;
  onSubmitModal?: (attachment: any) => void;
  isRequired?: boolean;
};

const optionsOfUpload = ['upload authorization', 'upload record'];

const UploadFiles: React.FC<Props> = ({
  options = [],
  label = '',
  labelForNoDoc = '',
  requestType = '',
  defaultValue = '',
  isOptional = false,
  showError = false,
  fileInputRef,
  attachment,
  onClick = () => {},
  onSelect = (requestType = '') => {},
  onRemoveRequest = () => {},
  value,
  error = '',
  subFormError = '',
  disable = false,
  renderChild,
  todoFormVariant,
  onSubmitModal,
  isRequired = true,
}) => {
  const { authStore }: any = useCustomStore();
  const isSHSS = authStore.getSHSSAccess();
  const [selectedType, setSelectedType] = useState('-');
  const [showTodoForm, setShowTodoForm] = useState(false);
  const driverCtx = useContext(NewDriverCtx);
  const [disableSelection, setDisableSelection] = useState<boolean>(disable);

  useEffect(() => {
    validateSHSSRequirements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateSHSSRequirements = () => {
    if (
      isSHSS &&
      (label === 'Criminal Background Check' ||
        label === 'Driver Application' ||
        label === 'PSP') &&
      isRequired
    ) {
      onSelect('upload record');
      setSelectedType('upload record');
      setDisableSelection(true);
    }
  };

  const handleUploadClick = () => {
    if (todoFormVariant) {
      setShowTodoForm(true);
      onClick();
      return;
    }
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
    onClick();
  };

  const handleClickOnClose = () => {
    setSelectedType('-');
    onRemoveRequest();
    validateSHSSRequirements();
  };

  const getFeedback = (
    attachment: File | undefined,
    requestType = '',
    shouldDisable: boolean,
    label: string,
  ) => {
    if (attachment) {
      return (
        <FeedbackRow id="feedback-row" xs={12} wrap="wrap">
          <a href="#">{attachment?.name}</a>
          <CloseIcon
            disabled={shouldDisable}
            onClick={handleClickOnClose}
            size="small">
            <Close />
          </CloseIcon>
        </FeedbackRow>
      );
    }
    if (!optionsOfUpload.includes(requestType)) {
      return (
        <FeedbackRow id="feedback-row" xs={12} wrap="wrap">
          <Typography paragraph>
            {label === 'Criminal Background Check'
              ? 'Request Simplex to run Background Check'
              : 'Sending Request to driver for signature'}
          </Typography>
          <CloseIcon
            disabled={shouldDisable}
            onClick={handleClickOnClose}
            size="small">
            <Close />
          </CloseIcon>
        </FeedbackRow>
      );
    }
  };

  return (
    <ActionContainer>
      <Label>
        {label}
        {!defaultValue &&
          !isOptional &&
          (showError ? (
            <ReqText>
              <sup>*</sup>
            </ReqText>
          ) : (
            <sup>*</sup>
          ))}
        {isOptional && <OptionalText>(Optional)</OptionalText>}
      </Label>
      {(!value || value === '-' || optionsOfUpload.includes(value)) && (
        <>
          {options.length ? (
            <FormControl disabled={disableSelection ?? disable} fullWidth>
              <Select
                labelId="attachment-type-select-label"
                id="attachment-type-select"
                value={selectedType}
                onChange={(ev: any) => {
                  setSelectedType(ev.target.value);
                  onSelect(ev.target.value);
                }}>
                <MenuItem selected key={options.length} value="-">
                  Select
                </MenuItem>
                {options.map((option: any, index: number) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
          {optionsOfUpload.includes(selectedType) && (
            <ActionButton disabled={disable} onClick={handleUploadClick}>
              Upload
            </ActionButton>
          )}
        </>
      )}
      {defaultValue && requestType === '-' && (
        <>
          <DefaultMessage paragraph>
            {!isSHSS
              ? labelForNoDoc ||
                'If you don’t upload this document, it will be sent to Driver to Complete.'
              : ''}
          </DefaultMessage>
          <ActionButton disabled={disable} onClick={handleUploadClick}>
            Upload
          </ActionButton>
        </>
      )}
      {requestType !== '-' &&
        getFeedback(attachment, requestType, disable, label)}
      {error && <ErrorMessage paragraph> {error} </ErrorMessage>}
      {renderChild && renderChild()}
      {subFormError && <ErrorMessage paragraph> {subFormError} </ErrorMessage>}
      <Label>{showError && !error && <ReqText>Required</ReqText>}</Label>
      {showTodoForm && todoFormVariant && (
        <TodoForm
          onClose={({ attachment, payload }) => {
            if (driverCtx && todoFormVariant && attachment && onSubmitModal) {
              if (payload.fieldsChanged) delete payload.fieldsChanged;
              if (todoFormVariant === 'clearinghouse') {
                payload.clearinghouseQuerydateRan = payload.dateRan;
                delete payload.dateRan;
              }
              if (todoFormVariant === 'mvr') {
                payload.mvrDateRan = payload.dateRan;
                delete payload.dateRan;
              }
              driverCtx.updateDocsFormData(payload, todoFormVariant);
              onSubmitModal(attachment);
            }
            if (
              driverCtx &&
              todoFormVariant &&
              !attachment &&
              isSHSS &&
              onSubmitModal &&
              payload
            ) {
              // if (payload.fieldsChanged) delete payload.fieldsChanged;
              if (todoFormVariant === 'clearinghouse') {
                payload.clearinghouseQuerydateRan = payload.dateRan;
                delete payload.dateRan;
              }
              if (todoFormVariant === 'mvr') {
                payload.mvrDateRan = payload.dateRan;
                delete payload.dateRan;
              }
              driverCtx.updateDocsFormData(payload, todoFormVariant);
              onSubmitModal(attachment);
            }
            setShowTodoForm(false);
          }}
          preventSubmit
          variant={todoFormVariant}
          newDriver={
            (todoFormVariant && driverCtx?.data && driverCtx?.data) || null
          }
        />
      )}
    </ActionContainer>
  );
};

export default UploadFiles;
