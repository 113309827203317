import { yupResolver } from '@hookform/resolvers/yup';
import { Theme, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { toJS } from 'mobx';
import moment from 'moment';
import MuiPhoneNumber from 'mui-phone-number';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import FileUpload from '../../../components/FileUpload';
import { SelectDatePicker } from '../../../components/Forms/datePicker';
import { InputText } from '../../../components/Forms/InputText';
import { SelectInput } from '../../../components/Forms/selectInput';
import { useCustomStore, UseFocus, useMountEffect } from '../../../hooks';
import { UpdateDriverSetupInputs } from '../../../types';
import {
  driverLicenceTypes,
  driverTypes,
  drivingEndorsements,
  getMaxDateOfBirthForDriver,
  handleGetUpdatedDriverData,
  TransformSsn,
} from '../../../utils/helper';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import { UpdateDriverSetupSchema } from '../../../utils/ValidatorSchema';
import useStyles from '../ServiceRequest/TaxPermits/DotUpdate/DotUpdateStyles';

type Props = {
  driverList: any;
  cancel?: any;
  hanldeGetUsersProfilelInfo?: any;
  handleShowMedicalCard: (show: boolean) => void;
};

const ProfileUpdateForm: React.FC<Props> = ({
  driverList,
  cancel,
  hanldeGetUsersProfilelInfo,
  handleShowMedicalCard,
}) => {
  const { authStore, userStore, driverListStore }: any = useCustomStore();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [licenseFile, setLicenseFile] = useState<any>();
  const [licenseFileError, setLicenseFileError] = useState<boolean>(false);
  const [DLendorsements, setDLEndorsements] = useState(true);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [input1Ref, setInput1Focus] = UseFocus();
  const classes = useStyles();

  const theme = useTheme();
  const [isOpen, setOpen] = useState(false);
  const [ownerssnnew, setownerssnnew] = useState<{
    valArr: string[];
    valStr: string;
  }>({
    valArr: [],
    valStr: '',
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
  };

  function getStyles(
    name: string,
    licenseEndorsements: readonly string[],
    theme: Theme,
  ) {
    return {
      fontWeight:
        licenseEndorsements?.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const checkStateExists = (value: string) => {
    if (!value) {
      return '';
    }
    let valueDefine: any = '';
    if (toJS(authStore.statesListOption).find((e: any) => e.code === value)) {
      valueDefine = value;
    } else {
      valueDefine = '';
    }
    return valueDefine;
  };

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
    watch,
  } = useForm<UpdateDriverSetupInputs>({
    criteriaMode: 'all',
    defaultValues: {
      address: '',
      city: '',
      dob: '',
      driverType: '',
      email: '',
      firstName: '',
      hireDate: '',
      lastName: '',
      licenseEndorsements: [],
      licenseExpirationDate: '',
      licenseNumber: '',
      licenseState: '',
      licenseType: '',
      middleName: '',
      phoneNumber: '',
      ssn: '',
      state: '',
      terminalId: '',
      zip: '',
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(UpdateDriverSetupSchema),
  });
  const driverLicenseType = watch('licenseType');
  const submitDriverDetails = async (data: any) => {
    try {
      const updatedData = handleGetUpdatedDriverData(
        dirtyFields,
        driverList,
        data,
      );
      const licenseFile = data?.licenseFile;
      const res = await userStore.updateProfileInfo({
        ...updatedData,
        licenseFile,
      });
      setBtnDisable(false);
      setLoading(false);
      if (res && res.value) {
        enqueueSnackbar(
          'Information updated successfully',
          SnackBarConfig('s'),
        );
        cancel();
        hanldeGetUsersProfilelInfo();
      }
      if (res && res.error) {
        enqueueSnackbar('Something went wrong', SnackBarConfig('e'));
      }
    } catch (err) {
      return;
    }
  };
  const submitDriverLicenseFile = async (data: any) => {
    const formData = new FormData();
    const fileData: any[] = [
      {
        driver: {
          contactId: driverList?.id,
        },

        fileType: 'Drivers License',
        service: 'driver profile',
        type: 'driver',
      },
    ];
    formData.append('files', licenseFile);
    formData.append('data', JSON.stringify(fileData));
    const fileUploadResponse = await driverListStore.uploadFileForSignature(
      formData,
    );
    if (fileUploadResponse.isErr()) {
      setBtnDisable(false);
      setLoading(false);
      enqueueSnackbar('File upload failed', SnackBarConfig('e'));
    } else {
      const licenseDetails = {
        licenseFile: {
          fileName: fileUploadResponse.value[0][0].fileName,
          fileSize: fileUploadResponse.value[0][0].fileSize,
          fileType: fileUploadResponse.value[0][0].fileType,
          location: fileUploadResponse.value[0][0].location,
        },
      };
      submitDriverDetails({ ...data, ...licenseDetails });
    }
  };
  const onSubmit = (data: any) => {
    if (watch('licenseType') !== 'Mexican' && watch('licenseState') === 'MEX') {
      return;
    }
    setBtnDisable(true);
    setLoading(true);
    data.dob = moment(new Date(data.dob)).format('YYYY-MM-DD');
    data.licenseExpirationDate = moment(
      new Date(data.licenseExpirationDate),
    ).format('YYYY-MM-DD');
    data.licenseEndorsements = DLendorsements ? data.licenseEndorsements : [];
    if (watch('licenseType') === 'Mexican') {
      data.licenseEndorsements = [];
    }
    if (driverList?.licenseOnFile && !licenseFile) submitDriverDetails(data);
    else if (!driverList?.licenseOnFile && !licenseFile) {
      setLicenseFileError(true);
      setLoading(false);
      setBtnDisable(false);
    } else submitDriverLicenseFile(data);
  };
  const handleReset = () => {
    return cancel();
  };
  useEffect(() => {
    const authState = authStore.statesListOption;
    const stateData = toJS(authState);
    if (stateData && stateData.length) {
      setState(stateData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.statesListOption]);

  const handleGetDriverList = useCallback(
    (driverList) => {
      if (driverList) {
        const {
          address,
          city,
          dob,
          licenseEndorsements,
          driverType,
          email,
          firstName,
          hireDate,
          lastName,
          licenseExpirationDate,
          licenseNumber,
          licenseState,
          licenseType,
          middleName,
          phoneNumber,
          ssn,
          state,
          // terminalId,
          zip,
        } = driverList;
        setValue('address', address);
        setValue('city', city);
        setValue('dob', dob);
        setValue(
          'licenseEndorsements',
          licenseEndorsements ? licenseEndorsements.split(',') : [],
        );
        setValue('driverType', driverType);
        setValue('email', email);
        setValue('firstName', firstName);
        setValue('hireDate', hireDate);
        setValue('lastName', lastName);
        setValue('licenseExpirationDate', licenseExpirationDate);
        setValue('licenseNumber', licenseNumber);
        setValue('licenseState', licenseState);
        setValue('licenseType', licenseType);
        setValue('middleName', middleName);
        setValue('phoneNumber', phoneNumber);
        setValue('ssn', ssn);
        setValue('state', state);
        setValue('zip', zip);
        setownerssnnew((pre) => ({
          ...pre,
          valArr: ssn ? ssn.split('') : [],
          valStr: ssn,
        }));
      }
    },
    [setValue],
  );
  useEffect(() => {
    handleGetDriverList(driverList);
  }, [handleGetDriverList, driverList]);

  useMountEffect(setInput1Focus);
  useEffect(() => {
    if (driverLicenseType === 'Non-CDL') {
      setDLEndorsements(false);
      handleShowMedicalCard(false);
    } else {
      setDLEndorsements(true);
      handleShowMedicalCard(true);
    }
    // eslint-disable-next-line
  }, [driverLicenseType]);

  return (
    <form
      style={{
        width: '100%',
      }}
      id="driver-update-form"
      onSubmit={handleSubmit(onSubmit)}>
      <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
        <Grid item lg={8} md={12}>
          <Card>
            <CardHeader
              title={<h3>Profile Information</h3>}
              action={
                <Grid>
                  <Button
                    onClick={() => handleReset()}
                    variant="text"
                    className={classes.primaryText}>
                    Cancel
                  </Button>

                  <Button
                    form="driver-update-form"
                    type="submit"
                    disabled={
                      btnDisable ||
                      (authStore.getTokenDetail &&
                        authStore.getTokenDetail?.adminId)
                    }
                    variant="text"
                    className={classes.primaryText}>
                    Save
                  </Button>
                </Grid>
              }
              style={{ fontWeight: 'bolder' }}
            />
            {loading ? (
              <div className={classes.loader}>
                <CircularProgress size={30} sx={{ color: '#DEC330' }} />
              </div>
            ) : (
              <CardContent>
                <Grid container spacing={2}>
                  <>
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>
                        Driver First Name *
                      </Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="firstName"
                        render={({
                          field: { onChange, value, name, ref, onBlur },
                          formState,
                        }) => {
                          return (
                            <InputText
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={input1Ref}
                              errors={errors.firstName?.message || ''}
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeText={(val) => {
                                onChange(val);
                              }}
                              onBlurText={onBlur}
                              isEditable={false}
                              className={classes.selectClassName}
                              type={'text'}
                              variant="standard"
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>
                        Driver Middle Name
                      </Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="middleName"
                        render={({
                          field: { onChange, value, name, ref, onBlur },
                          formState,
                        }) => {
                          return (
                            <InputText
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              errors={errors?.middleName?.message || ''}
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeText={(val) => {
                                onChange(val);
                              }}
                              onBlurText={onBlur}
                              isEditable={false}
                              className={classes.selectClassName}
                              type={'text'}
                              variant="standard"
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>
                        Driver Last Name *
                      </Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="lastName"
                        render={({
                          field: { onChange, value, name, ref, onBlur },
                          formState,
                        }) => {
                          return (
                            <InputText
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              errors={errors?.lastName?.message || ''}
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeText={(val) => {
                                onChange(val);
                              }}
                              onBlurText={onBlur}
                              isEditable={false}
                              className={classes.selectClassName}
                              type={'text'}
                              variant="standard"
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>
                        Phone Number *
                      </Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="phoneNumber"
                        render={({
                          field: { onChange, value, name, ref: phNumberRef },
                          formState,
                        }) => {
                          return (
                            <>
                              <MuiPhoneNumber
                                inputClass={classes.selectClassName}
                                ref={(ref: any) => {
                                  phNumberRef = ref;
                                }}
                                countryCodeEditable={false}
                                autoFormat={true}
                                defaultCountry={'us'}
                                onlyCountries={['us', 'mx', 'ca']}
                                disableCountryCode={false}
                                disableAreaCodes={true}
                                value={value ? value.toString() : undefined}
                                name={name}
                                onChange={(value) => {
                                  onChange(value.toString());
                                }}
                                inputProps={{
                                  maxLength:
                                    value && value.startsWith('+52')
                                      ? 13
                                      : undefined,
                                }}
                              />
                              <span className="custom-error">
                                {errors.phoneNumber?.message || ''}
                              </span>
                            </>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>
                        Email Address *
                      </Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="email"
                        render={({
                          field: { onChange, value, name, ref, onBlur },
                          formState,
                        }) => {
                          return (
                            <InputText
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              errors={errors?.email?.message || ''}
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeText={(val) => {
                                onChange(val);
                              }}
                              onBlurText={onBlur}
                              isEditable={false}
                              className={classes.selectClassName}
                              type={'text'}
                              variant="standard"
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>DOB *</Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="dob"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <SelectDatePicker
                              maxDate={getMaxDateOfBirthForDriver()}
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              errors={errors?.dob?.message || ''}
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeDate={(e) => {
                                if (e) {
                                  onChange(e);
                                }
                                // validation();
                              }}
                              disabled={false}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>SSN *</Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="ssn"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <InputText
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              autoComplete={'off'}
                              errors={errors.ssn?.message || ''}
                              name={name}
                              isRefreshValue={true}
                              value={TransformSsn(value)}
                              onChangeText={(
                                val: any,
                                selectionStart: number,
                              ) => {
                                if (/[^\d*-]/gim.test(val)) {
                                  return;
                                }
                                if (!val) {
                                  onChange('');
                                  setownerssnnew({
                                    valArr: [],
                                    valStr: '',
                                  });
                                  return;
                                }

                                function patternDesider(length: number) {
                                  let regexstr: any = '';
                                  if (length < 3) {
                                    regexstr = '$1';
                                  } else if (length === 3) {
                                    regexstr = '$1-';
                                  } else if (length < 6) {
                                    regexstr = '$1-$2';
                                  } else if (length === 6) {
                                    regexstr = '$1-$2-';
                                  } else if (length > 6) {
                                    regexstr = '$1-$2-$3';
                                  }
                                  return regexstr;
                                }

                                let PatternStringDesiderValue: any =
                                  patternDesider(val.length);

                                let { valArr, valStr } = ownerssnnew;
                                const accessindex: number = selectionStart - 1;
                                if (valStr.length < val.length) {
                                  if (selectionStart === valStr.length + 1) {
                                    if (selectionStart >= 12) {
                                      return;
                                    }
                                    valArr[accessindex] = val[accessindex];
                                    for (let i = 0; i < valArr.length; i++) {
                                      if (!valArr[i]) {
                                        valArr[i] = '-';
                                      }
                                    }
                                  } else {
                                    const cpyValArr: any = [...valArr];
                                    cpyValArr.splice(
                                      accessindex,
                                      0,
                                      val[accessindex],
                                    );
                                    let cpyValArrss: any = cpyValArr
                                      .join('')
                                      .replace(/[-]/gim, '');
                                    // .replace(/[0]+$/gmi,'')

                                    cpyValArrss = cpyValArrss.replace(
                                      /(.{0,3})(.{0,2})(.{0,4})/,
                                      PatternStringDesiderValue,
                                    );

                                    // const cpyValArrssLength: number = cpyValArrss.length
                                    cpyValArrss = cpyValArrss.split('');
                                    // .concat(sliceValues.slice((cpyValArrssLength + 1), 11));

                                    valArr = cpyValArrss.slice(0, 11);
                                  }
                                }

                                if (val.length < valStr.length) {
                                  let cpyvalArr = [...valArr];
                                  const valueStrLength: number = valStr.length;
                                  if (valueStrLength - 1 === val.length) {
                                    if (valStr[selectionStart] === '-') {
                                      selectionStart -= 1;
                                    }
                                    cpyvalArr.splice(selectionStart, 1);
                                    cpyvalArr = cpyvalArr
                                      .join('')
                                      .replace(/[-]/gim, '')
                                      .replace(
                                        /(.{0,3})(.{0,2})(.{0,4})/,
                                        patternDesider(val.length - 1),
                                      )
                                      .split('');

                                    PatternStringDesiderValue = patternDesider(
                                      val.length - 1,
                                    );

                                    valArr = cpyvalArr;
                                  } else {
                                    let cpyValArr: any = [...valArr];
                                    const deletedSequence: any =
                                      valStr.length - val.length;
                                    cpyValArr.splice(
                                      selectionStart,
                                      deletedSequence,
                                    );

                                    // const valLengthNum:number = val.length;
                                    const valLengthNum: number =
                                      cpyValArr.length;
                                    let addStepIndex: any = 0;

                                    if (
                                      valLengthNum === 5 &&
                                      !cpyValArr.find((e: any) => e === '-')
                                    ) {
                                      addStepIndex = 1;
                                    }

                                    cpyValArr = cpyValArr
                                      .join('')
                                      .replace(/[-]/gim, '')
                                      .replace(
                                        /(.{0,3})(.{0,2})(.{0,4})/,
                                        patternDesider(
                                          valLengthNum + Number(addStepIndex),
                                        ),
                                      )
                                      .split('');

                                    PatternStringDesiderValue = patternDesider(
                                      valLengthNum + Number(addStepIndex),
                                    );

                                    valArr = cpyValArr;
                                  }
                                }

                                let valueStr: any = valArr
                                  .join('')
                                  .replace(/[-]/gim, '');
                                // .replace(/[0]+$/gmi,'')

                                valueStr = valueStr.replace(
                                  /(.{0,3})(.{0,2})(.{0,4})/,
                                  PatternStringDesiderValue,
                                );

                                valStr = valueStr;
                                setownerssnnew({
                                  valArr,
                                  valStr,
                                });

                                onChange(valueStr);
                                // dotUpdateStore.setDotUpdateDetail(
                                //   'ownerssn',
                                //   valueStr.replace(/[-]/gim, ''),
                                // );
                              }}
                              className={classes.selectClassName}
                              type={'text'}
                              variant="standard"
                              isEditable={false}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item lg={3} md={12} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>Address *</Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="address"
                        render={({
                          field: { onChange, value, name, ref, onBlur },
                          formState,
                        }) => {
                          return (
                            <InputText
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              errors={errors.address?.message || ''}
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeText={(val) => {
                                onChange(val);
                                // annualReportStore.setAnnualDetail('registeredAgent', val);
                              }}
                              onBlurText={onBlur}
                              isEditable={false}
                              className={classes.selectClassName}
                              type={'text'}
                              variant="standard"
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item lg={3} md={12} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>City *</Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="city"
                        render={({
                          field: { onChange, value, name, ref, onBlur },
                          formState,
                        }) => {
                          return (
                            <InputText
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              errors={errors.city?.message || ''}
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeText={(val) => {
                                onChange(val);
                                // annualReportStore.setAnnualDetail('registeredAgent', val);
                              }}
                              onBlurText={onBlur}
                              isEditable={false}
                              className={classes.selectClassName}
                              type={'text'}
                              variant="standard"
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>State *</Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="state"
                        render={({
                          field: { onChange, value, name, ref, onBlur },
                          formState,
                        }) => {
                          return (
                            <FormControl>
                              <SelectInput
                                isFormSubmittedOnce={!!formState.submitCount}
                                inputRefObj={ref}
                                errors={errors?.state?.message || ''}
                                name={name}
                                isRefreshValue={true}
                                value={checkStateExists(value)}
                                onBlurFn={onBlur}
                                onChangeText={(val: any) => {
                                  onChange(val);
                                  // onChangeFn(addressName, 'state', val);
                                }}
                                size={'small'}
                                selectClassName={classes.selectClassName}
                                placeHolder={'Select'}
                                optionsList={state.map(
                                  (stateval: any, index: number) => ({
                                    label: stateval.fullName,
                                    value: stateval.code,
                                  }),
                                )}
                                // {
                                //   <>
                                //     <option
                                //       value=""
                                //       className={classes.optionsClassName}>
                                //       Select
                                //     </option>
                                //     {state.map(
                                //       (stateval: any, index: number) => (
                                //         <option
                                //           key={index}
                                //           value={stateval.code}
                                //           className={classes.optionsClassName}>
                                //           {stateval.fullName}
                                //         </option>
                                //       ),
                                //     )}
                                //   </>
                                // }
                              />
                            </FormControl>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item lg={3} md={12} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>Zip *</Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="zip"
                        render={({
                          field: { onChange, value, name, ref, onBlur },
                          formState,
                        }) => {
                          return (
                            <InputText
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              errors={errors.zip?.message || ''}
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeText={(val) => {
                                onChange(val);
                                // annualReportStore.setAnnualDetail('registeredAgent', val);
                              }}
                              onBlurText={onBlur}
                              isEditable={false}
                              className={classes.selectClassName}
                              type={'text'}
                              variant="standard"
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item lg={3} md={12} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>Hire Date</Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="hireDate"
                        render={({
                          field: { onChange, value, name, ref, onBlur },
                          formState,
                        }) => {
                          return (
                            <SelectDatePicker
                              maxDate={new Date()}
                              inputRefObj={ref}
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeDate={(e) => {
                                if (e) {
                                  onChange(moment(e).format('MM/DD/YYYY'));
                                }
                              }}
                              disabled={true}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>
                        Driver&apos;s License Type *
                      </Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="licenseType"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <SelectInput
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              errors={errors.licenseType?.message || ''}
                              name={name}
                              size={'small'}
                              isRefreshValue={true}
                              value={value}
                              onChangeText={(val: string) => {
                                onChange(val);
                              }}
                              isEditable={false}
                              selectClassName={classes.selectClassName}
                              optionsList={driverLicenceTypes.map(
                                ({ value, disabled, title }) => ({
                                  disabled: disabled,
                                  label: title,
                                  value: value,
                                }),
                              )}
                              // {
                              //   <>
                              //     {driverLicenceTypes.map(
                              //       ({ value, disabled, title }, index) => (
                              //         <option
                              //           value={value}
                              //           key={index}
                              //           className={classes.optionsClassName}
                              //           disabled={disabled}>
                              //           {title}
                              //         </option>
                              //       ),
                              //     )}
                              //   </>
                              // }
                            />
                          );
                        }}
                      />
                    </Grid>
                    {DLendorsements && (
                      <Grid item md={6} sm={12} xs={12}>
                        <Box className={classes.formLabelClass}>
                          Driver&apos;s License Endorsement
                        </Box>

                        <Controller
                          control={control}
                          defaultValue={[]}
                          name="licenseEndorsements"
                          render={({
                            field: { value, onBlur, name, ...otherOptions },
                            formState,
                          }) => {
                            return (
                              <>
                                <Select
                                  multiple
                                  displayEmpty
                                  onClose={(event) => {
                                    // @ts-ignore
                                    onBlur(event);
                                    setOpen(false);
                                  }}
                                  onOpen={() => setOpen(true)}
                                  open={isOpen}
                                  value={value}
                                  input={<OutlinedInput />}
                                  renderValue={(selected) => {
                                    if (
                                      selected.length === 0 ||
                                      watch('licenseType') === 'Mexican'
                                    ) {
                                      return <em>None</em>;
                                    }

                                    return selected
                                      ?.map((option) => option)
                                      .join(', ');
                                  }}
                                  MenuProps={MenuProps}
                                  inputProps={{ 'aria-label': 'Without label' }}
                                  {...otherOptions}>
                                  <MenuItem disabled value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {watch('licenseType') !== 'Mexican' &&
                                    drivingEndorsements.map((name) => (
                                      <MenuItem
                                        key={name}
                                        value={name}
                                        style={getStyles(name, value, theme)}>
                                        <Checkbox
                                          checked={value.indexOf(name) > -1}
                                        />
                                        <ListItemText primary={name} />
                                      </MenuItem>
                                    ))}
                                </Select>
                                {/* @ts-ignore  */}
                                {errors[`${name}`]?.message && (
                                  <p className="error-msg">
                                    {/* @ts-ignore */}
                                    {errors?.licenseEndorsements?.message || ''}
                                  </p>
                                )}
                              </>
                            );
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>
                        Driver&apos;s License # *
                      </Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="licenseNumber"
                        render={({
                          field: { onChange, value, name, ref, onBlur },
                          formState,
                        }) => {
                          return (
                            <InputText
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              errors={errors.licenseNumber?.message || ''}
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeText={(val) => {
                                onChange(val);
                                // annualReportStore.setAnnualDetail('registeredAgent', val);
                              }}
                              onBlurText={onBlur}
                              isEditable={false}
                              className={classes.selectClassName}
                              type={'text'}
                              variant="standard"
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>
                        Driver&apos;s License State *
                      </Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="licenseState"
                        render={({
                          field: { onChange, value, name, ref, onBlur },
                          formState,
                        }) => {
                          return (
                            <FormControl>
                              <SelectInput
                                isFormSubmittedOnce={!!formState.submitCount}
                                inputRefObj={ref}
                                errors={errors?.licenseState?.message || ''}
                                name={name}
                                isRefreshValue={true}
                                // @ts-ignore
                                value={checkStateExists(value)}
                                onBlurFn={onBlur}
                                onChangeText={(val: any) => {
                                  onChange(val);
                                  // onChangeFn(addressName, 'state', val);
                                }}
                                size={'small'}
                                selectClassName={classes.selectClassName}
                                placeHolder={'Select'}
                                optionsList={state.map((stateval: any) => ({
                                  label: stateval.fullName,
                                  value: stateval.code,
                                }))}
                              />
                            </FormControl>
                          );
                        }}
                      />
                    </Grid>

                    <Grid item md={6} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>
                        Driver&apos;s License Expiration *
                      </Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="licenseExpirationDate"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <SelectDatePicker
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              errors={
                                errors?.licenseExpirationDate?.message || ''
                              }
                              name={name}
                              isRefreshValue={true}
                              value={value}
                              onChangeDate={(e) => {
                                onChange(e);
                                // validation();
                              }}
                              disabled={false}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Box className={classes.formLabelClass}>
                        Driver Type *
                      </Box>
                      <Controller
                        control={control}
                        defaultValue=""
                        name="driverType"
                        render={({
                          field: { onChange, value, name, ref },
                          formState,
                        }) => {
                          return (
                            <SelectInput
                              isFormSubmittedOnce={!!formState.submitCount}
                              inputRefObj={ref}
                              errors={errors.driverType?.message || ''}
                              name={name}
                              size={'small'}
                              isRefreshValue={true}
                              value={value}
                              onChangeText={(val: string) => {
                                onChange(val);
                              }}
                              isEditable={false}
                              selectClassName={classes.selectClassName}
                              optionsList={driverTypes.map(
                                ({ value, disabled, title }, index) => ({
                                  disabled: disabled,
                                  label: title,
                                  value: value,
                                }),
                              )}
                              // {
                              //   <>
                              //     {driverTypes.map(
                              //       ({ value, disabled, title }, index) => (
                              //         <option
                              //           value={value}
                              //           key={index}
                              //           className={classes.optionsClassName}
                              //           disabled={disabled}>
                              //           {title}
                              //         </option>
                              //       ),
                              //     )}
                              //   </>
                              // }
                            />
                          );
                        }}
                      />
                    </Grid>

                    <FileUpload
                      fileLabel={
                        driverList?.licenseOnFile
                          ? 'Update Driver License'
                          : 'Upload Driver License *'
                      }
                      setFile={setLicenseFile}
                      file={licenseFile}
                    />

                    {licenseFileError && !licenseFile ? (
                      <Typography
                        component={'div'}
                        style={{ marginLeft: '20px' }}>
                        <p className="error-msg">Required</p>
                      </Typography>
                    ) : null}
                    <div
                      style={{
                        borderBottom: '1px solid #E8E8E8',
                        margin: '20px 0',
                      }}
                    />
                  </>
                </Grid>
              </CardContent>
            )}
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProfileUpdateForm;
