import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
  useTheme,
} from '@mui/material';
import { toJS } from 'mobx';
import moment from 'moment';
import MuiPhoneNumber from 'mui-phone-number';
import React from 'react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { SelectDatePicker } from '../../../../../components/Forms/datePicker';
import { InputText } from '../../../../../components/Forms/InputText';
import { SelectInput } from '../../../../../components/Forms/selectInput';
import { useCustomStore } from '../../../../../hooks';
import { NewDriverSetupInputs } from '../../../../../types';
import { licenseTypesWithNoEndorsements } from '../../../../../utils/config';
import { processEndorsementsSelect } from '../../../../../utils/form';
import {
  driverLicenceTypes,
  driverTypes,
  drivingEndorsements,
  getMaxDateOfBirthForDriver,
  preEmploymentCHValues,
  preEmploymentDAValues,
  preEmploymentDLValues,
  preEmploymentDTValues,
  preEmploymentMCValues,
  preEmploymentMVRValues,
  preEmploymentPSPValues,
  TransformSsn,
} from '../../../../../utils/helper';
import { NewDriverSetupSchema } from '../../../../../utils/ValidatorSchema';
import TaxPermitsButtons from '../../components/ButtonsTaxPermits/ButtonTaxPermits';
import useStyles from '../../TaxPermits/DotUpdate/DotUpdateStyles';

type DriverKey = keyof NewDriverSetupInputs;

type Props = {
  terminals?: Terminal[];
  onSubmit: (value: any) => any;
  newDriver: NewDriverSetupInputs | any;
};

const DriverSetupForm: React.FC<Props> = ({
  onSubmit: handleFormSubmit,
  terminals = [],
  newDriver,
}) => {
  const { authStore, userStore }: any = useCustomStore();
  const [state, setState] = useState([]);
  const [debugCount, setDebugCount] = useState(0);
  const classes = useStyles();
  const theme = useTheme();
  const [DLendorsements, setDLEndorsements] = useState(true);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [isOpen, setOpen] = useState(false);
  const [driverApplicationVal, setDriverApplicationVal] =
    useState<boolean>(false);
  const [ownerssnnew, setownerssnnew] = useState<{
    valArr: string[];
    valStr: string;
  }>({
    valArr: [],
    valStr: '',
  });

  const isSHSS = authStore.getSHSSAccess();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        minWidth: 360,
        width: 360,
      },
    },
  };

  function getStyles(
    name: string,
    personName: readonly string[],
    theme: Theme,
  ) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const mexicanState = toJS(authStore.statesListOption).find(
    (e: any) => e.code === 'MEX',
  );

  const checkStateExists = (value: string) => {
    if (!value) {
      return '';
    }
    let valueDefine: any = '';
    if (toJS(authStore.statesListOption).find((e: any) => e.code === value)) {
      valueDefine = value;
    } else {
      valueDefine = '';
    }
    return valueDefine;
  };

  const defaultValues = {
    address: '',
    city: '',
    dob: '',
    driverLicenseEndorsements: [],
    driverType: '',
    email: '',
    firstName: '',
    isSHSSAccount: isSHSS,
    lastName: '',
    licenseExpirationDate: '',
    licenseNumber: '',
    licenseStateId: '',
    licenseType: '',
    middleName: '',
    phoneNumber: '',
    preEmploymentMVR: '',
    ssn: '',
    state: '',
    terminalId: '',
    zip: '',
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm<NewDriverSetupInputs>({
    criteriaMode: 'all',
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(NewDriverSetupSchema),
  });
  const driverLicenseType = watch('licenseType');

  const onSubmit = (data: NewDriverSetupInputs) => {
    data.dob = moment(data.dob).format('YYYY-MM-DD');
    data.licenseExpirationDate = moment(data.licenseExpirationDate).format(
      'YYYY-MM-DD',
    );

    if (
      data.driverLicenseEndorsements?.includes('None') ||
      (data.licenseType &&
        licenseTypesWithNoEndorsements.includes(data.licenseType))
    ) {
      data.driverLicenseEndorsements = [];
    }

    // SHSS Accounts only
    if (isSHSS) {
      data.shssOptions = {
        ApplicationDate:
          moment(data.applicationDate).format('YYYY-MM-DD') || undefined,
        DriverApplication: Number(data.driverApplication) || undefined,
        HireDate: moment(data.dahire).format('YYYY-MM-DD') || undefined,
        PreEmploymentBackgroundCheck:
          Number(data.preBackgroundCheck) || undefined,
        PreEmploymentClearingHouse: Number(data.preEmploymentCH) || undefined,
        PreEmploymentDriverLicense: Number(data.preEmploymentDL) || undefined,
        PreEmploymentDrugTest: Number(data.preEmploymentDT) || undefined,
        PreEmploymentMVR: Number(data.preEmploymentMVR) || undefined,
        PreEmploymentMedicalCard: Number(data.preEmploymentMC) || undefined,
        PreEmploymentPSP: Number(data.preEmploymentPSP) || undefined,
        isSHSS: isSHSS,
      };
    }
    handleFormSubmit(data);
  };

  const resetDriverSetupForm = () => {
    reset();
    setBtnDisable(false);

    /* NOTE: This is only for development purposes, not actual production code
             Delete this if the development is complete.
    */
    if (userStore.isTestAccount()) {
      setDebugCount(debugCount + 1);
      /* Clicking 5 times will prepopulate the data */
      if (debugCount === 4) {
        setValue('address', '5080 Str');
        setValue('city', 'Addison');
        setValue('dob', '2004-01-01');
        setValue('driverLicenseEndorsements', []);
        setValue('driverType', 'House');
        setValue('preEmploymentMVR', '');
        setValue('email', 'house@j.co');
        setValue('firstName', 'Hiro');
        setValue('lastName', 'Test');
        setValue('licenseExpirationDate', '2022-10-10');
        setValue('licenseNumber', 'PK012302');
        setValue('licenseStateId', 'f25ddef5-a08d-eb11-a9f6-005056a93d01');
        setValue('licenseType', 'CDL A');
        setValue('middleName', '');
        setValue('phoneNumber', '1231231231');
        setValue('ssn', '123-12-1234');
        setValue('state', 'TX');
        setValue('terminalId', '');
        setValue('zip', '75001');
        setDebugCount(0);
      }
    }
  };

  useEffect(() => {
    const authState = authStore.statesListOption;
    const stateData = toJS(authState);
    if (stateData && stateData.length) {
      setState(stateData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.statesListOption]);

  useEffect(() => {
    if (newDriver && typeof newDriver === 'object') {
      const _keys = Object.keys(newDriver) as Array<DriverKey>;
      _keys.forEach((_key: DriverKey) => setValue(_key, newDriver[_key]));
    }
  }, [newDriver, setValue]);

  useEffect(() => {
    if (driverLicenseType === 'Non-CDL') setDLEndorsements(false);
    else setDLEndorsements(true);

    if (driverLicenseType === 'Mexican' && state.length) {
      const selectedState: any = state.find(
        (el: any) => el.fullName === 'Mexico',
      ) || { id: '' };
      setValue('licenseStateId', selectedState?.id || '');
    }
  }, [driverLicenseType, setValue, state]);

  useEffect(() => {
    if (driverLicenseType === 'Mexican') {
      setValue('driverLicenseEndorsements', []);
    }
  }, [driverLicenseType, setValue]);

  return (
    <>
      <form
        style={{
          width: '100%',
        }}
        id="driver-setup-form"
        onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <>
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>Driver First Name *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="firstName"
                render={({
                  field: { onChange, value, name, ref, onBlur },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.firstName?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={(val) => {
                        onChange(val);
                      }}
                      onBlurText={onBlur}
                      isEditable={false}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>Driver Middle Name </Box>
              <Controller
                control={control}
                defaultValue=""
                name="middleName"
                render={({
                  field: { onChange, value, name, ref, onBlur },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors?.middleName?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={(val) => {
                        onChange(val);
                      }}
                      onBlurText={onBlur}
                      isEditable={false}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>Driver Last Name *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="lastName"
                render={({
                  field: { onChange, value, name, ref, onBlur },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors?.lastName?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={(val) => {
                        onChange(val);
                      }}
                      onBlurText={onBlur}
                      isEditable={false}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>Phone Number *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="phoneNumber"
                render={({
                  field: { onChange, value, name, ref: phNumberRef },
                  formState,
                }) => {
                  return (
                    <>
                      <MuiPhoneNumber
                        inputClass={classes.selectClassName}
                        ref={(ref: any) => {
                          phNumberRef = ref;
                        }}
                        countryCodeEditable={false}
                        autoFormat={true}
                        defaultCountry={'us'}
                        onlyCountries={['us', 'mx', 'ca']}
                        disableCountryCode={false}
                        disableAreaCodes={true}
                        value={value ? value.toString() : undefined}
                        name={name}
                        onChange={(value) => {
                          onChange(value.toString());
                        }}
                        inputProps={{
                          maxLength:
                            value && value.startsWith('+52') ? 13 : undefined,
                        }}
                      />
                      <span className="custom-error">
                        {errors.phoneNumber?.message || ''}
                      </span>
                    </>
                  );
                }}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>Email Address *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="email"
                render={({
                  field: { onChange, value, name, ref, onBlur },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors?.email?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={(val) => {
                        onChange(val);
                      }}
                      onBlurText={onBlur}
                      isEditable={false}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>DOB *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="dob"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectDatePicker
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors?.dob?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeDate={(e) => {
                        onChange(e);
                      }}
                      maxDate={getMaxDateOfBirthForDriver()}
                      disabled={false}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>SSN *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="ssn"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      autoComplete={'off'}
                      errors={errors.ssn?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={TransformSsn(value)}
                      onChangeText={(val: any, selectionStart: number) => {
                        if (/[^\d*-]/gim.test(val)) {
                          return;
                        }
                        if (!val) {
                          onChange('');
                          setownerssnnew({
                            valArr: [],
                            valStr: '',
                          });
                          return;
                        }

                        function patternDesider(length: number) {
                          let regexstr: any = '';
                          if (length < 3) {
                            regexstr = '$1';
                          } else if (length === 3) {
                            regexstr = '$1-';
                          } else if (length < 6) {
                            regexstr = '$1-$2';
                          } else if (length === 6) {
                            regexstr = '$1-$2-';
                          } else if (length > 6) {
                            regexstr = '$1-$2-$3';
                          }
                          return regexstr;
                        }

                        let PatternStringDesiderValue: any = patternDesider(
                          val.length,
                        );

                        let { valArr, valStr } = ownerssnnew;
                        const accessindex: number = selectionStart - 1;
                        if (valStr.length < val.length) {
                          if (selectionStart === valStr.length + 1) {
                            if (selectionStart >= 12) {
                              return;
                            }
                            valArr[accessindex] = val[accessindex];
                            for (let i = 0; i < valArr.length; i++) {
                              if (!valArr[i]) {
                                valArr[i] = '-';
                              }
                            }
                          } else {
                            const cpyValArr: any = [...valArr];
                            cpyValArr.splice(accessindex, 0, val[accessindex]);
                            let cpyValArrss: any = cpyValArr
                              .join('')
                              .replace(/[-]/gim, '');
                            cpyValArrss = cpyValArrss.replace(
                              /(.{0,3})(.{0,2})(.{0,4})/,
                              PatternStringDesiderValue,
                            );
                            cpyValArrss = cpyValArrss.split('');
                            valArr = cpyValArrss.slice(0, 11);
                          }
                        }

                        if (val.length < valStr.length) {
                          let cpyvalArr = [...valArr];
                          const valueStrLength: number = valStr.length;
                          if (valueStrLength - 1 === val.length) {
                            if (valStr[selectionStart] === '-') {
                              selectionStart -= 1;
                            }
                            cpyvalArr.splice(selectionStart, 1);
                            cpyvalArr = cpyvalArr
                              .join('')
                              .replace(/[-]/gim, '')
                              .replace(
                                /(.{0,3})(.{0,2})(.{0,4})/,
                                patternDesider(val.length - 1),
                              )
                              .split('');

                            PatternStringDesiderValue = patternDesider(
                              val.length - 1,
                            );

                            valArr = cpyvalArr;
                          } else {
                            let cpyValArr: any = [...valArr];
                            const deletedSequence: any =
                              valStr.length - val.length;
                            cpyValArr.splice(selectionStart, deletedSequence);

                            const valLengthNum: number = cpyValArr.length;
                            let addStepIndex: any = 0;

                            if (
                              valLengthNum === 5 &&
                              !cpyValArr.find((e: any) => e === '-')
                            ) {
                              addStepIndex = 1;
                            }

                            cpyValArr = cpyValArr
                              .join('')
                              .replace(/[-]/gim, '')
                              .replace(
                                /(.{0,3})(.{0,2})(.{0,4})/,
                                patternDesider(
                                  valLengthNum + Number(addStepIndex),
                                ),
                              )
                              .split('');

                            PatternStringDesiderValue = patternDesider(
                              valLengthNum + Number(addStepIndex),
                            );

                            valArr = cpyValArr;
                          }
                        }

                        let valueStr: any = valArr
                          .join('')
                          .replace(/[-]/gim, '');

                        valueStr = valueStr.replace(
                          /(.{0,3})(.{0,2})(.{0,4})/,
                          PatternStringDesiderValue,
                        );

                        valStr = valueStr;
                        setownerssnnew({
                          valArr,
                          valStr,
                        });

                        onChange(valueStr);
                      }}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                      isEditable={false}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item lg={3} md={12} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>Address *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="address"
                render={({
                  field: { onChange, value, name, ref, onBlur },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.address?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={(val) => {
                        onChange(val);
                      }}
                      onBlurText={onBlur}
                      isEditable={false}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item lg={3} md={12} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>City *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="city"
                render={({
                  field: { onChange, value, name, ref, onBlur },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.city?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={(val) => {
                        onChange(val);
                      }}
                      onBlurText={onBlur}
                      isEditable={false}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>State *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="state"
                render={({
                  field: { onChange, value, name, ref, onBlur },
                  formState,
                }) => {
                  return (
                    <FormControl>
                      <SelectInput
                        isFormSubmittedOnce={!!formState.submitCount}
                        inputRefObj={ref}
                        errors={errors?.state?.message || ''}
                        name={name}
                        isRefreshValue={true}
                        value={checkStateExists(value)}
                        onBlurFn={onBlur}
                        onChangeText={(val: any) => {
                          onChange(val);
                        }}
                        size={'small'}
                        selectClassName={classes.selectClassName}
                        placeHolder={'Select'}
                        optionsList={state.map((stateval: any) => ({
                          label: stateval.fullName,
                          value: stateval.code,
                        }))}
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid item lg={3} md={12} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>Zip *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="zip"
                render={({
                  field: { onChange, value, name, ref, onBlur },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.zip?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={(val) => {
                        onChange(val);
                      }}
                      onBlurText={onBlur}
                      isEditable={false}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>
                Driver&apos;s License Type *
              </Box>
              <Controller
                control={control}
                defaultValue=""
                name="licenseType"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.licenseType?.message || ''}
                      name={name}
                      size={'small'}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={(val: string) => {
                        onChange(val);
                      }}
                      isEditable={false}
                      selectClassName={classes.selectClassName}
                      optionsList={driverLicenceTypes.map(
                        ({ value, title }) => ({
                          label: title,
                          value: value,
                        }),
                      )}
                    />
                  );
                }}
              />
            </Grid>
            {DLendorsements && (
              <Grid item md={6} sm={12} xs={12}>
                <Box className={classes.formLabelClass}>
                  Driver&apos;s License Endorsement{' '}
                  {driverLicenseType !== 'Mexican' && <sup>*</sup>}
                </Box>
                <Controller
                  control={control}
                  defaultValue={[]}
                  name="driverLicenseEndorsements"
                  render={({
                    field: { value, onBlur, name, onChange, ...otherOptions },
                    formState,
                  }) => {
                    return (
                      <>
                        <Select
                          onChange={(event) => {
                            const {
                              target: { value = [] },
                            } = event;
                            const newEvent = processEndorsementsSelect(
                              value as string[],
                            );
                            onChange(newEvent);
                          }}
                          multiple
                          displayEmpty
                          disabled={driverLicenseType === 'Mexican'}
                          onClose={(event) => {
                            // @ts-ignore
                            onBlur(event);
                            setOpen(false);
                          }}
                          onOpen={() => setOpen(true)}
                          open={isOpen}
                          value={value}
                          input={<OutlinedInput />}
                          renderValue={(selected) => {
                            return selected?.map((option) => option).join(', ');
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ 'aria-label': 'Without label' }}
                          {...otherOptions}>
                          <MenuItem
                            key={-1}
                            value="None"
                            style={getStyles(name, value, theme)}>
                            <Checkbox checked={value.indexOf('None') > -1} />
                            None
                          </MenuItem>
                          {drivingEndorsements.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                              style={getStyles(name, value, theme)}>
                              <Checkbox checked={value.indexOf(name) > -1} />
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors?.driverLicenseEndorsements && (
                          <FormHelperText error>Required</FormHelperText>
                        )}
                      </>
                    );
                  }}
                />
              </Grid>
            )}
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>
                Driver&apos;s License # *
              </Box>
              <Controller
                control={control}
                defaultValue=""
                name="licenseNumber"
                render={({
                  field: { onChange, value, name, ref, onBlur },
                  formState,
                }) => {
                  return (
                    <InputText
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.licenseNumber?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={(val) => {
                        onChange(val);
                      }}
                      onBlurText={onBlur}
                      isEditable={false}
                      className={classes.selectClassName}
                      type={'text'}
                      variant="standard"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>
                Driver&apos;s License State *
              </Box>
              <Controller
                control={control}
                defaultValue=""
                name="licenseStateId"
                render={({
                  field: { onChange, value, name, ref, onBlur },
                  formState,
                }) => {
                  return (
                    <FormControl>
                      <SelectInput
                        isFormSubmittedOnce={!!formState.submitCount}
                        inputRefObj={ref}
                        isEditable={driverLicenseType === 'Mexican'}
                        errors={errors?.licenseStateId?.message || ''}
                        name={name}
                        isRefreshValue={true}
                        value={value}
                        onBlurFn={onBlur}
                        onChangeText={(val: any) => {
                          onChange(val);
                        }}
                        size={'small'}
                        selectClassName={classes.selectClassName}
                        placeHolder={'Select'}
                        optionsList={
                          watch('licenseType') === 'Mexican'
                            ? [mexicanState].map((val) => ({
                                label: val.fullName,
                                value: val.id,
                              }))
                            : state.map((stateval: any) => ({
                                label: stateval.fullName,
                                value: stateval.id,
                              }))
                        }
                      />
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>
                Driver&apos;s License Expiration *
              </Box>
              <Controller
                control={control}
                defaultValue=""
                name="licenseExpirationDate"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectDatePicker
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors?.licenseExpirationDate?.message || ''}
                      name={name}
                      isRefreshValue={true}
                      value={value}
                      onChangeDate={(e) => {
                        onChange(e);
                        // validation();
                      }}
                      disabled={false}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Box className={classes.formLabelClass}>Driver Type *</Box>
              <Controller
                control={control}
                defaultValue=""
                name="driverType"
                render={({
                  field: { onChange, value, name, ref },
                  formState,
                }) => {
                  return (
                    <SelectInput
                      isFormSubmittedOnce={!!formState.submitCount}
                      inputRefObj={ref}
                      errors={errors.driverType?.message || ''}
                      name={name}
                      size={'small'}
                      isRefreshValue={true}
                      value={value}
                      onChangeText={(val: string) => {
                        onChange(val);
                      }}
                      isEditable={false}
                      selectClassName={classes.selectClassName}
                      optionsList={driverTypes.map(
                        ({ value, disabled, title }) => ({
                          disabled,
                          label: title,
                          value,
                        }),
                      )}
                    />
                  );
                }}
              />
            </Grid>
            {isSHSS && (
              <>
                <Grid item md={6} sm={12} xs={12}>
                  <Box className={classes.formLabelClass}>
                    Driver Application *
                  </Box>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="driverApplication"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <SelectInput
                          isFormSubmittedOnce={!!formState.submitCount}
                          inputRefObj={ref}
                          errors={errors.driverApplication?.message || ''}
                          name={name}
                          size={'small'}
                          isRefreshValue={true}
                          value={value}
                          onChangeText={(val: string) => {
                            onChange(val);
                            if (val === '140320002')
                              setDriverApplicationVal(true);
                            else setDriverApplicationVal(false);
                          }}
                          isEditable={false}
                          selectClassName={classes.selectClassName}
                          optionsList={preEmploymentDAValues.map(
                            ({ value, disabled, title }) => ({
                              disabled,
                              label: title,
                              value,
                            }),
                          )}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Box className={classes.formLabelClass}>
                    {driverApplicationVal ? 'Hire Date *' : 'Hire Date'}
                  </Box>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="dahire"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <SelectDatePicker
                          isFormSubmittedOnce={!!formState.submitCount}
                          inputRefObj={ref}
                          errors={errors.dahire?.message || ''}
                          name={name}
                          isRefreshValue={true}
                          value={value}
                          onChangeDate={(e) => {
                            onChange(e);
                            // validation();
                          }}
                          disabled={false}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Box className={classes.formLabelClass}>
                    {driverApplicationVal
                      ? 'Application Date *'
                      : 'Application Date'}
                  </Box>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="applicationDate"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <SelectDatePicker
                          isFormSubmittedOnce={!!formState.submitCount}
                          inputRefObj={ref}
                          errors={errors.applicationDate?.message || ''}
                          name={name}
                          isRefreshValue={true}
                          value={value}
                          onChangeDate={(e) => {
                            onChange(e);
                            // validation();
                          }}
                          disabled={false}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Box className={classes.formLabelClass}>
                    Pre-Employment MVR *
                  </Box>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="preEmploymentMVR"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <SelectInput
                          isFormSubmittedOnce={!!formState.submitCount}
                          inputRefObj={ref}
                          errors={errors.preEmploymentMVR?.message || ''}
                          name={name}
                          size={'small'}
                          isRefreshValue={true}
                          value={value}
                          onChangeText={(val: string) => {
                            onChange(val);
                          }}
                          isEditable={false}
                          selectClassName={classes.selectClassName}
                          optionsList={preEmploymentMVRValues.map(
                            ({ value, disabled, title }) => ({
                              disabled,
                              label: title,
                              value,
                            }),
                          )}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Box className={classes.formLabelClass}>
                    Pre-Employment Clearing House *
                  </Box>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="preEmploymentCH"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <SelectInput
                          isFormSubmittedOnce={!!formState.submitCount}
                          inputRefObj={ref}
                          errors={errors.preEmploymentCH?.message || ''}
                          name={name}
                          size={'small'}
                          isRefreshValue={true}
                          value={value}
                          onChangeText={(val: string) => {
                            onChange(val);
                          }}
                          isEditable={false}
                          selectClassName={classes.selectClassName}
                          optionsList={preEmploymentCHValues.map(
                            ({ value, disabled, title }) => ({
                              disabled,
                              label: title,
                              value,
                            }),
                          )}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Box className={classes.formLabelClass}>
                    Pre-Employment Drug Test *
                  </Box>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="preEmploymentDT"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <SelectInput
                          isFormSubmittedOnce={!!formState.submitCount}
                          inputRefObj={ref}
                          errors={errors.preEmploymentDT?.message || ''}
                          name={name}
                          size={'small'}
                          isRefreshValue={true}
                          value={value}
                          onChangeText={(val: string) => {
                            onChange(val);
                          }}
                          isEditable={false}
                          selectClassName={classes.selectClassName}
                          optionsList={preEmploymentDTValues.map(
                            ({ value, disabled, title }) => ({
                              disabled,
                              label: title,
                              value,
                            }),
                          )}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Box className={classes.formLabelClass}>
                    Pre-Employment Medical Card *
                  </Box>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="preEmploymentMC"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <SelectInput
                          isFormSubmittedOnce={!!formState.submitCount}
                          inputRefObj={ref}
                          errors={errors.preEmploymentMC?.message || ''}
                          name={name}
                          size={'small'}
                          isRefreshValue={true}
                          value={value}
                          onChangeText={(val: string) => {
                            onChange(val);
                          }}
                          isEditable={false}
                          selectClassName={classes.selectClassName}
                          optionsList={preEmploymentMCValues.map(
                            ({ value, disabled, title }) => ({
                              disabled,
                              label: title,
                              value,
                            }),
                          )}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Box className={classes.formLabelClass}>
                    Pre-Employment Driver License *
                  </Box>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="preEmploymentDL"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <SelectInput
                          isFormSubmittedOnce={!!formState.submitCount}
                          inputRefObj={ref}
                          errors={errors.preEmploymentDL?.message || ''}
                          name={name}
                          size={'small'}
                          isRefreshValue={true}
                          value={value}
                          onChangeText={(val: string) => {
                            onChange(val);
                          }}
                          isEditable={false}
                          selectClassName={classes.selectClassName}
                          optionsList={preEmploymentDLValues.map(
                            ({ value, disabled, title }) => ({
                              disabled,
                              label: title,
                              value,
                            }),
                          )}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Box className={classes.formLabelClass}>
                    Pre-Employment PSP *
                  </Box>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="preEmploymentPSP"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <SelectInput
                          isFormSubmittedOnce={!!formState.submitCount}
                          inputRefObj={ref}
                          errors={errors.preEmploymentPSP?.message || ''}
                          name={name}
                          size={'small'}
                          isRefreshValue={true}
                          value={value}
                          onChangeText={(val: string) => {
                            onChange(val);
                          }}
                          isEditable={false}
                          selectClassName={classes.selectClassName}
                          optionsList={preEmploymentPSPValues.map(
                            ({ value, disabled, title }) => ({
                              disabled,
                              label: title,
                              value,
                            }),
                          )}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Box className={classes.formLabelClass}>
                    Pre-Employment Background Check *
                  </Box>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="preBackgroundCheck"
                    render={({
                      field: { onChange, value, name, ref },
                      formState,
                    }) => {
                      return (
                        <SelectInput
                          isFormSubmittedOnce={!!formState.submitCount}
                          inputRefObj={ref}
                          errors={errors.preBackgroundCheck?.message || ''}
                          name={name}
                          size={'small'}
                          isRefreshValue={true}
                          value={value}
                          onChangeText={(val: string) => {
                            onChange(val);
                          }}
                          isEditable={false}
                          selectClassName={classes.selectClassName}
                          optionsList={preEmploymentDAValues.map(
                            ({ value, disabled, title }) => ({
                              disabled,
                              label: title,
                              value,
                            }),
                          )}
                        />
                      );
                    }}
                  />
                </Grid>
              </>
            )}
            <div
              style={{ borderBottom: '1px solid #E8E8E8', margin: '20px 0' }}
            />
            {!!terminals.length && (
              <Grid item md={12} sm={12} xs={12}>
                <Box className={classes.formLabelClass}>Select Terminal</Box>
                <Controller
                  control={control}
                  defaultValue={null}
                  name="terminalId"
                  render={({
                    field: { onChange, value, name, ref },
                    formState,
                  }) => {
                    return (
                      <SelectInput
                        isFormSubmittedOnce={!!formState.submitCount}
                        inputRefObj={ref}
                        errors={errors.terminalId?.message || ''}
                        name={name}
                        size={'small'}
                        isRefreshValue={true}
                        value={value}
                        onChangeText={(val: string) => {
                          onChange(val);
                        }}
                        isEditable={false}
                        selectClassName={classes.selectClassName}
                        optionsList={terminals.map(({ id, name }) => ({
                          label: name,
                          value: id,
                        }))}
                      />
                    );
                  }}
                />
              </Grid>
            )}
          </>
        </Grid>
        <Divider className={classes.divider} />
      </form>
      <TaxPermitsButtons
        formId={'driver-setup-form'}
        disabled={btnDisable}
        formReset={resetDriverSetupForm}
        submitButtonName="Next"
      />
    </>
  );
};

export default DriverSetupForm;
