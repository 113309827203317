import axios, { AxiosError } from 'axios';
import { makeAutoObservable } from 'mobx';
import { err, ok, Result } from 'neverthrow';

import { AuthErrorType } from '../../backendError.types';
import { Config } from '../../config/Constants';
import { ApiError } from '../../types';
import { RootStore } from '..';
import { AccidentsApiStore } from './accidents.api';
import { ActivityListApiStore } from './activityList.api';
import { AddToFleetApiStore } from './addToFleet.api';
import { AdminNotificationApiStore } from './adminNotification.api';
import { AdminUsersApiStore } from './adminusers.api';
import { AnnualReportApiStore } from './annualreport.api';
import { AuthApiStore } from './auth.api';
import { DashboardApiStore } from './dashboard.api';
import { DataQChallengeApiStore } from './dataQchallenge.api';
import { DBsApiStore } from './db.api';
import { DocumentsApiStore } from './documents.api';
import { DotActivityListApiStore } from './dotActivityList.api';
import { DotUpdateApiStore } from './dotUpdate.api';
import { DriverListApiStore } from './driverList.api';
import { DriverOwnerNotificationApiStore } from './driverOwnerNotification.api';
import { DriverApiStore } from './drivers.api';
import { EquipmentApiStore } from './equipment.api';
import { EquipmentInspectionApiStore } from './equipmentInspection.api';
import { FileApiStore } from './file.api';
import { FleetApiStore } from './fleets.api';
import { FTQuarterFillingApiStore } from './ftQuarterFilling.api';
import { IFTAApiStore } from './ifta.api';
import { InspectionsAndViolationsApiStore } from './inspectionsAndViolations.api';
import { IrpServiceApiStore } from './irpService.api';
import { MvrApiStore } from './mvr.api';
import { NotificationApiStore } from './notification.api';
import { PrimaryDriversApiStore } from './primaryDrivers.api';
import { RoadTaxesApiStore } from './roadTaxes.api';
import { SettingsApiStore } from './setings.api';
import { StatePermitsApiStore } from './statePermits.api';
import { TerminateEquipmentApiStore } from './terminateEquipment.api';
import { TodoApiStore } from './todos.api';
import { TrainingApiStore } from './training.api';
import { TrendsAPIStore } from './trends.api';
import { UcrApiStore } from './ucr.api';
import { UserApiStore } from './user.api';
import { ViolationsApiStore } from './violations.api';

export class ApiStore {
  accidentsApi: AccidentsApiStore;
  dbsApi: DBsApiStore;
  authApi: AuthApiStore;
  userApi: UserApiStore;
  equipmentApi: EquipmentApiStore;
  dashboardApiStore: DashboardApiStore;
  statePermitsApiStore: StatePermitsApiStore;
  roadTaxesApiStore: RoadTaxesApiStore;
  ucrApi: UcrApiStore;
  annualReportApi: AnnualReportApiStore;
  activityListApiStore: ActivityListApiStore;
  dotUpdateApiStore: DotUpdateApiStore;
  ftQuarterFillingApiStore: FTQuarterFillingApiStore;
  equipmentInspectionApiStore: EquipmentInspectionApiStore;
  terminateEquipmentApiStore: TerminateEquipmentApiStore;
  dataQChallengeApiStore: DataQChallengeApiStore;
  iftaApiStore: IFTAApiStore;
  fileApi: FileApiStore;
  mvrApi: MvrApiStore;
  irpApiStore: IrpServiceApiStore;
  dotActivityListApiStore: DotActivityListApiStore;
  driverListApiStore: DriverListApiStore;
  driverApi: DriverApiStore;
  todoApi: TodoApiStore;
  fleetApi: FleetApiStore;
  adminUsersApi: AdminUsersApiStore;
  addToFleetApiStore: AddToFleetApiStore;
  adminNotificationApiStore: AdminNotificationApiStore;
  driverOwnerNotificationApiStore: DriverOwnerNotificationApiStore;
  notificationApiStore: NotificationApiStore;
  settingsApiStore: SettingsApiStore;
  documentsApiStore: DocumentsApiStore;
  inspectionsAndViolationsApi: InspectionsAndViolationsApiStore;
  violationsApi: ViolationsApiStore;
  primaryDriversApi: PrimaryDriversApiStore;
  trendsApi: TrendsAPIStore;
  trainingApiStore: TrainingApiStore;

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this, {
      accidentsApi: false,
      activityListApiStore: false,
      adminNotificationApiStore: false,
      adminUsersApi: false,
      annualReportApi: false,
      authApi: false,
      dashboardApiStore: false,
      dataQChallengeApiStore: false,
      dbsApi: false,
      documentsApiStore: false,
      dotActivityListApiStore: false,
      dotUpdateApiStore: false,
      driverApi: false,
      driverListApiStore: false,
      driverOwnerNotificationApiStore: false,
      equipmentApi: false,
      fileApi: false,
      fleetApi: false,
      ftQuarterFillingApiStore: false,
      iftaApiStore: false,
      inspectionsAndViolationsApi: false,
      irpApiStore: false,
      mvrApi: false,
      notificationApiStore: false,
      primaryDriversApi: false,
      roadTaxesApiStore: false,
      settingsApiStore: false,
      statePermitsApiStore: false,
      todoApi: false,
      trainingApiStore: false,
      trendsApi: false,
      ucrApi: false,
      userApi: false,
      violationsApi: false,
    });

    this.initialize();
    this.accidentsApi = new AccidentsApiStore(rootStore);
    this.dbsApi = new DBsApiStore(rootStore);
    this.dashboardApiStore = new DashboardApiStore(rootStore);
    this.authApi = new AuthApiStore(rootStore);
    this.userApi = new UserApiStore(rootStore);
    this.driverOwnerNotificationApiStore = new DriverOwnerNotificationApiStore(
      rootStore,
    );
    this.adminNotificationApiStore = new AdminNotificationApiStore(rootStore);
    this.equipmentApi = new EquipmentApiStore(rootStore);
    this.statePermitsApiStore = new StatePermitsApiStore(rootStore);
    this.roadTaxesApiStore = new RoadTaxesApiStore(rootStore);
    this.activityListApiStore = new ActivityListApiStore(rootStore);
    this.dotUpdateApiStore = new DotUpdateApiStore(rootStore);
    this.fileApi = new FileApiStore(rootStore);
    this.irpApiStore = new IrpServiceApiStore(rootStore);
    this.ucrApi = new UcrApiStore(rootStore);
    this.annualReportApi = new AnnualReportApiStore(rootStore);
    this.ftQuarterFillingApiStore = new FTQuarterFillingApiStore(rootStore);
    this.dataQChallengeApiStore = new DataQChallengeApiStore(rootStore);
    this.iftaApiStore = new IFTAApiStore(rootStore);
    this.mvrApi = new MvrApiStore(rootStore);
    this.dotActivityListApiStore = new DotActivityListApiStore(rootStore);
    this.driverListApiStore = new DriverListApiStore(rootStore);
    this.equipmentInspectionApiStore = new EquipmentInspectionApiStore(
      rootStore,
    );
    this.terminateEquipmentApiStore = new TerminateEquipmentApiStore(rootStore);
    this.driverApi = new DriverApiStore(rootStore);
    this.todoApi = new TodoApiStore(rootStore);
    this.fleetApi = new FleetApiStore(rootStore);
    this.adminUsersApi = new AdminUsersApiStore(rootStore);
    this.addToFleetApiStore = new AddToFleetApiStore(rootStore);
    this.notificationApiStore = new NotificationApiStore(rootStore);
    this.settingsApiStore = new SettingsApiStore(rootStore);
    this.documentsApiStore = new DocumentsApiStore(rootStore);
    this.inspectionsAndViolationsApi = new InspectionsAndViolationsApiStore(
      rootStore,
    );
    this.violationsApi = new ViolationsApiStore(rootStore);
    this.primaryDriversApi = new PrimaryDriversApiStore(rootStore);
    this.trendsApi = new TrendsAPIStore(rootStore);
    this.trainingApiStore = new TrainingApiStore(rootStore);
    this.initialize();
  }

  initialize = () => {
    this.rootStore.axiosInstance = axios.create({
      baseURL: Config.API_URL,
    });

    this.rootStore.axiosInstance.interceptors.request.use((config: any) => {
      const accessToken = this.rootStore.authStore.tokens?.accessToken;
      const authHeders = accessToken
        ? { Authorization: `Bearer ${accessToken}` }
        : { 'x-simplex-client-api-key': '' };

      config.headers = {
        ...config.headers,
        ...authHeders,
      };

      return config;
    });

    this.rootStore.axiosInstance.interceptors.response.use(
      undefined,
      (error: AxiosError) => {
        const { response } = error;
        throw new ApiError({
          actions: response?.data?.actions,
          message:
            response?.data?.message ?? 'Please check your internet connection!',
          status: response?.status,
          type: response?.data?.type,
        });
      },
    );
  };

  exchangeTokenApiCall(refreshToken: string | null) {
    return this.rootStore.axiosInstance({
      data: {
        refreshToken,
      },
      method: 'POST',
      url: 'tokens/exchange',
    });
  }

  async call<T = any>(options: {
    url: string;
    method: 'POST' | 'GET' | 'DELETE' | 'PUT' | 'PATCH';
    headers?: any;
    data?: any;
    params?: any;
    responseType?: any;
  }): Promise<Result<T, ApiError>> {
    const initialAuthCount = this.rootStore.authStore.authCount;
    try {
      const { data } = await this.rootStore.axiosInstance(options);
      return ok(data as T);
    } catch (e) {
      const er = e as ApiError;
      if (er.actions.exchange) {
        try {
          // await exchange the token only once.
          initialAuthCount === this.rootStore.authStore.authCount &&
            (await this.rootStore.authStore.exchangeOnlyOnce());
          // on success - call the api again.
          return await this.call<T>(options);
        } catch (e) {
          //  logout only once
          await this.rootStore.authStore.logout();
        }
      } else if (er.type === AuthErrorType.NoAccessToUser) {
        return err(e as ApiError);
      } else if (er.actions.authFailed) {
        await this.rootStore.authStore.logout();
      }
      return err(e as ApiError);
    }
  }
}
