import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Checkbox } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Switch from 'react-switch';

import SimplexGrid from '../../../components/SimplexGrid';
import { useCustomStore } from '../../../hooks';
import { SnackBarConfig } from '../../../utils/SnackBarConfig';
import Accident from './Accident';
import MatchDriversAndVins from './MatchDriversAndVinsDialog';
import MatchDriversAndVinsMultple from './MatchDriversAndVinsMultipleDialog';
import MoreDetails from './MoreDetails';
import { useStyles } from './styles';

const allHeaderKeys = [
  {
    label: 'Accident Date',
    name: 'accidentDate',
  },
  {
    label: 'State',
    name: 'state',
  },
  {
    label: 'Accident Number',
    name: 'accidentNumber',
  },
  {
    label: 'Fatalities',
    name: 'fatalities',
  },
  {
    label: 'Injuries',
    name: 'injuries',
  },
  {
    label: 'Tow-Away',
    name: 'towAway',
  },
  {
    label: 'HM-Released',
    name: 'hmReleased',
  },
  {
    label: 'Citation Issued',
    name: 'citationIssued',
  },
  {
    label: 'Not Preventable Flag',
    name: 'notPreventableFlag',
  },
  {
    label: 'Severity Weight',
    name: 'severityWeight',
  },
  {
    label: 'Time Weight',
    name: 'timeWeight',
  },
  {
    label: 'Time Severity Weight',
    name: 'timeSeverityWeight',
  },
  {
    label: 'Primary Driver',
    name: 'primaryDriver',
  },
  {
    label: 'Power Unit VIN',
    name: 'powerUnitVin',
  },
];

const manageHeaderKeys = [
  {
    label: 'Accident Date',
    name: 'accidentDate',
  },
  {
    label: 'State',
    name: 'state',
  },
  {
    label: 'Accident Number',
    name: 'accidentNumber',
  },
  {
    label: 'Primary Driver',
    name: 'primaryDriver',
  },
  {
    label: 'Power Unit VIN',
    name: 'powerUnitVin',
  },
];

const applyAllHeaderSpacing = (el: any, index: number) => {
  const spacing = [
    100, 80, 150, 100, 100, 100, 100, 100, 150, 100, 100, 100, 200, 200, 50,
  ];
  return {
    ...el,
    spacing: spacing[index],
  };
};

const applyManageHeaderSpacing = (el: any, index: number) => {
  const spacing = [200, 150, 300, 350, 350, 300, 300];
  return {
    ...el,
    spacing: spacing[index],
  };
};

const allHeaderKeysWithSpacing = allHeaderKeys.map(applyAllHeaderSpacing);
const manageHeaderKeysWithSpacing = manageHeaderKeys.map(
  applyManageHeaderSpacing,
);
const Accidents: React.FC = () => {
  const { accidentsStore: store, userStore, authStore } = useCustomStore();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { DOTPin, FMCSA } = userStore;
  const { NavbarAccess: access } = authStore;
  const showBanner = () => {
    const isDotMissingOrInvalid =
      (DOTPin && DOTPin?.status !== 'Valid') || false;
    const isFMCSAMissingOrInvalid =
      (FMCSA && FMCSA?.status !== 'Valid') || false;
    const showDOTBanner =
      (access?.dashboard?.dotPin && isDotMissingOrInvalid) || false;
    const showFMCSABanner =
      (access?.dashboard?.fmcsa && isFMCSAMissingOrInvalid) || false;
    return showDOTBanner || showFMCSABanner;
  };
  const managePageHeaderTopSspacing = showBanner() ? '120px' : '80px';
  const [prevSortBy, setPrevSortBy] = useState('');
  const [showCompleted, setShowCompleted] = useState(false);
  const [isHeaderRowChecked, setIsHeaderRowChecked] = useState(false);

  const [selectedAccidentRecord, setSelectedAccidentRecord]: any = useState([]);
  const [
    showSingleAccidentDriversAndVinsDialogue,
    setShowSingleAccidentDriversAndVinsDialogue,
  ]: any = useState(false);
  const [
    showMultipleAccidentDriversAndVinsDialogue,
    setShowMultipleAccidentDriversAndVinsDialogue,
  ]: any = useState(false);
  const [selectedMatchId, setSelectedMatchId] = useState('');

  const getList = useCallback(async () => {
    store.setShowLoader(true);
    const manageType = showCompleted ? 'manage-completed' : 'manage-all';
    store.setManageType(manageType);
    await store.getList();
    store.setShowLoader(false);
  }, [store, showCompleted]);

  useEffect(() => {
    (async () => {
      store.setShowLoader(true);
      const manageType = showCompleted ? 'manage-completed' : 'manage-all';
      store.setManageType(manageType);
      await store.getList();
      store.setShowLoader(false);
    })();
    return () => {
      store.resetParams();
    };
  }, [store, store.Status, showCompleted]);

  const getNextList = useCallback(() => {
    (async () => {
      store.setShowLoader(true);
      const manageType = showCompleted ? 'manage-completed' : 'manage-all';
      store.setManageType(manageType);
      await store.getList();
      store.setShowLoader(false);
    })();
  }, [store, showCompleted]);

  const handleSort = useCallback(
    (e) => {
      (async () => {
        store.resetParams();
        store.setSortBy(e);
        setPrevSortBy(e);
        if (e === prevSortBy) {
          store.setSortDirection(
            (store.SortDirection &&
              (store.SortDirection === 'asc' ? 'desc' : 'asc')) ||
              'desc',
          );
        } else {
          store.setSortDirection('desc');
        }
        store.setShowLoader(true);
        const manageType = showCompleted ? 'manage-completed' : 'manage-all';
        store.setManageType(manageType);
        await store.getList();
        store.setShowLoader(false);
      })();
    },
    [prevSortBy, store, showCompleted],
  );

  const manageSelectAll = useCallback(
    (e) => {
      setSelectedAccidentRecord([]);
      setIsHeaderRowChecked(false);
      const totalToBeSelected = store.Count < 30 ? store.Count : 30;
      if (e.currentTarget.checked) {
        setIsHeaderRowChecked(true);
        const _selectedAccidentRecord = [];
        for (let i = 0; i < totalToBeSelected; i++) {
          _selectedAccidentRecord.push(store.Data[i].accidentId);
        }
        setSelectedAccidentRecord(_selectedAccidentRecord);
      }
    },
    [store.Data, store.Count],
  );

  const manageSelected = (string?: any) => {
    if (string === 'multiple') {
      setShowMultipleAccidentDriversAndVinsDialogue(true);
    } else {
      setSelectedMatchId(string);
      setShowSingleAccidentDriversAndVinsDialogue(true);
    }
  };

  const closeSingleDialog = async (reload = true): Promise<any> => {
    setShowSingleAccidentDriversAndVinsDialogue(false);
    if (reload) {
      setSelectedMatchId('');
      store.resetParams();
      await getList();
    }
  };

  const closeMultipleRecDialog = async (reload = true): Promise<any> => {
    setShowMultipleAccidentDriversAndVinsDialogue(false);
    if (reload) {
      setSelectedAccidentRecord([]);
      store.resetParams();
      await getList();
    }
  };

  const changeDateFormat = (inputDate: any): string | null => {
    // expects Y-m-d
    const splitDate = inputDate?.split('-');
    if (splitDate?.length === 0) {
      return null;
    }

    const year = splitDate[0];
    const month = splitDate[1];
    const day = splitDate[2];

    return `${month as string}/${day as string}/${year as string}` as string;
  };

  const getNewInspectionObj = useCallback((el: Accident, status) => {
    const {
      primaryDriver,
      primaryDriverMatched,
      primaryDriverMatchedStatus,
      powerUnitVin,
      powerUnitVinMatched,
      powerUnitVinMatchedStatus,
      accidentNumber,
      accidentDate,
      hasPrimaryDriverOnboarding,
      hasPowerUnitAddedToFleet,
      ...rest
    } = el;
    const isDataQ = false;
    const newObj: Partial<any> = { ...rest };
    newObj.accidentDate = changeDateFormat(accidentDate);
    newObj.primaryDriver = {
      primaryText: primaryDriver,
      status: primaryDriverMatchedStatus,
    };
    newObj.powerUnitVin = {
      primaryText: powerUnitVin,
      status: powerUnitVinMatchedStatus,
    };
    newObj.accidentNumber = {
      caption: isDataQ ? 'DataQ challenge submitted' : '',
      primaryText: accidentNumber,
    };
    // if (status === 'Manage') {
    newObj.primaryDriver.secondaryText = primaryDriverMatched;
    newObj.powerUnitVin.secondaryText = powerUnitVinMatched;
    newObj.accidentNumber.caption = isDataQ ? 'DataQ challenge submitted' : '';

    if (hasPrimaryDriverOnboarding)
      newObj.primaryDriver.caption = 'New Driver Onboarding submitted';

    if (hasPowerUnitAddedToFleet)
      newObj.powerUnitVin.caption = 'Add to Fleet submitted';
    // }
    newObj.hasPrimaryDriverOnboarding = hasPrimaryDriverOnboarding;
    newObj.hasPowerUnitAddedToFleet = hasPowerUnitAddedToFleet;
    return newObj as any;
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
      }}>
      {store.Status === 'Manage' && (
        <div
          className="manage-page-header"
          style={{
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
            display: 'flex',
            justifyContent: 'space-between',
            left: '240px',
            paddingTop: '5px',
            top: managePageHeaderTopSspacing,
            width: '84%',
            zIndex: 8,
          }}>
          <div
            style={{
              marginBottom: '10px',
              paddingLeft: '38px',
            }}>
            {showCompleted
              ? 'Matched Accidents'
              : `${store.ShowLoader ? '' : store.Count} Unmanaged Accidents`}
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              marginBottom: '10px',
            }}>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                paddingRight: '25px',
              }}>
              Show Completed&nbsp;&nbsp;
              <Switch
                onChange={(checked: boolean, e: any, id: string) => {
                  setSelectedAccidentRecord([]);
                  setIsHeaderRowChecked(false);
                  setShowCompleted(checked);
                }}
                checked={showCompleted ? true : false}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor={'#0DA728'}
                offColor={'#787880'}
                width={51}
                height={31}
                className={'activeSwitch'}
                disabled={store.ShowLoader}
              />
            </div>
            <div
              style={{
                alignItems: 'start',
                display: 'flex',
                flexDirection: 'column',
                paddingRight: '33px',
              }}>
              <Typography component={'div'}>
                <Typography
                  component={'div'}
                  className={classes.createBtn.concat(
                    selectedAccidentRecord.length < 2
                      ? ` disable ${classes.manageButtonDisable}`
                      : '',
                  )}
                  {...(selectedAccidentRecord.length < 2
                    ? {}
                    : { onClick: () => manageSelected('multiple') })}>
                  Manage Selected ({selectedAccidentRecord.length})
                </Typography>
              </Typography>
              <Typography
                sx={{
                  color: '#979598',
                  fontFamily: 'FiraSans-Regular',
                  fontSize: '11px',
                  fontStyle: 'italic',
                  fontWeight: '500',
                  height: 0,
                  lineHeight: '13px',
                  padding: '4px 0 5px 0',
                  textAlign: 'center',
                }}>
                *Select 2 or more records to update
              </Typography>
            </div>
          </div>
        </div>
      )}
      {store.Data.length === 0 && store.ShowLoader ? (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '80px',
            marginTop: '80px',
            width: '100%',
          }}>
          <CircularProgress size={30} sx={{ color: '#DEC330' }} />
        </div>
      ) : (
        <Grid
          style={{
            overflow: 'hidden',
            padding: '0 2rem 0 3rem',
            paddingTop: store.Status === 'Manage' ? '10px' : '0px',
            position: 'relative',
          }}
          container
          spacing={2}
          justifyContent="space-between">
          <TableContainer
            sx={{
              maxHeight: `${
                store.Status === 'Manage'
                  ? 'calc(100vh - 180px)'
                  : 'calc(100vh - 125px)'
              }`,
            }}
            id="accidents-table">
            <InfiniteScroll
              dataLength={store.Data.length}
              style={{
                overflow: 'unset',
              }}
              next={getNextList}
              hasMore={!!store.NextLink}
              scrollableTarget={'accidents-table'}
              loader={
                store.ShowLoader && (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <CircularProgress
                      size={30}
                      sx={{ color: '#DEC330', marginTop: 20 }}
                    />
                  </div>
                )
              }
              endMessage>
              <Table
                style={{
                  borderSpacing: '0px 5px',
                  minWidth: 700,
                }}
                stickyHeader
                aria-label="sticky table">
                <TableHead
                  style={{
                    height: `${
                      store.Status === 'Manage' ? '60px' : '80px'
                    }` as string,
                  }}>
                  <SimplexGrid.Header
                    id="accidents"
                    cells={
                      store.Status === 'All'
                        ? [
                            ...allHeaderKeysWithSpacing,
                            { noHeader: true, noSorting: true, spacing: 80 },
                          ]
                        : [
                            ...manageHeaderKeysWithSpacing,
                            { noHeader: true, noSorting: true, spacing: 80 },
                            { noHeader: true, noSorting: true, spacing: 80 },
                          ]
                    }
                    sortConfig={{ handleSort, store }}
                    checkBoxConfig={{
                      handleCheck: manageSelectAll,
                      tooltip: 'Max selection limit 30',
                    }}
                    showRowCheck={store.Status === 'Manage' ? true : false}
                    isRowChecked={
                      store.Status === 'Manage' ? isHeaderRowChecked : false
                    }
                    height={80}
                    top={store.Status === 'Manage' ? 15 : 0}
                    checkboxPaddingBottom="4px"
                    gridHeaderStackPaddingTop="20px"
                    gridHeaderStackPaddingBottom="14px"
                  />
                </TableHead>
                <TableBody>
                  {store.Data.length > 0 ? (
                    <SimplexGrid.Body
                      id="accidents"
                      data={store.Data}
                      cells={
                        store.Status === 'All'
                          ? allHeaderKeysWithSpacing
                          : manageHeaderKeysWithSpacing
                      }>
                      {(el: Accident, index: number) => (
                        <>
                          {store.Status === 'Manage' && (
                            <TableCell
                              sx={{
                                minWidth: '80px',
                                padding: '0 16px 0 16px',
                                textAlign: 'left',
                                verticalAlign: 'middle',
                                width: '80px',
                              }}
                              padding={'none'}
                              width={80}>
                              <Checkbox
                                style={{
                                  backgroundColor: '#FFF',
                                  border: '1px solid #FFF',
                                  borderRadius: '4px',
                                }}
                                onChange={(event: any) => {
                                  const index = selectedAccidentRecord.indexOf(
                                    el.accidentId,
                                  );
                                  if (index > -1) {
                                    const _selectedAccidentRecord =
                                      selectedAccidentRecord;
                                    _selectedAccidentRecord.splice(index, 1);
                                    setSelectedAccidentRecord([
                                      ..._selectedAccidentRecord,
                                    ]);
                                  } else {
                                    if (selectedAccidentRecord.length === 30) {
                                      enqueueSnackbar(
                                        String(
                                          'Cannot select more than 30 records',
                                        ),
                                        SnackBarConfig('e'),
                                      );
                                      return;
                                    }
                                    setSelectedAccidentRecord([
                                      ...selectedAccidentRecord,
                                      el.accidentId,
                                    ]);
                                  }
                                }}
                                color="success"
                                tabIndex={0}
                                key={el.accidentId}
                                checked={selectedAccidentRecord.includes(
                                  el.accidentId,
                                )}
                              />
                            </TableCell>
                          )}
                          <Accident
                            cells={
                              store.Status === 'All'
                                ? allHeaderKeysWithSpacing
                                : manageHeaderKeysWithSpacing
                            }
                            key={index}
                            data={getNewInspectionObj(el, store.Status)}
                            renderChild={(id: string) => (
                              <MoreDetails
                                showDriverInfo={true}
                                showEquipmentInfo={true}
                                id={id}
                              />
                            )}
                            isManage={store.Status === 'Manage' ? true : false}
                            handleMatchInfo={manageSelected}
                          />
                        </>
                      )}
                    </SimplexGrid.Body>
                  ) : (
                    <TableRow style={{ height: '80px', minHeight: '80px' }}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        align="center"
                        colSpan={
                          store.Status === 'All'
                            ? allHeaderKeysWithSpacing.length
                            : manageHeaderKeysWithSpacing.length
                        }>
                        <Grid item xs={12} md={12} lg={12}>
                          <Typography
                            noWrap
                            sx={{
                              color: '#241A2E',
                              fontFamily: 'FiraSans-Semibold',
                              fontSize: '16px',
                              fontWeight: 600,
                              height: '23px',
                              letterSpacing: 0,
                              lineHeight: '23px',
                              textAlign: 'center',
                            }}>
                            No data available
                          </Typography>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        </Grid>
      )}
      {showSingleAccidentDriversAndVinsDialogue && (
        <MatchDriversAndVins
          isOpen={showSingleAccidentDriversAndVinsDialogue}
          closeDialog={async ({ reload }: { reload?: boolean }) => {
            await closeSingleDialog(reload);
          }}
          selectedInspectionsViolations={
            selectedMatchId !== ''
              ? store.Data.filter((iv) => {
                  return iv.accidentId === selectedMatchId;
                })
              : []
          }
        />
      )}
      {showMultipleAccidentDriversAndVinsDialogue && (
        <MatchDriversAndVinsMultple
          isOpen={showMultipleAccidentDriversAndVinsDialogue}
          closeDialog={async ({ reload }: { reload?: boolean }) => {
            await closeMultipleRecDialog(reload);
          }}
          selectedInspectionsViolations={store.Data.filter((iv) => {
            return selectedAccidentRecord.includes(iv.accidentId);
          })}
        />
      )}
    </Box>
  );
};

export default observer(Accidents);
