import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useCallback } from 'react';

import { TrainingStatus } from '../../../types';
import { TODO_CATEGORY } from '../../../utils/config';
import useStyles from './todoStyle';

type ListPropsTypes = {
  variants: any;
  category: string;
};

const TodoVariants = (props: ListPropsTypes): React.ReactElement => {
  const { variants, category } = props;
  const classes = useStyles();

  enum AssignmentTodoVideoStatus {
    INCOMPLETE = 'Incomplete',
    COMPLETED = 'Completed',
  }

  const getVariants = useCallback((key, value) => {
    return (
      <Typography
        component={'div'}
        style={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          marginRight: '0.8rem',
        }}
        className={classes.verticalBorder}>
        <Typography
          component={'div'}
          sx={{
            color: '#241A2E',
            fontFamily: 'FiraSans-Regular',
            fontSize: '13px',
            letterSpacing: 0,
            lineHeight: '18px',
          }}>
          {key}
        </Typography>
        <Typography
          component={'p'}
          sx={{
            color: '#241A2E',
            fontFamily: 'FiraSans-Light',
            fontSize: '13px',
            fontWeight: 300,
            letterSpacing: 0,
            lineHeight: '18px',
            paddingLeft: '0.3rem',
          }}>
          {value ? value : '-'}
        </Typography>
      </Typography>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        paddingRight: '8px',
      }}>
      {category === 'Fleet' && (
        <>
          {getVariants('Year:', variants.year)}
          {getVariants('Make:', variants.make)}
          {getVariants('VIN #:', variants.vinNumber)}
          {getVariants('Unit #:', variants.unitNumber)}
        </>
      )}
      {category === 'Driver' && (
        <>
          {getVariants('Driver Name:', variants.fullname)}
          {getVariants(
            'DOB:',
            variants.dob ? moment(variants.dob).format('MM/DD/YYYY') : '-',
          )}
        </>
      )}
      {category === 'Company' &&
        (variants?.ifta ? (
          getVariants('IFTA Account #:', variants.ifta)
        ) : variants?.driver ? (
          <>
            {getVariants('Driver Name:', variants.driver.fullname)}
            {getVariants(
              'DOB:',
              variants.driver.dob
                ? moment(variants.driver.dob).format('MM/DD/YYYY')
                : '-',
            )}
          </>
        ) : variants?.unit ? (
          <>
            {getVariants('Equipment Type:', variants.unit.equipmentType)}
            {getVariants('VIN #:', variants.unit.vinNumber)}
            {getVariants('Unit #:', variants.unit.unitNumber)}
          </>
        ) : variants?.companyProfile ? (
          <>
            {getVariants('Policy #:', variants.companyProfile.policy)}
            {getVariants('Insurer:', variants.companyProfile.insurer)}
            {getVariants(
              'Policy Expiration Date:',
              variants.companyProfile.policyExpirationDate,
            )}
          </>
        ) : (
          ''
        ))}
      {category === TODO_CATEGORY.TRAINING && (
        <div className={classes.trainingTodoVariant}>
          <div className="todo-assigned">
            {getVariants('Assigned To:', variants.assignedToName)}
            {getVariants('Assigned By:', variants.assignedByName)}
            {getVariants('Assigned Date:', variants.assignedDate)}
            {getVariants('Assignment Reason:', variants.assignmentReason)}
          </div>
          <div className="todo-status">
            <div>
              <span className="key">
                1) {variants.hasDocument ? 'Read Document' : 'Watch Video'}:
              </span>
              <span
                className="value status"
                style={{
                  color:
                    AssignmentTodoVideoStatus.COMPLETED === variants.videoStatus
                      ? '#0DA728'
                      : '#A70D0D',
                }}>
                {variants.videoStatus}
              </span>
            </div>
            {variants.hasQuiz && (
              <div>
                <span className="key">2) Take Quiz:</span>
                <span className="value">
                  {`${variants.failedAttempts as string} of ${
                    variants.totalAttempts as string
                  } attempts`}
                  <span
                    className="value status"
                    style={{
                      color:
                        TrainingStatus.COMPLETED === variants.assignmentStatus
                          ? '#0DA728'
                          : [
                              TrainingStatus.FAILED,
                              TrainingStatus.QUIZ_FAILED,
                            ].includes(variants.assignmentStatus)
                          ? '#A70D0D'
                          : 'unset',
                    }}>
                    {TrainingStatus.COMPLETED === variants.assignmentStatus
                      ? ' - Pass'
                      : TrainingStatus.FAILED === variants.assignmentStatus
                      ? ' - Fail'
                      : ''}
                  </span>
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(TodoVariants);
