import React from 'react';

import { tpts_with_equipment_select_slugs } from '../../utils/config';
import SelectEquipmentModal from '../SelectEquipmentModal';
import AutoLiabilityActionsForm from './AutoLiabilityActionsForm';
import JustForm from './JustForm';
import TerminationAcknowledgment from './TerminationAcknowledgment';
import { ToDoFormIProps } from './types';

const EquipmentSelectForm: React.FC<ToDoFormIProps> = (props) => {
  return <SelectEquipmentModal requestType="TODO" {...props} />;
};

const TerminationSelectForm: React.FC<ToDoFormIProps> = (props) => {
  return <TerminationAcknowledgment {...props} />;
};

const TodoForm: React.FC<ToDoFormIProps> = (props) => {
  const { variant, newDriver } = props;
  const shouldShowFromEquipmentSelection =
    tpts_with_equipment_select_slugs.includes(variant);
  return shouldShowFromEquipmentSelection ? (
    <EquipmentSelectForm {...props} />
  ) : props.todoType === 'Termination Acknowledgment Form' ? (
    <TerminationSelectForm {...props} />
  ) : props.todoType === 'Auto-Liability Insurance' ? (
    <AutoLiabilityActionsForm {...props} />
  ) : (
    <JustForm {...props} newDriver={newDriver} />
  );
};

export default TodoForm;
