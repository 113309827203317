import { makeAutoObservable } from 'mobx';

import { RootStore } from '.';

type GetOptionSetParams = {
  optionSet?: string;
};

type GetEntityOptionSetParams = {
  entity?:
    | 'sg_ContactType'
    | 'sg_notificationsubtype'
    | 'sg_state'
    | 'sg_violationgroup'
    | 'sg_inspectionlevel'
    | 'sg_unit'
    | 'sg_documenttype'
    | 'Account'
    | 'Admin'
    | 'Annotation'
    | 'Contact'
    | 'Incident'
    | 'msdyn_serviceconfiguration'
    | 'Service'
    | 'ServiceAppointment'
    | 'ServiceEndpoint'
    | 'ServicePlan'
    | 'ServicePlanAppModules'
    | 'ServicePlanMapping'
    | 'sg_Accident'
    | 'sg_accountservice'
    | 'sg_accountserviceproduct'
    | 'sg_Adjustment'
    | 'sg_AdjustmentType'
    | 'sg_ApplicationSetting'
    | 'sg_Audit'
    | 'sg_basicforecasting'
    | 'sg_CaseServiceLine'
    | 'sg_CaseType'
    | 'sg_CaseTypeStatus'
    | 'sg_city'
    | 'sg_CityMileageCalc'
    | 'sg_collectionsite'
    | 'sg_Comdata'
    | 'sg_ComdataChecks'
    | 'sg_companyprofile'
    | 'sg_ContactRole'
    | 'sg_county'
    | 'sg_Credential'
    | 'sg_DataQ'
    | 'sg_documentreference'
    | 'sg_DriverHistory'
    | 'sg_DrugandAlcoholQuery'
    | 'sg_DrugandAlcoholTest'
    | 'sg_ELDDevice'
    | 'sg_ELDUsage'
    | 'sg_EmploymentHistory'
    | 'sg_Escrow'
    | 'sg_factoringvolume'
    | 'sg_FuelCard'
    | 'sg_FuelCardApplication'
    | 'sg_fuelcreditapplication'
    | 'sg_FuelTaxCalculation'
    | 'sg_FuelTaxForm'
    | 'sg_FuelTaxFormChild'
    | 'sg_fueltaxformrequest'
    | 'sg_FuelTaxRate'
    | 'sg_FuelTaxSettings'
    | 'sg_FuelVolume'
    | 'sg_insurancepolicy'
    | 'sg_insurer'
    | 'sg_labnetwork'
    | 'sg_Load'
    | 'sg_lossoutcome'
    | 'sg_lossoutcomereason'
    | 'sg_maintenanceviolation'
    | 'sg_MROLastStatus'
    | 'sg_MVR'
    | 'sg_ParentLoad'
    | 'sg_Permit'
    | 'sg_productrequirements'
    | 'sg_randompool'
    | 'sg_randompooldetail'
    | 'sg_randompoolquarter'
    | 'sg_randomselection'
    | 'sg_SAPProgram'
    | 'sg_terminal'
    | 'sg_territorymapping'
    | 'sg_Todo'
    | 'sg_Training'
    | 'sg_Trip'
    | 'sg_TripFuelPurchase'
    | 'sg_TripLeg'
    | 'sg_TripMileage'
    | 'sg_UnitHistory'
    | 'sg_UnitPermits'
    | 'sg_VehicleInspection'
    | 'sg_ViolationCode_Incident'
    | 'sg_ViolationCode'
    | 'sg_walkin'
    | 'sg_zipcode'
    | 'st_ChildTable'
    | 'st_MainTable'
    | 'StageSolutionUpload'
    | 'StatusMap'
    | 'StringMap'
    | 'Svt_AdminPassword'
    | 'svt_NotificationTemplate'
    | 'svt_NotificationSetting'
    | 'svt_simplexadmin'
    | 'svt_simplexadminrole'
    | 'svt_companyadmin'
    | 'svt_companyadminrole'
    | 'svt_emailinvitation'
    | 'svt_metrics'
    | 'Svt_UserPassword'
    | 'sg_portaltraining'
    | 'sg_quiz'
    | 'sg_quizquestion'
    | 'sg_quizquestionansweroption'
    | 'sg_trainingviolation'
    | 'sg_trainingassignment'
    | 'sg_inspectiondetail'
    | 'sg_trainingassignmentreason'
    | 'sg_trainingassignmentsubmission'
    | 'sg_trainingassignmentsubmissionanswer'
    | 'sg_trainingreasonmapping'
    | 'rsm_applicationsetting'
    | 'sg_notificationtype'
    | 'sg_notificationcontact';
  keyField?: string;
  valueField?: string;
  filte?: string;
};

export class DBsStore {
  private _optionSet: any;

  constructor(public rootStore: RootStore) {
    this._optionSet = null;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  setOptionSet(value: any) {
    this._optionSet = value;
  }

  get OptionSet() {
    return this._optionSet;
  }

  getOptionSet = async (optionSetName: string) => {
    const params: GetOptionSetParams = {
      optionSet: optionSetName,
    };
    const { data } = await this.rootStore.apiStore.dbsApi.getOptionSetParam(
      params,
    );

    this.setOptionSet(data);
  };

  getEntityOptionSet = async (p: GetEntityOptionSetParams) => {
    const params: GetEntityOptionSetParams = {
      entity: p.entity,
      filte: p.filte,
      keyField: p.keyField,
      valueField: p.valueField,
    };
    const { data } =
      await this.rootStore.apiStore.dbsApi.getEntityOptionSetParam(params);

    this.setOptionSet(data);
  };
}
