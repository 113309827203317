import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Footer from '../../../../../components/Footer';
import SuccessNotification from '../../../../../components/SuccessNotification';
import { useCustomStore } from '../../../../../hooks';
import { NewDriverSetupInputs } from '../../../../../types';
import { new_driver_docs } from '../../../../../utils/config';
import { getFileNamingConvention } from '../../../../../utils/fileName.config';
import { refreshActivityListByStore } from '../../../../../utils/helper';
import { SnackBarConfig } from '../../../../../utils/SnackBarConfig';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import DriverSetupForm from './DriverSetupForm';
import NewDriverCtx from './NewDriverCtx';
import UploadAttachments from './UploadAttachments';

interface propState {
  from?: string;
  id?: string;
  details?: any;
}

const optionsOfUpload = ['upload authorization', 'upload record'];

const ErrorMessage = styled(Typography)(() => ({
  color: 'red',
  fontSize: '14px',
  margin: '15px 0',
}));

const NewDriverSetup: React.FC = () => {
  const [showAttachments, setShowAttachments] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notification, setNotification] = useState(false);
  const { driverStore, driverListStore, dotActivityListStore }: any =
    useCustomStore();
  const { authStore } = useCustomStore();
  const [newDriver, setNewDriver] = useState<NewDriverSetupInputs | any>();
  const [documents, setDocuments] = useState<any[]>([...new_driver_docs()]);
  const [terminals, setTerminals] = useState<Terminal[]>([]);

  const [accidentId, setAccidentId] = useState<string>('');
  const [inspectionId, setInspectionId] = useState<string>('');
  const [matchDriverType, setMatchDriverType] = useState<string>('');

  const params = useLocation();
  const {
    from = '',
    id = '',
    details = '',
  } = params.state
    ? (params.state as propState)
    : { details: '', from: '', id: '' };

  useEffect(() => {
    if (from === 'accidents') {
      setAccidentId(id);
    } else {
      setInspectionId(id);
    }
    if (details) {
      setMatchDriverType(details.matchDriverType);
      const driverNameParts = details.driverName.split(' ');
      const fName = driverNameParts[0];
      const lName = driverNameParts[driverNameParts.length - 1];
      let mName = '';
      if (driverNameParts.length > 2) {
        for (let i = 1; i < driverNameParts.length - 1; i++)
          mName =
            `${mName as string}` + ' ' + `${driverNameParts[i] as string}`;
      }
      mName.trim();
      setNewDriver({
        firstName: fName,
        lastName: lName,
        middleName: mName,
      });
    }
  }, [details, id, from]);

  const [error, setError] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const handleCancel = () => {
    setShowAttachments(false);
  };
  const isSHSS = authStore.getSHSSAccess();
  const [loadingTest, setLoadingText] = useState(
    isSHSS
      ? 'Driver record is being created...'
      : 'Driver record & To-Dos are being created...',
  );

  // MVR, MEDICAL, CLEARING House documents(sections) have their own forms
  // docsFromData is object which with documents names as property and formValue as
  // value
  const [docsFormData, setDocsFormData] = useState<Record<string, any>>();

  const updateDocsFormData = (formValue: any, todoFormVariant: string) => {
    setDocsFormData({
      ...(docsFormData || {}),
      [todoFormVariant]: formValue,
    });
  };

  const getValuesOfDocsFormData = (obj: Record<string, any> = {}) => {
    const values = Object.values(obj);
    if (values.length === 0) {
      return {};
    }
    return values.reduce((prevValue, currentValue) => ({
      ...prevValue,
      ...currentValue,
    }));
  };

  const [extraValue, setExtraValue] = useState<{ [key: string]: any }>({});

  const handleDriverFormSubmit = (data: NewDriverSetupInputs) => {
    const { licenseType } = data;
    if (
      newDriver?.licenseType &&
      (licenseType === 'Mexican' || licenseType === 'Non-CDL') &&
      newDriver?.licenseType !== licenseType
    ) {
      setDocuments([...new_driver_docs()]);
      setExtraValue({});
    }
    setNewDriver(data);
    setShowAttachments(true);
  };

  useEffect(() => {
    (async () => {
      const terminalsList = await driverListStore.getTerminals();
      setTerminals(terminalsList);
    })();
  }, [driverListStore]);

  const processSubmit = async () => {
    const docFormsWithoutAttachments = documents
      .filter((doc) => !doc.attachment)
      .map((doc) => doc.todoFormVariant);
    const newDocsFormData = { ...docsFormData };

    docFormsWithoutAttachments.forEach((docFormName) => {
      delete newDocsFormData[docFormName];
    });

    const valuesOfTodoForms = getValuesOfDocsFormData(newDocsFormData);
    const data = { ...newDriver, ...valuesOfTodoForms };
    const tempDocuments = [...documents];
    const lastTwoDocs = tempDocuments.splice(
      documents.length - 2,
      documents.length,
    );
    const docsWithValuesFromLastTwoDocs = lastTwoDocs.filter(
      (doc) => doc.value,
    );
    const newDocs = [...tempDocuments, ...docsWithValuesFromLastTwoDocs];
    const modifiedDocs = newDocs.map((doc) => ({
      name: doc.caseIdName,
      requestType:
        !doc.requestType || doc.requestType === '-'
          ? 'send to driver'
          : doc.requestType,
    }));

    if (isSHSS) {
      modifiedDocs.push({
        name: 'PSP',
        requestType: 'send to driver',
      });
    }
    setLoadingText(
      isSHSS
        ? 'Driver record is being created...'
        : 'Driver record & To-Dos are being created...',
    );
    setIsSubmitting(true);

    try {
      const bgCheckFormValue = extraValue['Background Check'] || {};
      const pspFormValue = extraValue['PSP'] || {};
      const driverApplicationValue = extraValue['Drivers Application'] || {};
      const drugTestValue = extraValue['Pre-employment Drug Test'] || {};
      let mvrTestValue = extraValue['mvr'] || {};
      let medicalTestValue = extraValue['medical card'] || {};
      let clearingHouseTestValue = extraValue[''] || {};
      let completePayload = {
        ...data,
        ...bgCheckFormValue,
        ...pspFormValue,
        ...driverApplicationValue,
        ...drugTestValue,
      };
      let verifyEmptySectionComplete = false;

      if (
        isSHSS &&
        docsFormData &&
        (completePayload.preEmploymentMVR === '140320002' ||
          completePayload.preEmploymentMC === '140320002' ||
          completePayload.preEmploymentCH === '140320002')
      ) {
        mvrTestValue = docsFormData['mvr'] || null;
        medicalTestValue = docsFormData['medical-card'] || null;
        clearingHouseTestValue = docsFormData['clearinghouse'] || null;

        completePayload = {
          ...data,
          ...bgCheckFormValue,
          ...pspFormValue,
          ...driverApplicationValue,
          ...drugTestValue,
          ...(mvrTestValue ? mvrTestValue : {}),
          ...(medicalTestValue ? medicalTestValue : {}),
          ...(clearingHouseTestValue ? clearingHouseTestValue : {}),
        };
        verifyEmptySectionComplete = true;
      } else if (
        isSHSS &&
        !docsFormData &&
        (completePayload.preEmploymentMVR === '140320002' ||
          completePayload.preEmploymentMC === '140320002' ||
          completePayload.preEmploymentCH === '140320002')
      ) {
        mvrTestValue = null;
        medicalTestValue = null;
        clearingHouseTestValue = null;
        verifyEmptySectionComplete = true;
      }
      const inspectionAccidentData: any = {};

      if (verifyEmptySectionComplete) {
        const setcionsEmpty: string[] = [];
        if (mvrTestValue === null) setcionsEmpty.push('MVR');
        if (medicalTestValue === null) setcionsEmpty.push('Medical Card');
        if (clearingHouseTestValue === null)
          setcionsEmpty.push('Clearinghouse');
        if (
          Object.keys(drugTestValue).length === 0 &&
          completePayload.preEmploymentDT === '140320002'
        )
          setcionsEmpty.push('Pre-Employment Drug Test Result');

        /** Display pending error message */
        if (setcionsEmpty.length > 0) {
          setError(
            `Please fill in all required fields in the following sections: ${setcionsEmpty.join(
              ', ',
            )}`,
          );
          setIsSubmitting(false);
          return;
        }
      }

      if (from && from === 'accidents') {
        inspectionAccidentData.accidentId = accidentId;
      }

      if (from && from === 'inspections') {
        inspectionAccidentData.inspectionId = inspectionId;
        inspectionAccidentData.matchDriverType = matchDriverType;
      }

      const res = await driverStore.submitDriver({
        ...completePayload,
        documents: modifiedDocs,
        ...inspectionAccidentData,
      });
      const {
        licenseType = '',
        state = '',
        licenseExpirationDate = '',
        clearinghouseQuerydateRan = '',
        mvrDateRan = '',
        type = '',
        driverType = '',
        issueDate = '',
        expirationDate = '',
        signedDate = '',
        applicationDate = '',
        pspRunDate = '',
        pspAuthSignedDate = '',
        backgroundCheckResultsDate = '',
        collectionDate = '',
      } = completePayload;
      if (res && res.value) {
        if (documents && documents.length) {
          setLoadingText('Documents are being uploaded...');
        }
        const fileUploadDataArr: { attachment: unknown; data: unknown }[] = [];

        for (const section of documents) {
          if (
            section.requestType === 'upload record' ||
            section.requestType === 'upload authorization'
          ) {
            let sectionName: string = section?.fileType;
            let fileType = section.fileType || section.name;

            if (sectionName === 'Driver Application') {
              fileType = `${fileType as string} - ${
                (driverType === 'Owner Operator'
                  ? driverType
                  : 'House') as string
              }`;
            } else if (
              sectionName === 'PSP Authorization Form' &&
              section.requestType === 'upload record'
            ) {
              sectionName = 'PSP Result';
              fileType = 'PSP Result';
            }

            const fileName = getFileNamingConvention[
              sectionName as FileType
            ].name({
              applicationDate: moment(applicationDate).format('MM/DD/YYYY'),
              clearinghouseQuerydateRan: moment(
                clearinghouseQuerydateRan,
              ).format('MM/DD/YYYY'),
              collectionDate: moment(collectionDate).format('MM/DD/YYYY'),
              driverType,
              expirationDate: moment(expirationDate).format('MM/DD/YYYY'),
              issueDate: moment(issueDate).format('MM/DD/YYYY'),
              licenseExpirationDate: moment(licenseExpirationDate).format(
                'MM/DD/YYYY',
              ),
              licenseType,
              mvrDateRan: moment(mvrDateRan).format('MM/DD/YYYY'),
              pspAuthSignedDate: moment(pspAuthSignedDate).format('MM/DD/YYYY'),
              pspRunDate: moment(pspRunDate).format('MM/DD/YYYY'),
              receivedDate: moment(backgroundCheckResultsDate).format(
                'MM/DD/YYYY',
              ),
              signedDate: moment(signedDate).format('MM/DD/YYYY'),
              state,
              type,
            });
            const caseIdName: string = section?.caseIdName;

            const fileData: unknown = {
              caseId: res.value[`${caseIdName} CaseId`],
              driver: {
                contactId: res.value.contactId,
              },
              fileName,
              fileType,
              service: 'new driver setup',
              type: 'driver',
            };
            fileUploadDataArr.push({
              attachment: section.attachment,
              data: fileData,
            });
          }
        }

        const requests = [];
        const data = new FormData();
        const formData = [];
        setDocsFormData({});

        /* NOTE: Batch all uploads into 1 request to optimize upload calls */
        for (let j = 0; j < fileUploadDataArr.length; j++) {
          const each = fileUploadDataArr[j];
          data.append('files', each.attachment as Blob);
          formData.push(each.data);
        }
        data.append('data', JSON.stringify(formData));
        requests.push(driverListStore.uploadFileForSignature(data, true));

        if (requests.length) {
          const response = await Promise.all(requests);
          if (response.length) {
            refreshActivityListByStore(dotActivityListStore);
            setIsSubmitting(false);
            setNotification(true);
            setDocuments([...new_driver_docs()]);
            enqueueSnackbar(
              'Request submitted successfully',
              SnackBarConfig('success'),
            );
            setDocsFormData({});
          }
        }
        const hasAttachment = documents.find((document) => document.attachment);

        if (!hasAttachment) {
          refreshActivityListByStore(dotActivityListStore);
          setIsSubmitting(false);
          setNotification(true);
          setDocuments([...new_driver_docs()]);
          enqueueSnackbar(
            'Request submitted successfully',
            SnackBarConfig('success'),
          );
          setDocsFormData({});
        }
      } else {
        setIsSubmitting(false);
        enqueueSnackbar('Error while processing data', SnackBarConfig('e'));
      }
    } catch (err) {
      setIsSubmitting(false);
      enqueueSnackbar(
        'Failed to submit request. Try again',
        SnackBarConfig('e'),
      );
      return err;
    }
  };

  const handleSubmit = () => {
    const validatedDocs = documents.map((document) => {
      if (document.required && (!document.value || document.value === '-')) {
        return { ...document, showError: true };
      }

      if (
        optionsOfUpload.includes(document.requestType) &&
        !document.attachment &&
        !isSHSS
      ) {
        return {
          ...document,
          error: 'Attachment is required',
          showError: true,
        };
      }
      return { ...document, showError: false };
    });

    setDocuments([...validatedDocs]);

    const requiredDocuments = documents.filter((doc) => doc.required);
    const requiredDocumentsWithNoValues = requiredDocuments.filter(
      (doc) => !doc.value,
    );
    const documentsWithErrors = validatedDocs.filter(
      (doc) => doc.error || doc.subFormError,
    );

    if (
      documents.length === 0 ||
      requiredDocumentsWithNoValues.length ||
      documentsWithErrors.length
    ) {
      return setError('Please provide required information');
    } else {
      setError('');
    }
    processSubmit();
  };

  const handleIneraction = (sections: any[], formValue = {}) => {
    setDocuments(sections);
    setExtraValue(formValue);
  };

  if (notification) {
    return (
      <SuccessNotification
        title="New Driver Setup"
        handleNotification={() => {
          setDocuments([...new_driver_docs()]);
          setNewDriver(undefined);
          setShowAttachments(false);
          setNotification(false);
        }}
      />
    );
  }

  return (
    <div
      style={{
        paddingRight: '4rem',
      }}>
      <NewDriverCtx.Provider
        value={newDriver ? { data: newDriver, updateDocsFormData } : null}>
        {!showAttachments && (
          <DriverSetupForm
            {...(newDriver ? { newDriver } : { newDriver: null })}
            terminals={terminals}
            onSubmit={handleDriverFormSubmit}
          />
        )}
        {isSubmitting && (
          <LoadingModal
            isOpen={isSubmitting}
            maxWidth="sm"
            body={
              <>
                <p className="modal-body-loading-text">{loadingTest}</p>
                <p className="modal-body-loading-message">
                  It may take upto 3 to 4 mins to finish the request. DO NOT
                  click away or refresh the browser tab.
                </p>
              </>
            }
          />
        )}
        {showAttachments && (
          <UploadAttachments
            childFormValues={extraValue}
            documents={documents}
            disableInteraction={isSubmitting}
            driver={newDriver}
            onInteract={handleIneraction}
            showAttachments={() => setShowAttachments(false)}
          />
        )}
      </NewDriverCtx.Provider>
      {error && <ErrorMessage paragraph> {error} </ErrorMessage>}
      {showAttachments && (
        <Footer
          {...(!showAttachments ? { isNext: true } : {})}
          disabled={isSubmitting}
          disableLoader={isSubmitting}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default NewDriverSetup;
